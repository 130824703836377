<div class="p-0">
    <div class="row g-0">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div
                class="auth-full-page-content d-flex justify-content-center align-items-center p-4 p-lg-5 p-md-3 p-xl-5">
                <div class="w-75 p-xl-3">
                    <div class="d-flex flex-column h-100">
                        <!-- <div class=""> -->
                        <div class="text-center"><img src="../assets/images/D9.Software.Logo.png" alt="" height="60"
                                class="auth-logo-dark text-center"></div>
                        <div class="mt-4">
                            <div class="mb-3">
                                <label for="email" class="fw-bold font-size-13">Email</label>
                                <input type="email" class="form-control" placeholder="Enter Email"
                                    [(ngModel)]='loginCredentials.username'
                                    (input)="changetenant();validateEmail($event,loginCredentials.username)">
                                <div
                                    *ngIf="submited && (loginCredentials.username == undefined || loginCredentials.username == '' || loginCredentials.username == null)">
                                    <span class="text-danger">Please enter your Email</span>
                                </div>
                                <div
                                    *ngIf="submited && !isEmailValid && (loginCredentials.username != undefined && loginCredentials.username != '' && loginCredentials.username != null)">
                                    <span class="text-danger">Please enter a valid Email</span>
                                </div>
                            </div>
                            <div class="mb-3" *ngIf="isShowtenantdropdown">
                                <label for="tenant" class="fw-bold font-size-13">Company</label>
                                <ng-select [clearable]="false" class="cursor-pointer" placeholder="Select company"
                                    [(ngModel)]="loginCredentials.tenantProfileId">
                                    <ng-option [value]="''" disabled>Select Company
                                    </ng-option>
                                    <ng-option *ngFor="let tenant of tenantcompanies" [value]="tenant.TenantProfileId">
                                        {{tenant.TenantName}}</ng-option>
                                </ng-select>
                                <div
                                    *ngIf="submited && (loginCredentials.tenantProfileId == undefined || loginCredentials.tenantProfileId == '' || loginCredentials.tenantProfileId == null)">
                                    <span class="text-danger">Please select Company</span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="userpassword" class="fw-bold font-size-13">Password</label>
                                <div class="fv-row mb-2 mt-1 position-relative">
                                    <input [type]="changetype?'password':'text'" class="form-control input-group"
                                        placeholder="Enter Password" aria-label="Password"
                                        [(ngModel)]='loginCredentials.password'
                                        (keydown.enter)='doLogin(loginCredentials)'>
                                    <span class="position-absolute translate-middle top-50 end-0 me-2 cursor-pointer"
                                        style="top: 20px !important;" (click)="viewpass()"><i
                                            [ngClass]="visible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
                                    <div
                                        *ngIf="submited && (loginCredentials.password == undefined || loginCredentials.password == '' || loginCredentials.password == null)">
                                        <span class="text-danger">Please enter your Password</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-check">
                                <!-- <input class="form-check-input" type="checkbox" id="remember-check">
                                <label class="form-check-label" for="remember-check">
                                    Remember me
                                </label> -->
                                <a [href]="redirecttoforgotpsd()" class="text-muted float-end text-dark"
                                    (click)="onforgotpswdLinkClick($event)">Forgot
                                    password?</a>
                            </div>
                            <div class="mt-3 d-grid">
                                <button (click)="doLogin(loginCredentials)" class="btn btn-primary btn-block" [disabled]="diablelogin">
                                    {{loginbtn}} </button>
                            </div>
                            <!-- <p class="text-center pt-3">Don't have an account?<span><a [href]="
                                redirecttosignup()
                              " (click)="
                              onsignupLinkClick(
                                  $event)" class="fw-bold font-size-16"> Sign Up</a></span></p> -->
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="auth-full-bg pt-lg-5 p-4">
                <div class="w-100">
                    <div class="bg-overlay"></div>
                    <div class="d-flex h-100 flex-column">
                        <div class="p-4 mt-auto">
                            <div class="row justify-content-center">
                                <div class="col-lg-7">
                                    <div class="text-center">
                                        <div dir="ltr">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>