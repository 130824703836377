// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { globalsearch, globalsearchAllData } from './globalsearchmodel';
type Tabs = 'Projects' | 'Dashboard' | 'Users' | 'Clients' | 'Tags' | 'Category' | 'Company Settings' | 'globalSearch' | 'Myprofile';

@Injectable({
    providedIn: 'root',
})

export class SharedService {

    public activeTab = new BehaviorSubject<Tabs>('Projects');
    isUploadComplete: boolean = false;
    static handleUploadComplete(arg0: boolean) {
        throw new Error("Method not implemented.");
    }

    setSidebarActiveTab(tab: Tabs) {
        this.activeTab.next(tab);
    }

    getSidebarActiveTab(): BehaviorSubject<Tabs> {
        return this.activeTab;
    }

    public isdisabled = new BehaviorSubject<boolean>(false);

    setIsDisabled(tab: any) {
        this.isdisabled.next(tab);
    }

    getIsDisabled(): BehaviorSubject<boolean> {
        return this.isdisabled;
    }

    public projectname = new BehaviorSubject<string>('');

    setprojectname(tasks: any) {
        this.projectname.next(tasks);
    }

    getprojectname(): BehaviorSubject<any> {
        return this.projectname;
    }

    public projecttasks = new BehaviorSubject<any[]>([]);

    setProjectTasks(tasks: any[]) {
        this.projecttasks.next(tasks);
    }

    getProjectTasks(): BehaviorSubject<any[]> {
        return this.projecttasks;
    }

    public projecttaskslist = new BehaviorSubject<any[]>([]);

    setProjectTaskslist(taskslist: any[]) {
        this.projecttaskslist.next(taskslist);
    }

    getProjectTaskslist(): BehaviorSubject<any[]> {
        return this.projecttaskslist;
    }

    public projectlists = new BehaviorSubject<any[]>([]);

    setprojectlists(tasks: any[]) {
        this.projectlists.next(tasks);
    }

    getprojectlists(): BehaviorSubject<any[]> {
        return this.projectlists;
    }

    public taskscounts = new BehaviorSubject<any[]>([]);

    settaskscounts(tasks: any[]) {
        this.taskscounts.next(tasks);
    }

    gettaskscounts(): BehaviorSubject<any[]> {
        return this.taskscounts;
    }
    public usertasks = new BehaviorSubject<any[]>([]);

    setusertasks(tasks: any[]) {
        this.usertasks.next(tasks);
    }

    getusertasks(): BehaviorSubject<any[]> {
        return this.usertasks;
    }

    public project = new BehaviorSubject<any[]>([]);

    setProject(project: any[]) {
        this.project.next(project);
    }

    getProject(): BehaviorSubject<any[]> {
        return this.project;
    }

    public userProject = new BehaviorSubject<any[]>([]);

    setuserProject(userProject: any[]) {
        this.userProject.next(userProject);
    }

    getuserProject(): BehaviorSubject<any[]> {
        return this.userProject;
    }

    public companyProject = new BehaviorSubject<any[]>([]);

    setcompanyProject(project: any[]) {
        this.companyProject.next(project);
    }

    getcompanyProject(): BehaviorSubject<any[]> {
        return this.companyProject;
    }

    public GlobalProjectId = '';

    setGlobalProjectId(GlobalProjectId: any) {
        this.GlobalProjectId = GlobalProjectId;
    }

    getGlobalProjectId() {
        return this.GlobalProjectId;
    }

    private setglobalsearch: globalsearch;

    private globalsearchAllListsData: globalsearchAllData;

    setglobalsearchdata(globalsearch: globalsearch): void {
        localStorage.setItem('GlobalSearchData', JSON.stringify(globalsearch));
        this.setglobalsearch = globalsearch;
    }

    getglobalSearchData(): globalsearch {
        return JSON.parse(localStorage.getItem("GlobalSearchData") || '{}');;

    }

    setglobalsearchAlldata(globalsearchAlldata: globalsearchAllData): void {
        localStorage.setItem('GlobalSearchAllData', JSON.stringify(globalsearchAlldata));
        this.globalsearchAllListsData = globalsearchAlldata;
    }

    getglobalSearchAllData(): globalsearchAllData {
        return JSON.parse(localStorage.getItem("GlobalSearchAllData") || '{}');;
    }

    handleUploadComplete(status: boolean) {
        this.isUploadComplete = status;
        this.setIsDisabled(status);
    }

    public projectlistsearchstring = '';

    setprojectlistsearchstring(projectlistsearchstring: any) {
        this.projectlistsearchstring = projectlistsearchstring;
    }

    getprojectlistsearchstring() {
        return this.projectlistsearchstring;
    }

    public userprojectlistsearchstring = '';

    setuserprojectlistsearchstring(userprojectlistsearchstring: any) {
        this.userprojectlistsearchstring = userprojectlistsearchstring;
    }

    getuserprojectlistsearchstring() {
        return this.userprojectlistsearchstring;
    }

    public userProfileDetails = new BehaviorSubject<any[]>([]);

    setuserProfileDetails(userProfileDetails: any) {
        this.userProfileDetails = userProfileDetails;
    }

    getuserProfileDetails(): BehaviorSubject<any[]> {
        return this.userProfileDetails;
    }
    getSearchString(): string {
        return localStorage.getItem("SearchString") || '';
    }

    setSearchString(searchString: any): void {
        localStorage.setItem("SearchString", searchString);
    }

    public allproject = new BehaviorSubject<any[]>([]);

    setallProject(allproject: any[]) {
        this.allproject.next(allproject);
    }

    getallProject(): BehaviorSubject<any[]> {
        return this.allproject;
    }

    public clientdetails = new BehaviorSubject<any[]>([]);

    setClientdetails(clientdetails: any[]) {
        this.clientdetails.next(clientdetails);
    }

    getClientdetails(): BehaviorSubject<any[]> {
        return this.clientdetails;
    }

    public clientList = new BehaviorSubject<any[]>([]);

    setclientList(clientList: any[]) {
        this.clientList.next(clientList);
    }

    getclientList(): BehaviorSubject<any[]> {
        return this.clientList;
    }


    public viewdetails = new BehaviorSubject<string>('');

    setview(tasks: any) {
        this.viewdetails.next(tasks);
    }

    getview(): BehaviorSubject<any> {
        return this.viewdetails;
    }

    public listid = new BehaviorSubject<string>('');

    setlist(listid: any) {
        this.listid.next(listid);
    }

    getlist(): BehaviorSubject<any> {
        return this.listid;
    }



}
