import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/core/services/SharedService.service';
import { ProjectdeatilsService } from 'src/app/core/services/projectdeatils.service';
import { ProjectlistService } from 'src/app/core/services/projectlist.service';
import { ToastralertService } from 'src/app/core/services/toasteralert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer component
 */
export class FooterComponent implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();
  version:any;
  token_response: any;
  
  token: any;
  projectdetails: any = { Projectname: '' };
  projectid: any;
  tabSubscription: Subscription;
  constructor(  
     private projectdeatilsService: ProjectdeatilsService,
     public toastralert: ToastralertService,
     public route: ActivatedRoute,
     private router: Router,
     private projectlistService: ProjectlistService,
     private sharedService: SharedService,) 
     { 

  this.version = environment.Version;

  this.token_response = JSON.parse(localStorage.getItem("d9_token_responseuat") || '{}');
  this.token = this.token_response.access_token;
  }

  
  ngOnInit() {
    // this.route.params.subscribe(params => {
    //   this.projectid = atob(params['projectid']);
     
    // });
   this.geturl();
    // this.GetCoreObjectDetailsforProjects();
    // this.GetCoreProjectByProjectIdforheader(this.projectid);
    this.tabSubscription = this.sharedService.getprojectname().subscribe((data) => {
      if(data){
        let currentpage = this.router.url.split('/')[1];
        let currentpages = this.router.url.split('/')[2];
        if(currentpage == 'project' && currentpages != 'projectlist'){
          this.projectdetails.Projectname = localStorage.getItem('projectname');
        }else{
          
          this.projectdetails.Projectname = '';
        }
      }
    });
  }

  geturl(){
    let currentpage = this.router.url.split('/')[1];
    let currentpages = this.router.url.split('/')[2];
    if(currentpage == 'project' && currentpages != 'projectlist'){
      this.projectdetails.Projectname = localStorage.getItem('projectname');
    }else{
      this.projectdetails.Projectname = '';
    }
  }


  // GetCoreObjectDetailsforProjects() {
  //   this.projectdeatilsService.GetCoreObjectDetailsforProjects(this.token_response.tenantId, this.token_response.userId, 'coreProjects', this.projectid).subscribe((results: any) => {
  //     if (results != null && results != "" && results != 'undefined') {

  //       localStorage.setItem('ProjectName', results.Projectname);
  //       this.projectdetails = results;
  //     }
  //   },
  //     (err) => {
  //       console.log(err);
  //       if (err.status == 401) {
  //         const timer = JSON.parse(localStorage.getItem('timer'));
  //         if (timer && (Date.now() < timer)) {
  //           this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
  //         }
  //       }
  //       else {
  //         this.toastralert.toastrerrorbottom('Unable to get project details.Please try after some time.');
  //       }
  //     }
  //   );
  // }

  // GetCoreProjectByProjectIdforheader(projetId: any) {
  //   this.projectlistService.GetCoreProjectByProjectId(this.token_response.tenantId, projetId, this.token_response.userId).subscribe((results) => {

  //     if (results != null && results != "" && results != 'undefined') {
  //       if(results.UserCount == 0){
  //         this.router.navigate(['/error'])
  //       }
  //       localStorage.setItem('ProjectName', results.ProjectName);
  //       this.projectdetails = results;
  //     }

  //   },
  //     (err) => {
  //       console.log(err);
  //       if (err.status == 401) {
  //         const timer = JSON.parse(localStorage.getItem('timer'));
  //         if (timer && (Date.now() < timer)) {
  //           this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
  //         }
  //       }
  //       else {
  //         this.toastralert.toastrerrorbottom('Unable to get project details.Please try after some time.');
  //       }
  //     }
  //   );
  // }

}
