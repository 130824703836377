import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'bx-home-circle',
        link: '/dashboard',
    },
    {
        id: 2,
        label: 'Projects',
        icon: 'bx-detail',
        link: '/projectlist',
    },
    {
        id: 3,
        label: 'Users',
        icon: 'bxs-user-detail',
        link: '/applicationUsers',
    },
    {
        id: 4,
        label: 'Clients',
        icon: 'bxs-group',
        link: '/clients',
    },
    {
        id: 5,
        label: 'Calendar',
        icon: 'bx bx-calendar',
        link: '#',
    },
    {
        id: 6,
        label: 'Tags',
        icon: 'bx bxs-purchase-tag',
        link: '/tags',
    },
    {
        id: 7,
        label: 'Category',
        icon: 'bx bxs-purchase-tag',
        link: '/categories',
    },
    // {
    //     id: 2,
    //     label: 'Company Settings',
    //     icon: 'bx-cog',
    //     link: '/companysettings',
    // },
];

