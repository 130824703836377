import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOtpInputComponent } from 'ng-otp-input';
import { Subscription, take, timer } from 'rxjs';
import { AuthguardService } from 'src/app/core/services/authgurd.service';
import { ToastralertService } from 'src/app/core/services/toasteralert.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { UserapplicationService } from 'src/app/core/services/userapplication.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-multi-factor-authentication',
  templateUrl: './multi-factor-authentication.component.html',
  styleUrls: ['./multi-factor-authentication.component.scss']
})
export class MultiFactorAuthenticationComponent implements OnInit {
  tfaOTP: any = '';
  token_response: any;
  counter: number = 60;
  isShowResendOTPbtn: boolean = true;
  @ViewChild('ngOtpInput') ngOtpInput: NgOtpInputComponent;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    public toastralert: ToastralertService,
    private UserapplicationService: UserapplicationService,
    private userService: UserProfileService,
    private authGuardservice: AuthguardService,

  ) {
    this.token_response = JSON.parse(localStorage.getItem("d9_token_responseuat") || '{}');
  }

  ngOnInit(): void {
    this.modalService.dismissAll();
  }

  countDown: Subscription;
  tick = 1000;
  startTimer() {
    this.countDown = timer(0, this.tick)
      .pipe(take(this.counter))
      .subscribe(() => {
        --this.counter;
        if (this.counter == 0) {
          this.countDown.unsubscribe();
          this.counter = 60;
          this.isShowResendOTPbtn = true;
        }
      });
  };


  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  ResendOTPtoUser() {
    this.ngOtpInput.setValue('');
    this.tfaOTP = '';
    this.isShowResendOTPbtn = false;
    this.counter = 60;
    this.startTimer();
    this.ResentEmailOTPtoUser();
  }

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };

  onOtpChange(otp) {
    this.tfaOTP = otp;
  }

  ResentEmailOTPtoUser() {
    if (this.token_response.IsEmailAuthentication && this.token_response.IsMultiFactorAuth) {
      let replaceElementsList: any[] = [];
      let authDetails = {
        UserProfileId: this.token_response.userId,
        TenantProfileId: this.token_response.tenantId,
        UserId: this.token_response.userId,
        UserLoginEmailOTP: null,
        UserTwoFactorAuthCode: null,
        IsMultiFactorAuth: this.token_response.IsMultiFactorAuth,
        IsAuthenticatorApp: false,
        IsEmailAuthentication: this.token_response.IsEmailAuthentication,
      };
      let taskdetails = {
        ToAddress: this.token_response.UserEmail,
        SubjectLine: 'One Time Password',
        TemplateType: 'Email',
        TemplateName: 'D9 invite link',
        SourceCustomerId: environment.SourceCustomerId,
        SourceTenantId: environment.sourcetenantid,
        CompanyImage: this.token_response.CompanyLogoImage,
        EmailSetupModel: authDetails,
        TempReplaceElements: replaceElementsList,
        ReferenceId: this.token_response.userId,
        ReferenceType: "ResentOTP"
      };
      this.UserapplicationService.SendEmailOTPtoUser(taskdetails).subscribe((results) => {
        if (results != null && results != "" && results != undefined && results.responseText == 'Email sent') {
          this.toastralert.toastersuccesstop('OTP has been sent successfully');
        }
        else {

        }
      },
        (err) => {
          if (err.status == 401) {
            const timer = JSON.parse(localStorage.getItem('timer'));
            if (timer && (Date.now() < timer)) {
              this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
            }
          }
          else {
            this.toastralert.toastrerrorbottom("Unable to send OTP to your email.Please try after some time.");
          }
        }
      );
    }
  }

  VerifyTwofactorAuthentication() {
    if (this.tfaOTP.length == 0) {
      this.toastralert.toastrwarnningbottomright("Please enter OTP");
      return false;
    }
    else if (this.tfaOTP.length < 6) {
      this.toastralert.toastrwarnningbottomright("Please enter valid OTP");
      return false;
    }
    else {
      if (this.token_response.IsAuthenticatorApp == true && this.token_response) {
        let authDetails = {
          UserId: this.token_response.userId,
          TenantProfileId: this.token_response.tenantId,
          UserProfileId: this.token_response.userId,
          Code: this.tfaOTP,
          AuthenticatorKey: "",
          IsGoogleOrEmailChecked: "isGoogleAuth",
          Email: this.token_response.UserEmail
        };
        this.UserapplicationService.SaveVerifyGoogleAuthCode(authDetails).subscribe((results) => {

          if (results != null && results != "" && results != undefined) {
            if (results.IsUserVerified == true) {
              localStorage.setItem("isAuthenticated", "true");
              this.authGuardservice.authguard();
              const targetScreen = this.userService.getTargetScreen();
              //  this.loginbtn='Authenticating...';
              if (targetScreen) {
                this.router.navigateByUrl(targetScreen);
              } else {
                this.router.navigate(['project/projectlist']);
              }
            }
            else {
              localStorage.setItem("isAuthenticated", "false");
              this.toastralert.toastrerrorbottom(results.responseText);
              this.ngOtpInput.setValue('');
              this.tfaOTP = '';
            }
          }
          else {
            this.toastralert.toastrerrorbottom("Unable to verify OTP.Please try after some time.");
          }
        },
          (err) => {
            console.log(err);
            if (err.status == 401) {
              const timer = JSON.parse(localStorage.getItem('timer'));
              if (timer && (Date.now() < timer)) {
                this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
              }
            }
            else {
              this.toastralert.toastrerrorbottom("Unable to verify google auth.Please try after some time.");
            }
          }
        );
      }
      else {
        let authDetails = {
          UserProfileId: this.token_response.userId,
          TenantProfileId: this.token_response.tenantId,
          UserId: this.token_response.userId,
          Code: this.tfaOTP,
          AuthenticatorKey: null,
          IsGoogleOrEmailChecked: 'isEmailAuth',
          Email: this.token_response.UserEmail
        };
        this.UserapplicationService.SaveVerifyGoogleAuthCode(authDetails).subscribe((results) => {

          if (results != null && results != "" && results != undefined) {
            if (results.IsUserVerified == true) {
              localStorage.setItem("isAuthenticated", "true");
              this.authGuardservice.authguard();
              const targetScreen = this.userService.getTargetScreen();
              //  this.loginbtn='Authenticating...';
              if (targetScreen) {
                this.router.navigateByUrl(targetScreen);
              } else {
                this.router.navigate(['project/projectlist']);
              }
            }
            else {
              localStorage.setItem("isAuthenticated", "false");
              this.toastralert.toastrerrorbottom(results.responseText);
            }
          }
          else {

          }
        },
          (err) => {
            console.log(err);
            if (err.status == 401) {
              const timer = JSON.parse(localStorage.getItem('timer'));
              if (timer && (Date.now() < timer)) {
                this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
              }
            }
            else {
              this.toastralert.toastrerrorbottom("Unable to verify google auth.Please try after some time.");
            }
          }
        );
      }

    }
  }

}
