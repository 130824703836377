import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
  pure:true,
})
export class FilesizePipe implements PipeTransform {

  // transform(size: number, extension: string = 'MB') {
  //   return (size / (1024 * 1024)).toFixed(2) + extension;
  // }

  transform(size:number,extension:string='MB'){

  if(size===0 || size == null || size == undefined)return'0B';
   const decimals = 2;
   const kiloByte=1000;
    const decimal=decimals <0?0:decimals;
   const sizes =['B','KB','MB','GB','TB','PB','EB','ZB','YB'];
   const i:number= Math.floor(Math.log(size)/Math.log(kiloByte));
     return `${parseFloat((size / kiloByte ** i).toFixed(decimal))} ${sizes[i]}`;
    // return (size / (1024 * 1024)).toFixed(2) + extension;
    
    }

}
