import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) {
  }

  GetAllCategoriesOrTagsByTenantProfileId(UserProfileId: any, tenantId: any, auxCategoryName: any,search:any) {
    return this.http.get(environment.GetAllCategoriesOrTagsByTenantProfileId + '/' + UserProfileId + '/' + tenantId + '/' + auxCategoryName + '/' + search)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveOrUpdateauxCategoryOrTag(categorydetails: any) {
    return this.http.post(environment.SaveOrUpdateauxCategoryOrTag, categorydetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetAllCategoriesOrTagsByauxCategoryId(UserId: any, tenantId: any, auxCategoryId: any) {
    return this.http.get(environment.GetAllCategoriesOrTagsByauxCategoryId + '/' + UserId + '/' + tenantId + '/' + auxCategoryId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveDeleteauxCategorieOrTag(deletecategorydetails: any) {
    return this.http.post(environment.SaveDeleteauxCategorieOrTag, deletecategorydetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveDeleteauxCategoryTagByTaskId(removetagtaskdetails: any) {
    return this.http.post(environment.SaveDeleteauxCategoryTagByTaskId, removetagtaskdetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveDeleteauxGroupItemBoardByTaskId(removeboardtaskdetails: any) {
    return this.http.post(environment.SaveDeleteauxGroupItemBoardByTaskId, removeboardtaskdetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
}
