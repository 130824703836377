import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjecttaskService {

  constructor(private http: HttpClient) { }
  
  SaveOrUpdateauxGroupItem(tasklist: any) {
    return this.http.post(environment.SaveOrUpdateauxGroupItem, tasklist, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveCopyorMovecoreTaskList(tasklists: any) {
    return this.http.post(environment.SaveCopyorMovecoreTaskList, tasklists, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveDeleteauxGroupItem(deletetasklist: any) {
    return this.http.post(environment.SaveDeleteauxGroupItem, deletetasklist, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetTasksByTasksFilter(taskfiltermodel: any) {
    return this.http.post(environment.GetTasksByTasksFilter, taskfiltermodel, {
      responseType: 'json'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveCopyorMovecoreTasks(movetaskmodel: any) {
    return this.http.post(environment.SaveCopyorMovecoreTasks, movetaskmodel, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
}
