import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectdeatilsService {
  token_response: any;

  constructor(private http: HttpClient) {
    this.token_response = JSON.parse(localStorage.getItem("d9_token_responseuat") || '{}');
  }

  GetCoreObjectHierarchy(objectName: any) {
    return this.http.get(environment.GetCoreObjectHierarchy + '/' + objectName)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetCoreObjectChildrenforTasks(objectDeatils: any) {
    return this.http.post(environment.GetCoreObjectChildrenforTasks, objectDeatils, {
      responseType: 'json'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetCoreObjectChildrenforCommentFiles(objectDetails: any) {
    return this.http.post(environment.GetCoreObjectChildrenforCommentFiles, objectDetails, {
      responseType: 'json'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  getCoreObjectDetails(tenantId: any, userId: any, coreTableName: any, coreObjectId: any) {
    return this.http.get(environment.GetCoreObjectDetails + '/' + tenantId + '/' + userId + '/' + coreTableName + '/' + coreObjectId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetGroups(objectName: any, tenantProfileId: any) {
    return this.http.get(environment.GetGroups + '/' + objectName + '/' + tenantProfileId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetGroupsList(groupId: any, tenantId: any, parentObjectId: any, UserId: any) {
    return this.http.get(environment.GetGroupsList + '/' + groupId + '/' + tenantId + '/' + UserId + '/' + parentObjectId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  getCoreObjectPrimaryKey(tenantId: any, userId: any, coreObjectName: any) {
    return this.http.get(environment.GetCoreObjectPrimaryKey + '/' + tenantId + '/' + userId + '/' + coreObjectName)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetCategoriesById(childObjectItemId: any, userId: any, tenantId: any) {
    return this.http.get(environment.GetCategoriesById + '/' + childObjectItemId + '/' + userId + '/' + tenantId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetAllCategoriesByProjectId(parentObjectName: any, parentObjectItemId: any, userId: any, tenantId: any) {
    return this.http.get(environment.GetAllCategoriesByProjectId + '/' + parentObjectName + '/' + parentObjectItemId + '/' + userId + '/' + tenantId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveOrUpdatecoreTasks(newtaskdetails: any) {
    return this.http.post(environment.SaveOrUpdatecoreTasks, newtaskdetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetGroupListByauxGroupItemId(groupId: any, tenantProfileId: any, parentObjectId: any, auxGroupItemId: any) {
    return this.http.get(environment.GetGroupListByauxGroupItemId + '/' + groupId + '/' + tenantProfileId + '/' + parentObjectId + '/' + auxGroupItemId + '/' + this.token_response.userId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }


  GetCoreObjectDetailsforProjects(tenantId: any, userId: any, coreTableName: any, coreObjectId: any) {
    return this.http.get(environment.GetCoreObjectDetailsforProjects + '/' + tenantId + '/' + userId + '/' + coreTableName + '/' + coreObjectId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetCoreObjectChildren(objectDeatils: any) {
    return this.http.post(environment.GetCoreObjectChildren, objectDeatils, {
      responseType: 'json'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetCoreObjectChildrenTimelogPaging(parentobjectName: any, ChildObjectName: any, ParentObjectItemId: any, UserProfileId: any, tenantId: any, newobject: any, orderBy: any, pagenum: any) {
    return this.http.get(environment.GetCoreObjectChildrenTimelogPaging + '/' + parentobjectName + '/' + ChildObjectName + '/' + ParentObjectItemId + '/' + UserProfileId + '/' + tenantId + '/' + newobject + '/' + orderBy + '/' + pagenum)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }


  SaveauxCategoryTask(newfiledetails: any) {
    return this.http.post(environment.SaveauxCategoryTask, newfiledetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }






}

