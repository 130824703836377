import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthguardService } from 'src/app/core/services/authgurd.service';
import { SendEmailsthroughFlexdripService } from 'src/app/core/services/sendEmails-through-flexdrip.service';
import { ToastralertService } from 'src/app/core/services/toasteralert.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment';
import { UserapplicationService } from 'src/app/core/services/userapplication.service';
import Swal from 'sweetalert2';
import { UseroptedpropertiesService } from 'src/app/core/services/useroptedproperties.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent {

  visible: boolean = false;
  changetype: boolean = true;
  isEmailValid: any = false;
  step1: any = false
  tenant: any = { userEmail: '', userPassword: '', companyName: '', userLastName: '', userFirstName: '', companyCountry: '', companyTimezone: '', ConfirmPassword: '' };
  timezones: any = [];
  submitStep1: boolean = false;
  isPasswordValid: boolean = false;
  isPasswordValidlen: boolean = false;
  isPasswordMatchValid: boolean = false;
  confirmvisible: boolean = false;
  confirmchangetype: boolean = true;
  submitStep2: boolean = false;
  updatetenant: boolean = false;
  token_response: any;
  token: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private userService: UserProfileService,
    public toastralert: ToastralertService,
    private UserapplicationService: UserapplicationService,
    private useroptedpropertiesService: UseroptedpropertiesService,
    private authGuardservice: AuthguardService,
    private sendEmailsthroughFlexdripService: SendEmailsthroughFlexdripService) {
    this.GetappTimeZones()
  }

  goToNextStep() {
    // Perform validation logic for Step 1
    // If validation passes, navigate to the next step (Personal Details)
    // For demonstration purposes, always navigate to the next step
    const nextStep = document.querySelector('.aw-wizard-step.current')?.nextElementSibling;
    if (nextStep) {
      nextStep.scrollIntoView({ behavior: 'smooth' });
    }
  }

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }

  validateEmail(email: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isEmailValid = re.test(email);
    return this.isEmailValid;
  }

  trim(str: string) {
    return str.replace(/ /g, '');
  }

  GetappTimeZones() {
    this.useroptedpropertiesService.GetappTimeZones('00000000-0000-0000-0000-000000000000', '00000000-0000-0000-0000-000000000000').subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.timezones = results;
      }
    },
      (err) => {
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            console.log(err);
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
          else {
          }
        }
        else {
          console.log(err);
          this.toastralert.toastrerrorbottom('Unable to get time zone details.Please try after some time.');
        }
      }
    );
  }


  VerifySignUpOTP(email: any, tenantProfileId: any) {
    email = this.trim(email);
    if (typeof email == 'undefined' || email == "" || email == null) {
      return false;
    }
    else {
      this.userService.VerifySignUpOTP(email, tenantProfileId).subscribe(results => {
        // if (results != null && results != "" && results != 'undefined') {

        //   // if (results == 'ForgotPasswordConfirmation') {

        //   //   Swal.fire({
        //   //     icon: 'success',
        //   //     title: 'An URL has been sent to the provided Email address for resetting your password',
        //   //     showConfirmButton: false,
        //   //     confirmButtonColor: "#a5dc86",
        //   //     timer: 1500
        //   //   })
        //   //   this.router.navigate(['/login']);
        //   // }
        //   // else {
        //   //   this.toastralert.toastrerror('Unable to fetch the details for the given Email');

        //   // }
        // }
        // else {
        //   this.toastralert.toastrerror('Error occured while fecting the details for the given EmailId');

        // }
      }, err => {
        console.log(err);

      });
    }
  }

  SaveOrUpdateTenantProfile(tenant: any) {
    this.updatetenant = true;
    if (typeof tenant.companyName == 'undefined' || tenant.companyName == "" || tenant.companyName == null) {
      return false;
    }
    else if (typeof tenant.companyTimezone == 'undefined' || tenant.companyTimezone == "" || tenant.companyTimezone == null) {
      return false;
    }
    else if (typeof tenant.companyCountry == 'undefined' || tenant.companyCountry == "" || tenant.companyCountry == null) {
      return false;
    }
    else {
      let tenantlist = {
        TenantProfileId: null,
        UserEmail: tenant.userEmail,
        UserPassword: tenant.ConfirmPassword,
        UserFirstName: tenant.userFirstName,
        UserLastName: tenant.userLastName,
        TenantName: tenant.companyName,
        CountryCode: tenant.companyCountry,
        TimeZoneId: tenant.companyTimezone
      };
      this.userService.SaveOrUpdateTenantProfile(tenantlist).subscribe(results => {
        if (results.Item1 != null && results.Item1 != "" && results.Item1 != 'undefined') {
         
          if (results.Item1 == '99999999-9999-9999-9999-999999999999' || results.Item1 == '00000000-0000-0000-0000-000000000000') {
            this.toastralert.toastrerrorbottom("Email ID already exists");
          }
          else {
            const tenantDetails = results.Item2;

           // this.VerifySignUpOTP(tenantDetails.UserEmail,tenantDetails.TenantProfileId );

            const formData = new FormData();
            formData.append('username', tenant.userEmail);
            formData.append('password', tenant.ConfirmPassword);
            formData.append('tenantProfileId', results.Item1);
            this.userService.userAuthentication(formData).subscribe((results) => {
              if (results != null && results != "" && results != 'undefined') {
                this.token_response = results;
                let redirecturl = environment.baseUrl + 'login';
                let replaceElementsList: any[] = [];
                let newList: any[] = [
                  { Name: 'AssignedTo', Value: tenant.userFirstName + ' ' + tenant.userLastName },
                  { Name: 'verfiyEmailUrl', Value: redirecturl },

                ];
                replaceElementsList = newList;

                if (this.token_response.userRoleId != null) {
                  this.token = this.token_response.access_token;
                  localStorage.setItem("d9_token_responseuat", JSON.stringify(this.token_response));
                  localStorage.setItem("timer", JSON.stringify(Date.now() + (this.token_response.expires_in * 1000)));
                  localStorage.setItem("isAuthenticated", "true");
                  this.authGuardservice.authguard();
                  let taskdetails = {
                    ToAddress: tenant.userEmail,
                    SubjectLine: 'Welcome to D9',
                    TemplateType: 'Email',
                    TemplateName: 'D9 Welcome Template',
                    SourceCustomerId: environment.SourceCustomerId,
                    SourceTenantId: environment.sourcetenantid,
                    TempReplaceElements: replaceElementsList,
                  };
                  this.sendEmailsthroughFlexdripService.SaveSendTaskCreatedEmailsToUsers(taskdetails).subscribe((results) => {

                    if (results != null && results != "" && results != undefined && results.responseText == 'Email sent') {

                      this.cleartenant();
                    }
                    else {
                      this.cleartenant();
                    }
                  },
                    (err) => {
                      if (err.status == 401) {
                        const timer = JSON.parse(localStorage.getItem('timer'));
                        if (timer && (Date.now() < timer)) {
                          this.cleartenant();
                          console.log(err);
                          this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
                        }
                        else {
                          this.cleartenant();
                        }
                      }
                      else {
                        this.toastralert.toastrerrorbottom("Unable to send emails.Please try after some time.");
                        this.cleartenant();
                      }
                    }
                  );
                  this.router.navigate(['project/projectlist']);
                  this.toastralert.toastersuccesstop('Tenant created successfully');
                  this.cleartenant();
                }
                else {
                  this.toastralert.toastrerror('Access not allowed for this user');
                }
              }
              else {
                this.toastralert.toastrerror('Access not allowed for this user');
              }
            },
              (err) => {
                this.toastralert.toastrerror('The Email or Password is incorrect');
              }
            );

          }
        }
        else {
          this.toastralert.toastrerror('Error occured while creating account');
        }
      },
        (err) => {
          console.log(err);
          if (err.status == 401) {
            const timer = JSON.parse(localStorage.getItem('timer'));
            if (timer && (Date.now() < timer)) {
              this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
            }
          }
          else {
            this.toastralert.toastrerrorbottom("Unable to sign up.Please try after some time.");
          }
        }
      );
    }
  }

  cleartenant() {
    this.submitStep1 = false;
    this.submitStep2 = false;
    this.updatetenant = false;
    this.tenant = { userEmail: '', userPassword: '', companyName: '', userLastName: '', userFirstName: '', companyCountry: '', companyTimezone: '', ConfirmPassword: '' };
  }

  canExitStep1(): boolean {
    this.tenant.userEmail = this.trim(this.tenant.userEmail);
    if ((!this.validateEmail(this.trim(this.tenant.userEmail)))
      || ((!this.validatePassword(this.tenant.userPassword)) ||
        !(this.tenant.ConfirmPassword != undefined && this.tenant.ConfirmPassword != '' && this.tenant.ConfirmPassword != null)) ||
      (this.tenant.userFirstName == undefined || this.tenant.userFirstName == '' || this.tenant.userFirstName == null) ||
      (this.tenant.userLastName == undefined || this.tenant.userLastName == '' || this.tenant.userLastName == null)
      || (this.tenant.ConfirmPassword != this.tenant.userPassword)) {
      return false;
    }
    else {
      return true;
    }
  }

  validateStep1() {
    this.submitStep1 = true;
  }

  validatePassword(password: any) {
    var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;
    this.isPasswordValid = re.test(password);
    return this.isPasswordValid;
  }

  validatePasswordLength(password: any) {
    this.isPasswordValidlen = password.length < 8 ? true : false;
    return this.isPasswordValidlen;
  }

  validatePasswordMatching(password: any) {
    this.isPasswordMatchValid = password != this.tenant.userPassword ? false : true;
    return this.isPasswordMatchValid;
  }

  confirmviewpass() {
    this.confirmvisible = !this.confirmvisible;
    this.confirmchangetype = !this.confirmchangetype;
  }

  onKeyDown(event: KeyboardEvent) {
    let key = event.keyCode;
    if ((key >= 15 && key <= 64 && key != 32) || (key >= 123) || (key >= 96 && key <= 105)) {
      event.preventDefault();
    }
  }

  validateStep2() {
    this.submitStep2 = true;
  }

  canExitStep2() {
    if ((this.tenant.userFirstName != undefined && this.tenant.userFirstName != '' && this.tenant.userFirstName != null) && (this.tenant.userLastName != undefined && this.tenant.userLastName != '' && this.tenant.userLastName != null)) {
      return true;
    } else {
      return false;
    }
  }

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };

}
