import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page404',
  templateUrl: './page404.component.html',
  styleUrls: ['./page404.component.scss']
})

/**
 * PAges-404 component
 */
export class Page404Component implements OnInit {

  constructor(private router: Router, private modalService : NgbModal) { }

  ngOnInit(): void {
    this.modalService.dismissAll();
  }

  ondashboardLinkClick(event: MouseEvent): void {
    event.preventDefault(); // Prevent the default anchor link behavior
    if (event.ctrlKey || event.metaKey) {
      window.open(environment.baseUrl + 'project/projectlist')
    } else {
      this.router.navigate(['/project/projectlist']);
    }
  }

  redirectodashboard() {
    return environment.baseUrl + 'project/projectlist';
    // this.router.navigate(['/projectlist'])
  }

}
