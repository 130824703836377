import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserapplicationService {

  constructor(private http: HttpClient) { }

  GetUserProfileDetailsByUserProfileId(userId: any) {
    return this.http.get(environment.GetUserProfileDetailsByUserProfileId + '/' + userId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetAllUsersListByTenantProfileId(userProfileId: any, tenantId: any ,tenantCompanyId:any ,search:any) {
    return this.http.get(environment.GetAllUsersListByTenantProfileId + '/' + userProfileId + '/' + tenantId +'/' + tenantCompanyId + '/' + search)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetappUserRolesByTenantProfileId(tenantProfileId:any , userProfileId:any){
    return this.http.get(environment.GetappUserRolesByTenantProfileId + '/' + tenantProfileId + '/' + userProfileId )
    .pipe(map((res: any) => {
      return res;
    }), catchError(error => {
   
      return throwError(error);
    }));
  }

  SaveOrUpdatecoreUserProfile(Userdetails: any) {
    return this.http.post(environment.SaveOrUpdatecoreUserProfile, Userdetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
  GetUserDetailsByTenantUserProfileId(TenantProfileId:any ,UserProfileId:any , ProjectId :any, userid:any ){
     return this.http.get(environment.GetUserDetailsByTenantUserProfileId + '/'+ TenantProfileId + '/' + UserProfileId + '/' + ProjectId + '/'+ userid  )
     .pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveDeleteUserProfile( deleteUserProfile:any){
    return this.http.post(environment.SaveDeleteUserProfile,deleteUserProfile,{
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveDeleteProjectUser( deleteUserProfile:any){
    return this.http.post(environment.SaveDeleteProjectUser,deleteUserProfile,{
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GenerateNewSecretKey(email: any, companyname: any) {
    return this.http.get(environment.GenerateNewSecretKey + '/' + email + '/' + companyname)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveVerifyGoogleAuthCode( authDetails:any){
    return this.http.post(environment.SaveVerifyGoogleAuthCode,authDetails,{
      responseType: 'json'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SendEmailOTPtoUser( authDetails:any){
    return this.http.post(environment.SendEmailOTPtoUser,authDetails,{
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  UpdateMultiFactorAuthenticationByUserId(userAuthDetails:any){
    return this.http.post(environment.UpdateMultiFactorAuthenticationByUserId,userAuthDetails,{
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }
  

}
