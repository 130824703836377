import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';

import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { ExtrapagesModule } from './extrapages/extrapages.module';

import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { initFirebaseBackend } from './authUtils';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
//editor
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { FormsModule } from '@angular/forms';
import { UserProfileService } from './core/services/user.service';
import { DecimalPipe } from '@angular/common';
import { MatTableModule } from "@angular/material/table";
import { MatTableDataSource } from "@angular/material/table";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import swal from 'sweetalert';
import { Recoverpwd2Component } from './components/recoverpwd2/recoverpwd2.component';
import { PasswordresetComponent } from './components/passwordreset/passwordreset.component';
import { AuthguardService } from './core/services/authgurd.service';
import { AuthorizationInterceptor } from './core/Interceptors/authorization.interceptor';
import { ToastrModule } from "ngx-toastr";
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FilesizePipe } from './filesize.pipe';
import { NgxSpinnerModule } from "ngx-spinner";
import { MultiFactorAuthenticationComponent } from './components/multi-factor-authentication/multi-factor-authentication.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { QRCodeModule } from 'angular2-qrcode';
import { ExportModule } from './components/export/export.module';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ArchwizardModule } from 'angular-archwizard';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgApexchartsModule } from 'ng-apexcharts';
//import { ShowEditDelDropdownDirective } from './show-edit-del-dropdown.directive';
//import { TenantdashboardComponent } from './tenantdashboard/tenantdashboard.component';



export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    Recoverpwd2Component,
    PasswordresetComponent,
    MultiFactorAuthenticationComponent,
    SignUpComponent,
    //ShowEditDelDropdownDirective,
   // TenantdashboardComponent,
  ],
  imports: [
    NgApexchartsModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,
    ArchwizardModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    CKEditorModule,
    NgOtpInputModule,
    ToastrModule.forRoot(),
    ScrollingModule,
    QRCodeModule,
    ExportModule,
    NgSelectModule
  ],
  bootstrap: [AppComponent],
  providers: [
    UserProfileService,
    AuthguardService,
    MatTableDataSource,
    DecimalPipe,
    DatePipe,
    FilesizePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    // LoaderService,
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptorService, multi: true },
  ],
})
export class AppModule { }
