<div class="p-0">
    <div class="row g-0">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div
                class="auth-full-page-content d-flex justify-content-center align-items-center p-4 p-lg-5 p-md-3 p-xl-5">
                <div class="w-75 p-xl-3">
                    <div class="d-flex flex-column h-100">
                        <div class="text-center"><img src="../assets/images/D9.Software.Logo.png" alt="" height="60"
                                class="auth-logo-dark text-center"></div>
                        <div class="mt-4">
                            <div class="mb-3">
                                <label class="fw-bold font-size-13">New Password</label>
                                <div class="fv-row mb-2 mt-1 position-relative">
                                    <input class="form-control" [type]="changetype?'password':'text'"
                                        placeholder="Enter New Password" autocomplete="off"
                                        (input)="validatePassword(passwordModel.NewPassword)"
                                        (keyup)="validatePasswordLength(passwordModel.NewPassword)"
                                        [(ngModel)]="passwordModel.NewPassword" />
                                    <span class="position-absolute translate-middle top-50 end-0 me-2 cursor-pointer"
                                        style="top: 20px !important;" (click)="viewpass()"><i
                                            [ngClass]="visible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
                                    <div
                                        *ngIf="submited && (passwordModel.NewPassword == undefined || passwordModel.NewPassword == '' || passwordModel.NewPassword == null)">
                                        <span class="text-danger">Please enter your New Password</span>
                                    </div>
                                    <div
                                        *ngIf="submited && !isPasswordValid && (passwordModel.NewPassword != undefined && passwordModel.NewPassword != '' && passwordModel.NewPassword != null)">
                                        <span class="text-danger">Password must contain at least one capital letter,
                                            at least one number and at least one special character</span>
                                    </div>
                                    <div
                                        *ngIf="submited && isPasswordValid && (passwordModel.NewPassword != undefined && passwordModel.NewPassword != '' && passwordModel.NewPassword != null) && (passwordModel.NewPassword.length < 8)">
                                        <span class="text-danger">Password must contain at least 8 characters</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="fw-bold font-size-13">Confirm Password</label>
                                <div class="fv-row mt-1 position-relative">
                                    <input class="form-control" [type]="confirmchangetype?'password':'text'"
                                        placeholder="Enter Confirm Password" autocomplete="off"
                                        (input)="validatePasswordMatching(passwordModel.ConfirmPassword)"
                                        [(ngModel)]="passwordModel.ConfirmPassword" />
                                    <span class="position-absolute translate-middle top-50 end-0 me-2 cursor-pointer"
                                        style="top: 20px !important;" (click)="confirmviewpass()"><i
                                            [ngClass]="confirmvisible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
                                    <div
                                        *ngIf="submited && (passwordModel.ConfirmPassword == undefined || passwordModel.ConfirmPassword == '' || passwordModel.ConfirmPassword == null)">
                                        <span class="text-danger">Please re-enter the above Password for
                                            confirmation.</span>
                                    </div>
                                    <div
                                        *ngIf="submited && isPasswordValid && (passwordModel.NewPassword.length >= 8) && !isPasswordMatchValid && (passwordModel.ConfirmPassword != undefined && passwordModel.ConfirmPassword != '' && passwordModel.ConfirmPassword != null)">
                                        <span class="text-danger">New password and confirm password must be same</span>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-4 d-grid">
                                <button class="btn btn-primary btn-block"
                                    (click)="UpdatePassword(passwordModel)">Submit</button>
                            </div>
                            <div class="mt-5 text-center">
                                <p>Remember It ? <a [href]="redirecttologin()" (click)="onloginLinkClick($event)"
                                        class="fw-medium text-primary cursor-pointer ">
                                        Sign In here</a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="auth-full-bg pt-lg-5 p-4">
                <div class="w-100">
                    <div class="bg-overlay"></div>
                    <div class="d-flex h-100 flex-column">
                        <div class="p-4 mt-auto">
                            <div class="row justify-content-center">
                                <div class="col-lg-7">
                                    <div class="text-center">
                                        <div dir="ltr">
                                            <!-- <owl-carousel-o class="owl-theme auth-review-carousel"
                                                  >
                                              </owl-carousel-o> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>