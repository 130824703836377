import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectlistService {
  token_response: any;
  token: any;

  constructor(private http: HttpClient) { 
    this.token_response = JSON.parse(localStorage.getItem("d9_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  GetAllProjectsList(userId: any, tenentId: any, auxCategoryId: any, isActive: any, userIsadmin: any, pagenum: any, search: any, Object: any, OrderBy: any , type : any) {
    return this.http.get(environment.GetAllProjectsList + '/' + userId + '/' + tenentId + '/' + auxCategoryId + '/' + isActive + '/' + userIsadmin + '/' + pagenum + '/' + search + '/' + Object + '/' + OrderBy + '/' + type)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetAllTenantCompanysByTenantProfileId(tenentId: any, userId: any, search: any) {
    return this.http.get(environment.GetAllTenantCompanysByTenantProfileId + '/' + tenentId + '/' + userId + '/' + search)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }


  GetAllUsersListByTenantProfileId(userId: any, tenentId: any, tenantCompanyId: any, search: any) {
    return this.http.get(environment.GetAllUsersListByTenantProfileId + '/' + userId + '/' + tenentId + '/' + tenantCompanyId + '/' + search)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetProjectUsersListByTenantProfileId(userId: any, tenentId: any) {
    return this.http.get(environment.GetProjectUsersListByTenantProfileId + '/' + userId + '/' + tenentId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetCoreProjectByProjectId(tenantId: any, projectId: any, Userid: any) {
    let userisadmin = (this.token_response.UserRoleName == 'Administrator' || this.token_response.UserRoleName == "Owner") ? true : false
    return this.http.get(environment.GetCoreProjectByProjectId + '/' + tenantId + '/' + projectId + '/' + Userid+ '/' + userisadmin)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveOrUpdatecoreProjects(projectData: any) {
    return this.http.post(environment.SaveOrUpdatecoreProjects, projectData, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveDeleteProjectByProjectId(deleteproject: any) {
    return this.http.post(environment.SaveDeleteProjectByProjectId, deleteproject, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveCopyProject(project: any) {
    return this.http.post(environment.SaveCopyProject, project, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

}
