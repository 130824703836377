import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UserProfileService } from 'src/app/core/services/user.service';
import { environment } from '../../../environments/environment';
import { ToastralertService } from 'src/app/core/services/toasteralert.service';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})

export class PasswordresetComponent implements OnInit, AfterViewInit {
  passwordModel: any = { PersonId: '', Code: '', NewPassword: '', ConfirmPassword: '',TenantProfileId:'' };
  visible: boolean = false;
  changetype: boolean = true;
  confirmvisible: boolean = false;
  confirmchangetype: boolean = true;
  isPasswordValid: boolean = false;
  submited: any = false;
  isPasswordValidlen: any = false;
  isPasswordMatchValid: any = false;
  constructor(private route: ActivatedRoute, private router: Router, private userService: UserProfileService, public toastralert: ToastralertService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.passwordModel.PersonId = params['personId'];
      this.passwordModel.Code = params['code'];
      this.passwordModel.TenantProfileId = params['tenantProfileId']
    });
  }

  ngAfterViewInit() {
  }

  onloginLinkClick(event: MouseEvent): void {
    event.preventDefault(); // Prevent the default anchor link behavior
    if (event.ctrlKey || event.metaKey) {
      window.open(environment.baseUrl + 'login')
    } else {
      this.router.navigate(['/login']);
    }
  }
  redirecttologin() {
    return environment.baseUrl + 'login';
  }

  // redirecttologin() {
  //   this.router.navigate(['/login']);
  // }

  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }

  confirmviewpass() {
    this.confirmvisible = !this.confirmvisible;
    this.confirmchangetype = !this.confirmchangetype;
  }

  UpdatePassword(passwordModel: any) {
    this.submited = true;
    this.validatePasswordMatching(passwordModel.ConfirmPassword)
    let capletrfrmt = /[A-Z]/;
    let numberformat = /\d/;
    let specialcharfrmt = /[!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;
    if (typeof passwordModel.NewPassword == 'undefined' || passwordModel.NewPassword == "" || passwordModel.NewPassword == null) {
      return false;
    }
    else if (passwordModel.NewPassword !== "" && !this.validatePassword(passwordModel.NewPassword)) {
      return false;
    }
    else if (passwordModel.NewPassword.length < 8) {
      return false;
    }
    else if (typeof passwordModel.ConfirmPassword == 'undefined' || passwordModel.ConfirmPassword == "" || passwordModel.ConfirmPassword == null) {
      return false;
    }
    else if (passwordModel.NewPassword != passwordModel.ConfirmPassword) {
      return false;
    }
    else {
      let resetdata = {
        TenantId: this.passwordModel.TenantProfileId,
        PersonId: this.passwordModel.PersonId,
        Code: this.passwordModel.Code,
        Password: passwordModel.ConfirmPassword
      };
      this.userService.resetpassword(resetdata).subscribe(results => {
       ;
        if (results != null && results != 'undefined') {
          this.submited = false;
          if (results.message == 'Password Updated') {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: "Password updated successfully",
              showConfirmButton: true,
              confirmButtonColor: "#a5dc86",
            })
            this.router.navigate(['login']);
          }
          if (results.message == 'Expired') {
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: "This URL for resetting your password has expired. Please generate another link from Login page",
              showConfirmButton: true,
              confirmButtonColor: "#a5dc86",
            })
            this.router.navigate(['login']);
          }
          if (results.message == 'User not found') {
            Swal.fire({
              icon: 'warning',
              title: 'Warning',
              text: "User not found",
              showConfirmButton: true,
              confirmButtonColor: "#a5dc86",
            })
            this.router.navigate(['login']);
          }

        }
        else {
          this.toastralert.toastrerror('Error occurred while updating password');
          this.submited = false;
        }
      }, (err) => {
        this.submited = false;
        this.toastralert.toastrerror('Error occurred while updating password');
        // if(err.status == '200'){
        //   Swal.fire({
        //     icon: 'success',
        //     title: 'Verified!',
        //     text: "Password has been successfully updated",
        //     showConfirmButton: true,
        //     confirmButtonColor: "#a5dc86",
        //   })
        //   this.router.navigate(['login']);
        // }
        // else{
        //   Swal.fire({
        //     icon: 'success',
        //     title: 'Success!',
        //     text: "Password has been successfully updated",
        //     showConfirmButton: true,
        //     confirmButtonColor: "#a5dc86",
        //   })
        //   this.router.navigate(['login']);
        // }
      });
    }
  }

  validatePassword(password: any) {
    var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/;
    this.isPasswordValid = re.test(password);
    return this.isPasswordValid;
  }

  validatePasswordLength(password: any) {
    this.isPasswordValidlen = password.length < 8 ? true : false;
    return this.isPasswordValidlen;
  }

  validatePasswordMatching(password: any) {
    this.isPasswordMatchValid = password != this.passwordModel.NewPassword ? false : true;
    return this.isPasswordMatchValid;
  }

}
