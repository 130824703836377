import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendEmailsthroughFlexdripService {

  constructor(private http: HttpClient) { }

  SaveSendTaskCreatedEmailsToUsers(taskdetails:any){
    return this.http.post(environment.SaveSendTaskCreatedEmailsToUsers, taskdetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveSendInvitationMailsToUsers(taskdetails:any){
    return this.http.post(environment.SaveSendInvitationMailsToUsers, taskdetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

}
