import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UserProfileService } from 'src/app/core/services/user.service';
import { environment } from '../../../environments/environment';
import { ToastralertService } from 'src/app/core/services/toasteralert.service';

@Component({
  selector: 'app-recoverpwd2',
  templateUrl: './recoverpwd2.component.html',
  styleUrls: ['./recoverpwd2.component.scss']
})
export class Recoverpwd2Component implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();

  resetForm: UntypedFormGroup;
  submitted = false;
  error = '';
  success = '';
  loading = false;
  email: any;
  submited: any = false;
  isEmailValid: any = false;
  isShowtenantdropdown: any = false;
  tenantProfileId: any;
  tenantcompanies: any;

  constructor(private formBuilder: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private userService: UserProfileService, public toastralert: ToastralertService) { }

  ngOnInit(): void {
  }

  onloginLinkClick(event: MouseEvent): void {
    event.preventDefault(); // Prevent the default anchor link behavior
    if (event.ctrlKey || event.metaKey) {
      window.open(environment.baseUrl + 'login')
    } else {
      this.router.navigate(['/login']);
    }
  }
  redirecttologin() {
    return environment.baseUrl + 'login';
  }

  // redirecttologin() {
  //   this.router.navigate(['/login']);
  // }

  trim(str: string) {
    return str.replace(/ /g, '');
  }

  PostForgotPassword(email: any, tenantProfileId: any) {
    this.submited = true;
    email = this.trim(email);
    if (typeof email == 'undefined' || email == "" || email == null) {
      return false;
    }
    else if (!this.validateEmail("", this.trim(email))) {
      return false;
    }
    else {
      this.userService.PostForgotPassword(email, tenantProfileId).subscribe(results => {
        if (results != null && results != "" && results != 'undefined') {
          this.submited = false;
          if (results == 'ForgotPasswordConfirmation') {

            Swal.fire({
              icon: 'success',
              title: 'An URL has been sent to the provided Email address for resetting your password',
              showConfirmButton: false,
              confirmButtonColor: "#a5dc86",
              timer: 1500
            })
            this.router.navigate(['/login']);
          }
          else {
            this.toastralert.toastrerror('Unable to fetch the details for the given Email');
            this.submited = false;
          }
        }
        else {
          this.toastralert.toastrerror('Error occured while fecting the details for the given EmailId');
          this.submited = false;
        }
      }, err => {
        if (err.error.text == "ForgotPasswordConfirmation") {
          this.submited = false;
          Swal.fire({
            icon: 'success',
            title: 'Verified!',
            text: "An URL has been sent to the provided Email address for resetting your password",
            showConfirmButton: true,
            confirmButtonColor: "#a5dc86",
          })
          this.router.navigate(['/login']);
        } else {
          this.toastralert.toastrerror('Unable to fetch the details for the given Email');
        }
      });
    }
  }

  validateEmail(event: any, email: any) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isEmailValid = re.test(email);
    if (this.isEmailValid) {
      if ((this.tenantProfileId == null || this.tenantProfileId == "" || this.tenantProfileId == 'undefined')) {
        this.submited = false;
        this.tenantcompanies = [];
        this.userService.GetTenantsByUser(email).subscribe((results) => {
          if ((results != null && results != "" && results != 'undefined')) {
            this.tenantcompanies = results.tenantByUserModel;
            if (this.tenantcompanies.length === 1) {
              this.tenantProfileId = this.tenantcompanies[0].TenantProfileId;
              this.isShowtenantdropdown = false;
            }
            else {
              this.tenantProfileId = '';
              this.isShowtenantdropdown = true;
            }
            if (this.tenantcompanies.length === 0 || this.tenantcompanies == null) {
              this.isShowtenantdropdown = false;
              this.tenantProfileId = null
            }
          }
        })
      }
      else {
        if (this.tenantcompanies.length === 1) {
          this.isShowtenantdropdown = false;
        }
        else {
          this.isShowtenantdropdown = true;
        }
      }
    }
    else {
      this.isShowtenantdropdown = false;
    }
    return this.isEmailValid;
  }

  changetenant() {
    this.tenantProfileId = '';
  }
}
