<div class="container mt-4">
    <div class="float-end">
        <a class="text-dark pe-3">Already have an account?</a>
        <button class=" btn btn-primary" routerLink="/login">Log
            in</button>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-6 col-sm-12">
            <aw-wizard [navBarLayout]="'large-empty-symbols'">
                <aw-wizard-step class="font-size-12" [canExit]="canExitStep1()">
                    <ng-template awWizardStepSymbol>1</ng-template>
                    <div class="card shadow-lg rounded-4">
                        <div class="row align-middle align-items-center mx-0">
                            <div class="col-sm-8 col-md-8 col-lg-8">
                                <div class="p-4 ps-2">
                                    <h4 class="text-primary">Sign Up</h4>
                                    <p class="fw-bold text-muted">Unlock seamless project management join our D9 Today
                                    </p>
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                                <img src="../assets/images/D9.Software.Logo.png" alt="logo" class="img-fluid">
                            </div>
                        </div>
                        <div class="card-body pt-0">
                            <div class="mb-3">
                                <label for="email" class="fw-bold font-size-13">Email<span class="text-danger">*</span></label>
                                <input type="email" class="form-control" placeholder="Enter Email"
                                    [(ngModel)]="tenant.userEmail" (input)="validateEmail(trim(tenant.userEmail))">
                                <div
                                    *ngIf="submitStep1 &&  (tenant.userEmail == undefined || tenant.userEmail == '' || tenant.userEmail == null)">
                                    <span class="text-danger"> Please enter User Email</span>
                                </div>
                                <div
                                    *ngIf="submitStep1 && !isEmailValid && (tenant.userEmail != undefined && tenant.userEmail != '' && tenant.userEmail != null)">
                                    <span class="text-danger">Please enter a valid Email</span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="userpassword" class="fw-bold font-size-13">Password<span class="text-danger">*</span></label>
                                <div class="fv-row mt-1 position-relative">
                                    <input [type]="changetype?'password':'text'" class="form-control input-group"
                                        placeholder="Enter Password" aria-label="Password"
                                        (input)="validatePassword(tenant.userPassword)"
                                        (input)="validatePasswordMatching(tenant.ConfirmPassword)"
                                        [(ngModel)]="tenant.userPassword">
                                    <span class="position-absolute translate-middle top-50 end-0 me-2 cursor-pointer"
                                        style="top: 20px !important;" (click)="viewpass()"><i
                                            [ngClass]="visible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
                                </div>
                                <div
                                    *ngIf="submitStep1 && (tenant.userPassword == undefined || tenant.userPassword == '' || tenant.userPassword == null)">
                                    <span class="text-danger">Please enter your New Password</span>
                                </div>
                                <div
                                    *ngIf="submitStep1 && !isPasswordValid && (tenant.userPassword != undefined && tenant.userPassword != '' && tenant.userPassword != null)">
                                    <span class="text-danger">Password must contain at least one capital letter,
                                        at least one number and at least one special character</span>
                                </div>
                                <div
                                    *ngIf="submitStep1 && isPasswordValid && (tenant.userPassword != undefined && tenant.userPassword != '' && tenant.userPassword != null) && (tenant.userPassword.length < 8)">
                                    <span class="text-danger">Password must contain at least 8 characters</span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="userpassword" class="fw-bold font-size-13">Confirm Password<span class="text-danger">*</span></label>
                                <div class="fv-row mt-1 position-relative">
                                    <input [type]="confirmchangetype?'password':'text'" class="form-control input-group"
                                        placeholder="Enter Confirm Password" aria-label="Password"
                                        (input)="validatePasswordMatching(tenant.ConfirmPassword)"
                                        [(ngModel)]="tenant.ConfirmPassword">
                                    <span class="position-absolute translate-middle top-50 end-0 me-2 cursor-pointer"
                                        style="top: 20px !important;" (click)="confirmviewpass()"><i
                                            [ngClass]="confirmvisible?'fa fa-eye':'fa fa-eye-slash'"></i></span>
                                </div>
                                <div
                                    *ngIf="submitStep1 && (tenant.ConfirmPassword == undefined || tenant.ConfirmPassword == '' || tenant.ConfirmPassword == null)">
                                    <span class="text-danger">Please re-enter the above Password for
                                        confirmation.</span>
                                </div>
                                <div
                                    *ngIf="submitStep1 && (this.tenant.ConfirmPassword != this.tenant.userPassword) && (tenant.ConfirmPassword != undefined && tenant.ConfirmPassword != '' && tenant.ConfirmPassword != null)">
                                    <span class="text-danger">New password and confirm password must be same</span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="firstname" class="fw-bold font-size-13">First Name<span class="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Enter First Name"
                                    [(ngModel)]="tenant.userFirstName" (keydown)="onKeyDown($event)">
                                <div
                                    *ngIf="submitStep1 && (tenant.userFirstName == undefined || tenant.userFirstName== '' || tenant.userFirstName == null)">
                                    <span class="text-danger">Please enter First Name</span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="lastname" class="fw-bold font-size-13">Last Name<span class="text-danger">*</span></label>
                                <input type="text" placeholder="Enter Last Name" class="form-control"
                                    [(ngModel)]="tenant.userLastName" (keydown)="onKeyDown($event)">
                                <div
                                    *ngIf="submitStep1 && (tenant.userLastName == undefined || tenant.userLastName== '' || tenant.userLastName == null)">
                                    <span class="text-danger">Please enter Last Name</span>
                                </div>
                            </div>
                            <div class="form-check mt-3">
                                <input class="form-check-input" type="checkbox" id="formCheck1">
                                <label class="form-check-label" for="formCheck1">
                                    By signing up you agree to our Privacy Statements and Terms and Conditions
                                </label>
                            </div>
                            <div class="mt-3 d-grid  wizard">
                                <button class="btn btn-primary btn-block" awNextStep (click)="validateStep1()">Let's
                                    Go</button>
                            </div>
                            <hr class="border border-secondary border-1 opacity-25">
                            <div class="d-flex justify-content-center">
                                <button class=" btn rounded-4 p-2 border-primary btn-sm border-opacity-50 mt-2">

                                    <i class="fab fa-google me-5 text-secondary"></i><span
                                        class="me-2 font-size-14">Sign Up with Google</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </aw-wizard-step>
                <aw-wizard-step>

                    <ng-template awWizardStepSymbol>2</ng-template>
                    <div class="card shadow-lg rounded-4">
                        <div class="row align-middle align-items-center mx-0">
                            <div class="col-sm-8 col-md-8 col-lg-8">
                                <div class="p-4 ps-2">
                                    <h4 class="text-secondary mt-4">Company Details</h4>
                                </div>
                            </div>
                            <div class="col-sm-4 col-md-4 col-lg-4">
                                <img src="../assets/images/D9.Software.Logo.png" alt="logo" class=" img-fluid">
                            </div>
                        </div>
                        <div class="card-body pt-1">
                            <label for="company name" class="fw-bold font-size-13">Company Name<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter Company"
                                [(ngModel)]="tenant.companyName">
                            <div
                                *ngIf="updatetenant && (tenant.companyName == undefined || tenant.companyName== '' || tenant.companyName == null)">
                                <span class="text-danger">Please enter Company Name</span>
                            </div>
                            <div class="mt-4">
                                <label for="email" class="fw-bold font-size-13">Country<span class="text-danger">*</span></label>
                                <ng-select data-control="select2" data-hide-search="false"
                                    [(ngModel)]="tenant.companyCountry" [clearable]="false" placeholder="Select Client">
                                    <ng-option [value]="''" disabled>Select Country
                                    </ng-option>
                                    <ng-option [value]="'US'">United States
                                    </ng-option>
                                    <!-- <ng-option [value]="'CA'">Canada
                                    </ng-option>
                                    <ng-option [value]="'IN'">India
                                    </ng-option>
                                    <ng-option [value]="'LK'">Srilanka
                                    </ng-option>
                                    <ng-option [value]="'UK'">United Kindoms
                                    </ng-option> -->
                                </ng-select>
                                <div
                                    *ngIf="updatetenant && (tenant.companyCountry == undefined || tenant.companyCountry== '' || tenant.companyCountry == null)">
                                    <span class="text-danger">Please select Country</span>
                                </div>
                            </div>
                            <div class="mt-4">
                                <label for="timezone" class="fw-bold font-size-13">Time Zone<span class="text-danger">*</span></label>
                                <ng-select [multiple]="false" [(ngModel)]="tenant.companyTimezone"
                                    class="cursor-pointer" [clearable]="false">
                                    <ng-option [value]="''" disabled>Select Time Zone
                                    </ng-option>
                                    <ng-option *ngFor='let timezone of timezones'
                                        [value]="timezone.TimeZoneId">{{timezone.TimeZoneName}}</ng-option>
                                </ng-select>
                                <div
                                    *ngIf="updatetenant && (tenant.companyTimezone == undefined || tenant.companyTimezone== '' || tenant.companyTimezone == null)">
                                    <span class="text-danger">Please select Time Zone</span>
                                </div>
                            </div>
                            <ul class="list-inline wizard mb-0 mt-4">
                                <li class="previous list-inline-item float-start"><button class="btn btn-primary" (click)="updatetenant = false"
                                        awPreviousStep>Previous</button>
                                </li>
                                <li class="next list-inline-item float-end"><button class="btn btn-primary "
                                        (click)="SaveOrUpdateTenantProfile(tenant)">Sign
                                        Up</button>
                                </li>
                            </ul>
                        </div>
                    </div>

                </aw-wizard-step>
            </aw-wizard>
        </div>
    </div>
</div>