<!-- Right Sidebar -->

<div class="right-bar">

  <ngx-simplebar class="h-100">
    <div class="rightbar-title px-3 py-2">
      <div class="float-end">
        <a href="javascript:void(0);" class="right-bar-toggle float-end" (click)="hide();">
          <i class="mdi mdi-close noti-icon"></i>
        </a> 
      </div>
        <div class="d-flex justify-content-between mb-2">      
          <h5 class="m-0">Notifications</h5>
            <i class="bx bx-check-double font-size-24 me-4 text-primary" ngbTooltip="Read All"></i>
          </div>
    <div class="scribe-scroll" style="overflow-y: scroll;height: 700px;">
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Rohini added a comment</div>
            <div><span class="text-secondary">Jul 19</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @saicharanp @chittibabuk @rohinik @manivarmad</div>
          <div>Preview of file - D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Rohini added a comment</div>
            <div><span class="text-secondary">Jul 19</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Suguna added a comment</div>
            <div><span class="text-secondary">Jul 29</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Charishma added a comment</div>
            <div><span class="text-secondary">Aug 1</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">chittibabu added a comment</div>
            <div><span class="text-secondary">Aug 1</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Prasannalakshmi added a comment</div>
            <div><span class="text-secondary">Aug 1</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Pravallika added a comment</div>
            <div><span class="text-secondary">Aug 1</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Suguna added a comment</div>
            <div><span class="text-secondary">Aug 1</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Nani added a comment</div>
            <div><span class="text-secondary">Aug 1</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Charishma added a comment</div>
            <div><span class="text-secondary">Aug 1</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
      <div class="d-flex align-items-center p-1 w-100 cursor-pointer">
        <div class="">
          <img src="../../../assets/images/avatar.png" class="rounded-circle" width="30" height="30">
        </div>
        <div class="w-85 px-2">
          <div class="d-flex justify-content-between font-size-12">
            <div class="text-primary">Nani added a comment</div>
            <div><span class="text-secondary">Aug 1</span> <i class="bx bxs-check-circle text-primary"></i> </div>
          </div>
          <div class="font-size-14 text-dark fw-bold text-truncate w-75">@sugunak @charishmam @prasannalakshmi  @chittibabuk</div>
          <div>SaveOrUpdate Comments -D9 project</div>
        </div>
      </div>
    </div>
    </div>
  </ngx-simplebar>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>