import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  token_response: any;

  constructor(private http: HttpClient) { 
    this.token_response = JSON.parse(localStorage.getItem("d9_token_responseuat") || '{}');

  }

  SaveOrUpdateTenantCompany(clientdetails: any) {
    return this.http.post(environment.SaveOrUpdateTenantCompany, clientdetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));

  }
  GetTenantCompaniesByCompanyId(TenantProfileId: any, UserProfileId: any, TenantCompanyId: any) {
    return this.http.get(environment.GetTenantCompaniesByCompanyId + '/' + TenantProfileId + '/' + UserProfileId + '/' + TenantCompanyId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveDeleteTenantCompanies(deletetenantcompanies: any) {
    return this.http.post(environment.SaveDeleteTenantCompanies, deletetenantcompanies, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  Saveclient(client: any) {
    return this.http.post(environment.SaveClient, client, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetProjectsByCompanyId(tenantProfileId: any, userProfileId: any, tenantCompanyId: any, pagenum: any) {
    let UserIsAdmin = (this.token_response.UserRoleName == 'Administrator' || this.token_response.UserRoleName == "Owner") ? true : false;
    return this.http.get(environment.GetProjectsByCompanyId + '/' + tenantProfileId + '/' + userProfileId + '/' + tenantCompanyId + '/' + pagenum + '/' + UserIsAdmin)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetUsersByCompanyId(tenantProfileId: any, userProfileId: any, tenantCompanyId: any) {
    return this.http.get(environment.GetUsersByCompanyId + '/' + tenantProfileId + '/' + userProfileId + '/' + tenantCompanyId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  DSBTenantsData() {
    return this.http.get(environment.DSBTenantsData)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  DSBTenantDataByTenantProfileId(tenantProfileId: any, userProfileId: any) {
    return this.http.get(environment.DSBTenantDataByTenantProfileId + '/' + tenantProfileId + '/' + userProfileId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  // TenantsFilter(timedetails:any){
  //   return this.http.post(environment.TenantsFilter,timedetails)
  //   .pipe(map((res: any) => {
  //    return res;
  //  }), catchError(error => {
  //    console.log(error);
  //    return throwError(error);
  //  }));
  // }

  TenantsFilter(timedetails: any) {
    return this.http.post(environment.TenantsFilter, timedetails, {
      responseType: 'json'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }


}
