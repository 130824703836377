import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError, timeout, timeoutWith, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  defaultTimeout = 2000000; // 
  token_response:any;
   token:any;
  constructor(private http: HttpClient) { 
    this.token_response = JSON.parse(localStorage.getItem("d9_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  GetCoreLookUpByCategory(category:any) {
    return this.http.get(environment.GetCoreLookUpByCategory  + '/' + this.token_response.tenantId + '/' + this.token_response.userId + '/' + category)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetFileURL(filepath:any) {
    return this.http.get(environment.GetFileURL + '/' + filepath, { responseType: 'text' })
    .pipe(
      map((res: any) => {
        return res;
      }),
      catchError(error => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  GetDownloadFile(filename: string): Observable<HttpResponse<ArrayBuffer>> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const body = { filename };
    return this.http.post(environment.DownloadFilesFromS3Bucket, body, { headers, responseType: 'arraybuffer', observe: 'response', reportProgress: true });
    // return this.http.get(environment.DownloadFilesFromS3Bucket + '/' + filename, { headers, responseType: 'arraybuffer', observe: 'response' });
  }

  SaveFilesToS3Bucket(file: any,tenantid :any,folderpath:any) {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('tenantId', tenantid);
    formdata.append('folderpath',folderpath);
    return this.http.post(environment.SaveFilesToS3Bucket, formdata, {
      responseType: 'json', 
      reportProgress: true,
    }).pipe(timeoutWith(this.defaultTimeout, timer(this.defaultTimeout)),map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  UploadUserFilesToS3Bucket(file: any,tenantid :any,folderpath:any) {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('tenantId', tenantid);
    formdata.append('folderpath',folderpath);
    return this.http.post(environment.UploadUserFilesToS3Bucket, formdata, {
      responseType: 'json'
    }).pipe(timeoutWith(this.defaultTimeout, timer(this.defaultTimeout)),map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  UploadFileInPublicBucket(file: any,tenantid :any,folderpath:any,filenameExtension:any) {
    const formdata = new FormData();
    formdata.append('file', file);
    formdata.append('tenantId', tenantid);
    formdata.append('folderpath',folderpath);
    formdata.append('filenameExtension',filenameExtension);
    return this.http.post(environment.UploadFileInPublicBucket, formdata, {
      responseType: 'json'
    }).pipe(timeoutWith(this.defaultTimeout, timer(this.defaultTimeout)),map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetFileURLFromS3Bucket(filename: any) {
    return this.http.post(environment.GetFileURLFromS3Bucket, filename, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveDeleteFileInS3Bucket(file: any){
    const formdata = new FormData();
    formdata.append('file', file);
    return this.http.post(environment.SaveDeleteFileInS3Bucket, formdata, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveOrUpdatecoreFiles(filedata) {
    return this.http.post(environment.SaveOrUpdatecoreFiles, filedata, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetcoreFilesByTaskId(tenantId: any, UserProfileId: any, taskId: any) {
    return this.http.get(environment.GetcoreFilesByTaskId + '/' + tenantId + '/' + UserProfileId + '/' + taskId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetFilesByFilter(getfilemodel: any) {
    return this.http.post(environment.GetFilesByFilter,getfilemodel, {
      responseType: 'json'
    })
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveDeletecoreFileByFileId(filesDetails: any) {
    return this.http.post(environment.SaveDeletecoreFileByFileId, filesDetails, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  getCoreFileByFileId(tenantId: any, UserProfileId: any,fileId:any) {
    return this.http.get(environment.GetCoreFileByFileId + '/' + tenantId + '/' + UserProfileId + '/' + fileId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  UpdatecoreFileVersion(filedata) {
    return this.http.post(environment.UpdatecoreFileVersion, filedata, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveGetFileURLFromS3(filename:any) {
        return this.http.post(environment.SaveGetFileURLFromS3, filename, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

}