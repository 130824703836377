<!-- ========== Left Sidebar Start ========== -->
<!-- <header id="page-topbar">
  <div class="navbar-header mx-0 mw-100 h-50px">

  </div>
</header> -->
<ng-template #contentTemplate>

  <!--- Sidemenu -->
  <div id="sidebar-menu" class="">
    <!-- LOGO -->
    <div class="navbar-brand-box d-flex align-items-center position-relative">
      <a class="logo logo-dark">
        <span class="logo-sm">
          <img src="assets/images/Apoorva_Logo_Square.png" alt="" height="22">
        </span>
        <span class="logo-lg">
          <img src="assets/images/ApoorvaWhiteLogo 35x116.png" alt="" height="35">
        </span>
      </a>

      <a class="logo logo-light">
        <span class="logo-sm">
          <img src="assets/images/Apoorva_Logo_Square.png" class="rounded-circle" alt="" height="30">
        </span>
        <span class="logo-lg logo-md">
          <img src="assets/images/ApoorvaWhiteLogo 35x116.png" alt="" height="35">
        </span>
      </a>
      <!-- <div class="ms-2 text-white font-size-16"><i (click)="toggleMobileMenu($event)"
          class="fa fa-fw fa-bars cursor-pointer"></i></div> -->

      <div class="btn btn-sm bg-white position-absolute top-50 p-1 translate-middle rotate" style="left:96%">
        <!--begin::Svg Icon | path: icons/duotune/arrows/arr079.svg-->
        <span class="svg-icon svg-icon-2 rotate-180" (click)="toggleMobileMenu($event)">
          <!-- <i class="fa fa-fw fa-bars cursor-pointer"></i> -->
          <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
            class="toggle-icon">
            <path opacity="0.5"
              d="M14.2657 11.4343L18.45 7.25C18.8642 6.83579 18.8642 6.16421 18.45 5.75C18.0358 5.33579 17.3642 5.33579 16.95 5.75L11.4071 11.2929C11.0166 11.6834 11.0166 12.3166 11.4071 12.7071L16.95 18.25C17.3642 18.6642 18.0358 18.6642 18.45 18.25C18.8642 17.8358 18.8642 17.1642 18.45 16.75L14.2657 12.5657C13.9533 12.2533 13.9533 11.7467 14.2657 11.4343Z"
              fill="currentColor" />
            <path
              d="M8.2657 11.4343L12.45 7.25C12.8642 6.83579 12.8642 6.16421 12.45 5.75C12.0358 5.33579 11.3642 5.33579 10.95 5.75L5.40712 11.2929C5.01659 11.6834 5.01659 12.3166 5.40712 12.7071L10.95 18.25C11.3642 18.6642 12.0358 18.6642 12.45 18.25C12.8642 17.8358 12.8642 17.1642 12.45 16.75L8.2657 12.5657C7.95328 12.2533 7.95328 11.7467 8.2657 11.4343Z"
              fill="currentColor" />
          </svg>
        </span>
        <!--end::Svg Icon-->
      </div>

    </div>
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled fw-500 fs-6" id="side-menu" #sideMenu>
      <!---------------User profile at top---------------->
      <li ngbDropdown>
        <a class="side-nav-link-ref cursor-pointer btn header-item d-flex justify-content-start px-1"
          id="page-header-user-dropdown" ngbDropdownToggle>
          <div class="profile-lg d-flex align-items-center justify-content-around">
            <div>
            <img class="rounded-circle header-profile-user" [src]="UserImageUrl" alt="">
          </div>
            <span class="d-none d-xl-inline-block ms-1 profilename text-truncate align-middle fs-6 fw-bold text-light"
              [class.active]="activatedtabfile == 'Myprofile'">{{username}}</span>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </div>
          <div class="profile-sm ps-3"><img class="rounded-circle header-profile-user" [src]="UserImageUrl" alt="">
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-start ms-2" ngbDropdownMenu>
          <a class="dropdown-item py-1 px-3 font-size-14" href="javascript: void(0);"
            (click)="redirectToUserProfile()"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            Profile</a>
          <ng-container *ngFor="let item of menuItems">
            <a class="dropdown-item py-1 px-3 font-size-14" *ngIf="item.SysMenuCategory == 'LeftSubMenu'"
              [routerLink]="item.SysPageURL"><i class="{{item.SysMenuIcon}} font-size-16 align-middle me-1"></i>
              {{ item.SysPage | translate }} </a>
          </ng-container>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item py-1 px-3 text-danger font-size-14" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Log Out</a>
        </div>
      </li>
      <!---------------User profile at top---------------->
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuItems">
        <li *ngIf="(!item.isTitle && !item.isLayout) && (item.SysMenuCategory == 'LeftMenu')">
          <a [routerLink]="item.SysPageURL" class="side-nav-link-ref fs-6 cursor-pointer"
            [class.active]="activatedtabfile == item.SysPage">
            <i class="bx {{ item.SysMenuIcon }}"></i>
            <span>{{ item.SysPage | translate }}</span>
            <i class="bx text-end" *ngIf="hasItems(item)"
              [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" (click)="show =!show"></i>
          </a>
          <ul *ngIf="hasItems(item) && show" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems">
              <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)"
                class="side-nav-link-ref fs-6 cursor-pointer" [attr.data-parent]="subitem.parentId"
                routerLinkActive="active">
                {{ subitem.label | translate}}
                <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end"
                  *ngIf="subitem.badge">{{subitem.badge.text |
                  translate}}</span>
              </a>
              <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                [attr.data-parent]="subitem.parentId">
                {{ subitem.label | translate}}
              </a>
              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active"
                    class="side-nav-link-ref fs-6 cursor-pointer">
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
      <li>
        <a class="side-nav-link-ref fs-6 cursor-pointer" routerLink="/globalSearch"
          [class.active]="activatedtabfile == 'globalSearch'">
          <i class="bx bx-search-alt"></i>
          <span>Search</span>
        </a>
      </li>
      <!-- <li>
        <a class="side-nav-link-ref fs-6 cursor-pointer" (click)="toggleRightNotifications()">
          <i class="bx bx-bell bx-tada text-white"></i>
          <span>Notifications</span>
          <i class="bx bx-bell  text-white"><sup><span class="badge bg-danger rounded-pill">3</span></sup></i>
          <span> Notifications
          </span>
        </a>
      </li> -->
      <!-- <li>
        <a class="side-nav-link-ref fs-6 cursor-pointer" [href]="dynamiclink" target="_blank">
          <i class="bx bx-help-circle"></i>
          <span>Help Center</span>
        </a>
      </li> -->

      <!-- <li>
        <a class="side-nav-link-ref font-size-15 cursor-pointer" (click)="getProjectid()" (click)="centerlargeModal(addTaskModal)">
          <i class="bx bx-plus"></i>
          <span> Add Task
          </span>
        </a>
      </li> -->
      <!-- <li>
        <a class="side-nav-link-ref font-size-15 cursor-pointer" (click)="centerLargeModal(centerDatasModal)">
          <i class="bx bx-plus"></i>
          <span> Add Project
          </span>
        </a>
      </li> -->

    </ul>

    <div class="fab-wrapper">
      <input id="fabCheckbox" type="checkbox" class="fab-checkbox" />
      <!-- <i class="bx bx-trash fab"  for="fabCheckbox"></i> -->
      <label class="fab bg-primary cursor-pointer" for="fabCheckbox">
        <div class="p-2">
          <i class="bx bx-plus text-white font-size-20" id="floating"></i>
          <i class="bx bx-x text-white font-size-20" id="floatings"></i>
        </div>
        <span class="fab-dots fab-dots-1"></span>
        <span class="fab-dots fab-dots-2"></span>
        <span class="fab-dots fab-dots-3"></span>
      </label>
    
      <div class="fab-wheel">
        <a class="fab-action fab-action-1 p-2 cursor-pointer bg-primary" *ngIf="token_response.UserRoleName == 'Owner' || token_response.UserRoleName == 'Administrator'"
        ngbTooltip="Add Project" (click)="centerLargeModal(centerDatasModal)" (click)="getAllCategoriesOrTagsByTenantProfileId()">
          <!-- <i class="fas fa-question"></i> -->
          <i class="bx bx-folder-plus font-size-20"></i>
        </a>
        <a class="fab-action fab-action-2 p-2 cursor-pointer bg-primary"  ngbTooltip="Add Task" (click)="getProjectid()"
        (click)="centerlargeModal(addTaskModal)">
          <!-- <i class="fas fa-book"></i> -->
          <i class="bx bx-task font-size-20"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="bottom-logo text-center ps-2 position-fixed">
    <span class="logo-sm">
      <img src="assets\images\d9-favicon.png" alt="" height="50">
    </span>
    <span class="logo-lg">
      <img src="assets\images\d9 35px.png" width="150" height="35">
    </span>
  </div>
  <!-- <div class="bottom-logo text-center ps-2">
    <ul class="list-unstyled mb-0">
      <li ngbDropdown>
        <a class="side-nav-link-ref font-size-15 cursor-pointer btn header-item d-flex justify-content-start align-items-center"
          id="page-header-user-dropdown" ngbDropdownToggle>
          <div class="profile-lg text-white">
            <img class="rounded-circle header-profile-user" [src]="UserImageUrl" alt="">
            <span class="d-sm-none d-lg-inline-block d-md-inline-block d-xl-inline-block ms-1">{{username}}</span>
            <i class="mdi mdi-chevron-down d-sm-none d-lg-inline-block d-md-inline-block d-xl-inline-block"></i>
          </div>
          <div class="profile-sm"><img class="rounded-circle header-profile-user" [src]="UserImageUrl" alt=""></div>
        </a>
        <div class="dropdown-menu dropdown-menu-start" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript: void(0);" (click)="redirectToUserProfile()"><i
              class="bx bx-user font-size-16 align-middle me-1"></i>
            Profile</a>
          <ng-container *ngFor="let item of menuItems">
            <a class="dropdown-item" *ngIf="item.SysMenuCategory == 'LeftSubMenu'" [routerLink]="item.SysPageURL"><i
                class="{{item.SysMenuIcon}} font-size-16 align-middle me-1"></i>
              {{ item.SysPage | translate }} </a>
          </ng-container>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Log Out</a>
        </div>
      </li>
    </ul>
  </div> -->
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->

<!-- add task -->
<ng-template #addTaskModal let-modal>
  <div class="modal-header p-2">
    <div>
      <h5 class="modal-title font-size-16">Quick add task</h5>
    </div>

    <button type="button" class="btn-close font-size-10" data-bs-dismiss="modal" aria-label="Close"
      (click)="cleartaskdetailsmodel()"></button>
  </div>
  <div class="modal-body pt-1">

    <div class="mt-2">
      <label class="col-form-label py-1">Task Name<span class="text-danger">*</span></label>
      <input class="form-control p-1" type="text" placeholder="What needs to be done?"
        [(ngModel)]="newtaskdetails.TaskName">
      <div style="margin-top: 5px;"
        *ngIf=" createdtasktriggered &&(newtaskdetails.TaskName == undefined || newtaskdetails.TaskName == '' || newtaskdetails.TaskName == null)">
        <span class="text-danger"> Please enter Task Name </span>
      </div>
    </div>
    <div class="row">
    <div class="mt-2 col-sm-6 col-md-6 col-lg-6">
      <label class="col-form-label py-1">Project<span class="text-danger">*</span></label>
      <ng-select [multiple]="false" [(ngModel)]='destinationproject' [clearable]="false" placeholder="Select a project"
        aria-placeholder="Select Project" (change)="onProjectSelectionChange()"
        (change)="onproject(destinationproject)">
        <ng-option [value]="" disabled>select Project</ng-option>
        <ng-option [value]="Project.ProjectId" *ngFor='let Project of projectlists'>{{Project.ProjectName}}</ng-option>
      </ng-select>
      <div style="margin-top: 5px;"
        *ngIf=" createdtasktriggered &&(destinationproject == undefined || destinationproject == '' || destinationproject == null)">
        <span class="text-danger"> Please Select a Project</span>
      </div>
    </div>

    <div class="mt-2 col-sm-6 col-md-6 col-lg-6">
      <label class="col-form-label py-1"> Tasklist<span class="text-danger">*</span></label>
      <ng-select [clearable]="false" [(ngModel)]='destinationTasklist' [disabled]="!isProjectSelected"
        placeholder="Select a Tasklist" (change)="GetGroupListByauxGroupItemId(destinationTasklist)">
        <ng-option [value]="Projecttasks.ObjectId"
          *ngFor='let Projecttasks of projecttasklist'>{{Projecttasks.ObjectName}}</ng-option>
      </ng-select>
      <div style="margin-top: 5px;"
        *ngIf=" createdtasktriggered &&(destinationTasklist == undefined || destinationTasklist == '' || destinationTasklist == null)">
        <span class="text-danger"> Please enter Task Name </span>
      </div>
    </div>
    </div>

    <div class="row py-2">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <label class="col-form-label py-1">Who should do this?</label>
        <ng-select [clearable]="false" [searchFn]="customSearchFn" [items]="userprofiles" placeholder="Anyone"
          [multiple]="true" [(ngModel)]="userselectedlist" [disabled]="!isProjectSelected" [closeOnSelect]="false"
          [clearSearchOnAdd]="true" bindValue="UserProfileId">
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of items.slice(0, 2)">
              <span class="ng-value-label">{{ item.UserFirstName }} {{ item.UserLastName }}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
            </div>

            <div class="ng-value" *ngIf="items.length > 2">
              <span class="ng-value-label">+{{ items.length - 2 }} more</span>
            </div>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            {{item.UserFirstName}} {{item.UserLastName}}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <label class="col-form-label py-1">Start Date</label>
        <div class="col-md-10">
          <input class="form-control cursor-pointer" type="date" value="2019-08-19" id="example-date-input"
            [(ngModel)]="newtaskdetails.TaskStartDate" (keydown)="restricttyping($event)">
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3">
        <label class="col-form-label py-1">End Date</label>
        <div class="col-md-10">
          <input class="form-control cursor-pointer" type="date" value="2019-08-19" id="example-date-input"
            [(ngModel)]="newtaskdetails.TaskDueDate" (keydown)="restricttyping($event)">

        </div>
        <div style="margin-top: 5px;"
          *ngIf="createdtasktriggered && (((newtaskdetails.TaskDueDate != undefined && newtaskdetails.TaskDueDate != '' && newtaskdetails.TaskDueDate != null)&&(newtaskdetails.TaskStartDate != undefined && newtaskdetails.TaskStartDate != '' && newtaskdetails.TaskStartDate != null))&&(newtaskdetails.TaskDueDate < newtaskdetails.TaskStartDate))">
          <span class="text-danger">End date should be greater then start date</span>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <ckeditor style="height: 50px;" (ready)="onReady($event)" [config]="ckeditorConfig" [editor]="Editor"
          data="<p>Add a description</p>" [(ngModel)]="newtaskdetails.TaskDescription"></ckeditor>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary waves-effect px-2 py-1" data-bs-dismiss="modal"
      (click)="onclickcanceltask(newtaskdetails)">Cancel</button>
    <button type="button" class="btn btn-primary waves-effect waves-light px-2 py-1" [disabled]="buttononclicksave"
      [disabled]="isUploadComplete" (click)="SaveOrUpdatecoreTasks(newtaskdetails,true , false)">Create Task</button>
  </div>
</ng-template>

<ng-template #cancelTaskconfirmation let-modal>
  <div class="modal-header p-2 bg-light">
    <div>
      <h5 class="modal-title font-size-16 text-dark">You have Changes</h5>
    </div>
    <button type="button" class="btn-close font-size-10" data-bs-dismiss="modal" aria-label="Close"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body pt-1">
    <label for="tags" class="col-form-label py-1">Are you sure?</label>
  </div>
  <div class="modal-footer p-0">
    <button type="button" class="btn btn-secondary waves-effect p-1 m-1" data-bs-dismiss="modal"
      (click)="modal.close('Close click')">Cancel</button>
    <button type="button" class="btn btn-primary waves-effect waves-light p-1 m-1" (click)="cleartaskdetailsmodel()">Ok
    </button>
  </div>
</ng-template>

<!-- add project -->

<ng-template #centerDatasModal let-modal>
  <div class="modal-header p-2">
    <div>
      <h5 class="modal-title font-size-14">Create a Project</h5>
    </div>
    <button type="button" class="btn-close font-size-10" data-bs-dismiss="modal" aria-label="Close"
      (click)="cancelModel()"></button>

  </div>
  <div class="modal-body pt-1 ">
    <div>
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <label for="project-input" class="col-form-label py-1">Project Name<span class="text-danger">*</span></label>
          <input class="form-control p-1" [(ngModel)]="projectDetails.ProjectName" type="text" style="
          height: 36px;" placeholder=" Please enter Project Name" id="project-input">
          <div style="margin-top: 5px;"
            *ngIf="createprojecttriggered && (projectDetails.ProjectName == undefined || projectDetails.ProjectName == '' || projectDetails.ProjectName == null)">
            <span class="text-danger"> Please enter Project Name</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6">
          <label for="example-text-input" class="col-form-label py-1">Choose a Client<span
              class="text-danger">*</span></label>
          <a class="float-end cursor-pointer" (click)="toggleField()"><u>Add
              Client</u></a>
              <div *ngIf="showTextField">
                <input type="text" placeholder="Enter Company Name" style="
                height: 36px;" class="form-control p-1" id="project-input" [(ngModel)]="projectDetails.companyName">
                <span class="Cross" (click)="toggleField()">X</span>
                <div style="margin-top: 5px;"
                  *ngIf="createprojecttriggered && showTextField && (projectDetails.companyName == undefined || projectDetails.companyName == '' || projectDetails.companyName == null)">
                  <span class="text-danger">Please enter Client Name</span>
                </div>
              </div>
          <ng-select data-control="select2" data-hide-search="false" (change)="get_companyId($event)"
          *ngIf="!showTextField" [(ngModel)]="companyId" [clearable]="false" placeholder="Select Client">
            <ng-option [value]="''" disabled>Select Client
            </ng-option>
            <ng-option *ngFor='let option of tenantCompanieslist'
              [value]="option.TenantCompanyId">{{option.CompanyName}}</ng-option>
          </ng-select>
          <div style="margin-top: 5px;"
            *ngIf="createprojecttriggered && !showTextField && (companyId == undefined || companyId == '' || companyId == null)">
            <span class="text-danger">Please select a Client</span>
          </div>
        </div>
      </div>
      <div class="row py-2">
        <div class="col-lg-6 col-md-6 col-sm-6">
          <label for="project-input" class="col-form-label py-1">Add a Project Category</label>
          <a class="float-end cursor-pointer mt-1" (click)="togglecategoryField()"><u>Add Category</u></a>
          <div *ngIf="showcategoryTextField">
            <input type="text" placeholder="Enter Project Category Name" style="
            height: 36px;" class="form-control p-1" id="project-input" [(ngModel)]="newcategoryName">
            <span class="Cross" (click)="togglecategoryField()">X</span>
            </div>
          <ng-select [clearable]="false" [searchable]="true" (change)="get_categoryName($event)" *ngIf="!showcategoryTextField"
            [(ngModel)]="categoryName" placeholder="Select category">
            <ng-option [value]="''" disabled>Select category
            </ng-option>
            <ng-option *ngFor="let categories of categorydetails"
              [value]="categories.auxSubCategoryName">{{categories.auxSubCategoryName}}</ng-option>
          </ng-select>
          <!-- <div style="margin-top: 5px;"
            *ngIf="createprojecttriggered && (categoryName == undefined || categoryName == '' || categoryName == null)">
            <span class="text-danger">Please Choose a Category</span>
          </div> -->
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
          <label class="col-form-label py-1">Start Date</label>
          <div class="col-md-10">
            <input class="form-control" type="date" (keydown)="restricttyping($event)" value="2023-04-19"
              id="example-date-input" [(ngModel)]="projectDetails.ProjectStartDate">

          </div>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-3">
          <label class="col-form-label py-1">End Date</label>
          <div class="col-md-10">
            <input class="form-control" type="date" value="2023-04-19" id="example-date-input"
              [(ngModel)]="projectDetails.ProjectEndDate" (keydown)="restricttyping($event)">

          </div>
          <div style="margin-top: 5px;"
            *ngIf="createprojecttriggered && (((projectDetails.ProjectEndDate != undefined && projectDetails.ProjectEndDate != '' && projectDetails.ProjectEndDate != null)&&(projectDetails.ProjectStartDate != undefined && projectDetails.ProjectStartDate != '' && projectDetails.ProjectStartDate != null))&&(projectDetails.ProjectEndDate < projectDetails.ProjectStartDate))">
            <span class="text-danger">End date should be greater then start date</span>
          </div>

        </div>
      </div>
      <div class="row" [hidden]="(companyId == undefined || companyId == '' || companyId == null) && !showTextField">
        <label for="example-text-input" class="col-form-label py-1">Users<span class="text-danger">*</span></label>

        <div *ngFor="let companyData of usersData" class="px-3">
          <div class="row my-2">
            <div class="col-lg-2 col-md-2 col-sm-2"></div>
            <div class="col-lg-8 col-md-8 col-sm-8 text-dark font-size-14 text-center">
              {{companyData.CompanyName}}
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2"></div>
          </div>
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-2"></div>
            <div class="col-lg-8 col-md-8 col-sm-8">
              <ng-select [items]="companyData.UnSelectedUsersModelsList" placeholder="Select Item(s)" [multiple]="true"
                [(ngModel)]="companyData.SelectedUsersModelsList" [clearable]="false" [searchFn]="customSearchFn"
                [closeOnSelect]="false" [clearSearchOnAdd]="true">
                <ng-template ng-label-tmp let-item="item" let-clear="clear" (change)="get_companyusersId($event)">
                  <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
                  <span class="ng-value-label" [style.background-color]="item.kolor">{{item.UserFirstName}}
                    {{item.UserLastName}}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                  {{item.UserFirstName}} {{item.UserLastName}}
                </ng-template>
              </ng-select>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-2"></div>
          </div>
          <br>
        </div>

      </div>

      <div class="modal-footer pt-3 ">

        <button type="button" class="btn btn-secondary waves-effect px-2 py-1" data-bs-dismiss="modal"
          (click)="cancelModel()">Cancel</button>

        <button type="button" class="btn btn-primary waves-effect waves-light px-2 py-1"
          (click)="SaveOrUpdatecoreProjects(false,false)">Create Project</button>

      </div>

    </div>
  </div>
</ng-template>

<ng-template #addCompanyDetailsModal let-modal>
  <div class="modal-header p-2">
    <div class="d-flex">
      <h5 class="modal-title font-size-16 ms-2">Add Client</h5>
    </div>
    <button type="button" class="btn-close font-size-10" data-bs-dismiss="modal" aria-label="Close"
      (click)="clearclientmodel()"></button>
  </div>
  <div class="modal-body pt-1">
    <div>
      <ul ngbNav #customNav="ngbNav" [activeId]="1" class="nav-tabs nav-tabs-custom nav-justified mt-2">
        <li [ngbNavItem]="1" class="flex-grow-0">
          <a ngbNavLink class="py-0">
            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
            <span class="d-none d-sm-block font-size-14">Essential</span>
          </a>
          <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="EssentialContent"></ng-template>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" class="flex-grow-0">
          <a ngbNavLink class="py-0">
            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
            <span class="d-none d-sm-block font-size-14">Address</span>
          </a>
          <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="AddressContent"></ng-template>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" class="flex-grow-0">
          <a ngbNavLink class="py-0">
            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
            <span class="d-none d-sm-block font-size-14">Profile</span>
          </a>
          <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="ProfileContent"></ng-template>
          </ng-template>
        </li>
        <li [ngbNavItem]="4" class="flex-grow-0">
          <a ngbNavLink class="py-0">
            <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
            <span class="d-none d-sm-block font-size-14">Notes</span>
          </a>
          <ng-template ngbNavContent>
            <ng-template [ngTemplateOutlet]="NotesContent"></ng-template>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="customNav"></div>
      <ng-template #EssentialContent>
        <div class="px-3">
          <div class="row">
            <div class="col-lg-8 col-md-8 col-sm-8">
              <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <label class="col-form-label py-1" for="email-input">
                    Client<span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                  <input class="form-control p-1" type="text" placeholder="Enter Client" id="email-input"
                    [(ngModel)]="addClient.CompanyName">
                  <div style="margin-top: 5px;"
                    *ngIf="createdclienttriggered && (addClient.CompanyName == undefined || addClient.CompanyName == '' || addClient.CompanyName == null)">
                    <span class="text-danger"> Please enter Client Name</span>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <label class="col-form-label py-1" for="email-input">
                    Website
                  </label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                  <input class="form-control p-1" type="text" placeholder="Enter Website" id="email-input"
                    [(ngModel)]="addClient.CompanyWebsite">
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <label class="col-form-label py-1" for="email-input">
                    Email<span class="text-danger">*</span>
                  </label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                  <input class="form-control p-1" type="text" placeholder="Enter Email" id="email-input"
                    [(ngModel)]="addClient.CompanyEmail1" (input)="validateEmail(trim(addClient.CompanyEmail1))">
                  <div style="margin-top: 5px;"
                    *ngIf="createdclienttriggered && (addClient.CompanyEmail1 == undefined || addClient.CompanyEmail1 == '' || addClient.CompanyEmail1 == null)">
                    <span class="text-danger"> Please enter Client Email</span>
                  </div>
                  <div
                    *ngIf="createdclienttriggered && !isEmailValid && (addClient.CompanyEmail1 != undefined && addClient.CompanyEmail1 != '' && addClient.CompanyEmail1 != null)">
                    <span class="text-danger">Please enter a valid Email</span>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <label class="col-form-label py-1" for="company-input">
                    Industry
                  </label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                  <select class="form-select p-1 cursor-pointer">
                    <option value="1" selected>Administrator</option>
                    <option value="2">Agriculture & Horticulture</option>
                    <option value="3">Advertising</option>
                  </select>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <label class="col-form-label py-1" for="jobTitle-input">
                    Phone
                  </label>
                </div>
                <!-- <div class="col-lg-8 col-md-8 col-sm-8">
                                  <input class="form-control p-1" type="text" placeholder="Client" id="email-input"
                                      [(ngModel)]="addClient.CompanyName" >
                                  <div style="margin-top: 5px;"
                                      *ngIf="createdclienttriggered && (addClient.CompanyName == undefined || addClient.CompanyName == '' || addClient.CompanyName == null)">
                                      <span class="text-danger"> Please enter Client Name</span>
                                  </div>
                              </div> -->
                <div class="col-lg-8 col-md-8 col-sm-8">
                  <input class="form-control p-1" placeholder="Enter Phone" type="text" id="844-500-DATA"
                    [(ngModel)]="addClient.CompanyPhone" (input)="validatemobile(addClient.CompanyPhone)">

                  <!-- <div style="margin-top: 5px; " 
                              *ngIf="createdclienttriggered && (addClient.CompanyPhone == undefined || addClient.CompanyPhone == '' || addClient.CompanyPhone == null)">
                              <span class="text-danger"> Please enter phone number</span>
                          </div> -->
                  <div
                    *ngIf="createdclienttriggered && !ismobilevalid && (addClient.CompanyPhone != undefined && addClient.CompanyPhone != '' && addClient.CompanyPhone != null)">
                    <span class="text-danger">Please enter a valid phone number</span>
                  </div>
                </div>
                <!-- <div style="margin-top: 5px;"
                             *ngIf="createdclienttriggered && (isNullOrWhiteSpace(addClient.CompanyPhone) || !isPhoneNumberValid)">
                                  <span class="text-danger">Please enter a valid phone number</span>
                          </div> -->
              </div>
              <div class="row mt-2">
                <div class="col-lg-4 col-md-4 col-sm-4">
                  <label class="col-form-label py-1" for="jobTitle-input">
                    Fax
                  </label>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8">
                  <input class="form-control p-1" placeholder="Enter Fax" type="text" id="phone-input"
                    [(ngModel)]="addClient.CompanyFax">
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class=" border border-dark border-1 p-3 rounded-3 bg-white mt-3">
                <img [src]="CompanyLogoImage" width="200" height="220" class="d-flex mx-auto">
              </div>
              <div class="d-flex justify-content-between px-3">
                <label *ngIf="CompanyLogoImage.includes(gettokenId)"
                  class="btn-link font-size-14 d-flex justify-content-center cursor-pointer mt-2" type="file"
                  for="myFile">
                  Upload Photo
                </label>
                <label *ngIf="!CompanyLogoImage.includes(gettokenId)" style="margin-left: 4em;"
                  class="btn-link font-size-14 d-flex justify-content-center cursor-pointer mt-2" type="file"
                  for="myFile">
                  Upload Photo
                </label>
                <label *ngIf="CompanyLogoImage.includes(gettokenId)"
                  class="btn-link font-size-14 d-flex justify-content-center cursor-pointer mt-2"
                  (click)="RemoveUserImage()">Remove Photo</label>
              </div>
              <!-- <label class="btn btn-outline-primary rounded-5 font-size-14 cursor-pointer mt-3" type="file"
                              for="myFile">
                              Edit
                          </label> -->
              <input type="file" id="myFile" name="myFile" (change)="UploadFile($event.target.files)">
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #AddressContent>
        <div class="px-3">
          <div class="row mt-2">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <label class="col-form-label py-1" for="line1-input">
                Line 1
              </label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <input class="form-control p-1" placeholder="Enter Line 1" type="text" id="line1-input"
                [(ngModel)]="addClient.CompanyAddressLine1">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <label class="col-form-label py-1" for="line2-input">
                Line 2
              </label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <input class="form-control p-1" placeholder="Enter Line 2" type="text" id="line2-input"
                [(ngModel)]="addClient.CompanyAddressLine2">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <label class="col-form-label py-1" for="jobTitle-input">
                City
              </label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <input class="form-control p-1" placeholder="Enter City" type="text" id="city-input"
                [(ngModel)]="addClient.CompanyAddressCity">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <label class="col-form-label py-1" for="line1-input">
                State
              </label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <input class="form-control p-1" placeholder="Enter State" type="text" id="phone-input"
                [(ngModel)]="addClient.CompanyAddressState">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
          </div>

          <div class="row mt-2">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <label class="col-form-label py-1" for="jobTitle-input">
                Country
              </label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <ng-select data-control="select2" data-hide-search="false" [(ngModel)]="addClient.CountryCode"
                [clearable]="false" placeholder="Select Country">
                <ng-option [value]="''" disabled>Select Country
                </ng-option>
                <ng-option [value]="'AFZ'">Afghanistan</ng-option>
                <ng-option [value]="'ALB'">Albania</ng-option>
                <ng-option [value]="'DZA'">Algeria</ng-option>
                <ng-option [value]="'ASM'">American Samoa</ng-option>
              </ng-select>
              <!-- <ng-select [multiple]="false" [(ngModel)]="addClient.CountryCode" class="cursor-pointer"
                              [clearable]="false" [placeholder]="'Select Country'">
                             
                              <ng-option [value]="'AFG'">Afghanistan</ng-option>
                              <ng-option [value]="'ALB'">Albania</ng-option>
                              <ng-option [value]="'DZA'">Algeria</ng-option>
                              <ng-option [value]="'ASM'">American Samoa</ng-option>
                          </ng-select> -->
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
          </div>


          <div class="row mt-2">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <label class="col-form-label py-1" for="line2-input">
                Zip/Post Code
              </label>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <input class="form-control p-1" placeholder="Enter Zip/Post Code" type="text" id="line2-input"
                [(ngModel)]="addClient.CompanyAddressZip">
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4"></div>
          </div>
        </div>
      </ng-template>
      <ng-template #ProfileContent>
        <div class="mt-2 px-3">
          <label class="col-form-label py-1" for="line2-input">
            Public Profile
          </label>
          <ckeditor style="height: 50px;" (ready)="onReady($event)" [config]="ckeditorConfig" [editor]="Editor" data="">
          </ckeditor>
        </div>
      </ng-template>
      <ng-template #NotesContent>
        <div class="mt-2 px-3">
          <label class="col-form-label py-1" for="line2-input">
            Private Notes
          </label>
          <ckeditor style="height: 50px;" (ready)="onReady($event)" [config]="ckeditorConfig" [editor]="Editor" data="">
          </ckeditor>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary waves-effect px-2 py-1 font-size-14" data-bs-dismiss="modal"
      (click)="clearclientmodel()">Close</button>
    <button type="button" class="btn btn-primary waves-effect waves-light px-2 py-1 font-size-14"
      [disabled]="isbtndisabled" (click)="SaveOrUpdateTenantCompany(addClient)">Add Client
    </button>

  </div>
</ng-template>