import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AggregatedetailsService {

  constructor(private http: HttpClient) {
  }

  GetGroupChildren(groupId: any, parentobjectName: any, ChildObjectName: any, UserProfileId: any, tenantId: any, ParentObjectItemId: any, pageNum: any, search: any) {
    return this.http.get(environment.GetGroupChildren + '/' + groupId + '/' + parentobjectName + '/' + ChildObjectName + '/' + UserProfileId + '/' + tenantId + '/' + ParentObjectItemId + '/' + pageNum + '/' + search)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }
}
