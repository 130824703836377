import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SetUserProfilesDirective } from '../CustomDirectives/setUserProfiles.directive';
import { FilesizePipe } from 'src/app/filesize.pipe';
import { RemoveNextLinePipe } from '../CustomPipes/RemovenextLine.pipe';
import { ProjectpermissionsforaddedcompoentsDirective } from '../CustomDirectives/projectpermissionsforaddedcompoents.directive';

@NgModule({
  declarations: [
    SetUserProfilesDirective,
    FilesizePipe,
    RemoveNextLinePipe,
    ProjectpermissionsforaddedcompoentsDirective
  ],
  exports: [
    SetUserProfilesDirective,
    RemoveNextLinePipe,
    FilesizePipe,
    ProjectpermissionsforaddedcompoentsDirective],
  imports: [
    CommonModule
  ]
})
export class ExportModule { }
