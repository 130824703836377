import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TaskdetailsService {

  constructor(private http: HttpClient) {
  }

  getCoreTaskByTaskId(tenantId: any, UserProfileId: any, taskId: any) {
    return this.http.get(environment.GetCoreTaskByTaskId + '/' + tenantId + '/' + UserProfileId + '/' + taskId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  getCoreUserProfileByUserProfileId(tenantId: any, UserProfileId: any) {
    return this.http.get(environment.GetCoreUserProfileByUserProfileId + '/' + tenantId + '/' + UserProfileId )
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  getCoreFileByFileId(tenantId: any, UserProfileId: any,fileId:any) {
    return this.http.get(environment.GetCoreFileByFileId + '/' + tenantId + '/' + UserProfileId + '/' + fileId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetCategoriesById(childObjectItemId: any, userId: any, tenantId: any) {
    return this.http.get(environment.GetCategoriesById + '/' + childObjectItemId + '/' + userId + '/' + tenantId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetAllCategoriesByProjectId(parentObjectName:any, parentObjectItemId: any, userId: any, tenantId: any) {
    return this.http.get(environment.GetAllCategoriesByProjectId + '/' + parentObjectName + '/' + parentObjectItemId + '/' + userId + '/' + tenantId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetCompletedTaskListsProjectId(tenantProfileId: any, userProfileId: any, projectId: any) {
    return this.http.get(environment.GetCompletedTaskListsProjectId + '/' + tenantProfileId + '/' + userProfileId + '/' + projectId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetCompletedTasksByAuxGroupItemId(tenantProfileId: any, userProfileId: any, projectId: any,auxGroupItemId:any,pagenum:any) {
    return this.http.get(environment.GetCompletedTasksByAuxGroupItemId + '/' + tenantProfileId + '/' + userProfileId + '/' + projectId+ '/' + auxGroupItemId + '/' + pagenum)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveDeletecoreTaskByTaskId(deletetask: any) {
    return this.http.post(environment.SaveDeletecoreTaskByTaskId, deletetask, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetTasksByUserProfileId(tenantId: any, UserProfileId: any, pagenum: any, UserId: any,auxGroupItemId :any, search: any) {
    return this.http.get(environment.GetTasksByUserProfileId + '/' + tenantId + '/' + UserProfileId + '/' + pagenum+ '/' + UserId + '/' + auxGroupItemId + '/'+ search )
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetTasksCompletedByUser(tenantId: any, UserProfileId: any, UserId: any ,pagenum : any) {
    return this.http.get(environment.GetTasksCompletedByUser + '/' + tenantId + '/' + UserProfileId + '/' + UserId + '/' + pagenum)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetPrivacyUsersByTaskId(userProfileId: any, tenantProfileId: any, projectId: any,taskListId: any, taskId: any, isPrivacyUsers: any) {
    return this.http.get(environment.GetPrivacyUsersByTaskId + '/' + userProfileId + '/' + tenantProfileId + '/' + projectId+ '/' + taskListId + '/' + taskId + '/' + isPrivacyUsers)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetAllCategories( objectname:any) {
    return this.http.get(environment.GetAllCategories  + '/' + objectname)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetauxCategorybyId( UserProfileId :any , tenantProfileId: any,auxCategorybyId: any) {
    return this.http.get(environment.GetauxCategorybyId + '/' + UserProfileId + '/' + tenantProfileId + '/' + auxCategorybyId )
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }
  GetauxCategoryTask( UserProfileId :any , tenantProfileId: any,ProjectId : any) {
    return this.http.get(environment.GetauxCategoryTask + '/' + UserProfileId + '/' + tenantProfileId + '/' + ProjectId )
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  UpdateTasksDisplayOrder(TaskOrderModel: any) {
    return this.http.post(environment.UpdateTasksDisplayOrder, TaskOrderModel, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  UpdateTaskListDisplayOrder(TaskListModel: any) {
    return this.http.post(environment.UpdateTaskListDisplayOrder, TaskListModel, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }




}
