import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { EventService } from '../../core/services/event.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { UserapplicationService } from 'src/app/core/services/userapplication.service';
import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { TranslateService } from '@ngx-translate/core';
import { UserProfileService } from 'src/app/core/services/user.service';
import { ToastralertService } from 'src/app/core/services/toasteralert.service';
import { SharedService } from 'src/app/core/services/SharedService.service';
import { Subscription } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as CustomEditor from 'src/app/ckEditor/build/ckEditor';
import { ProjectlistService } from 'src/app/core/services/projectlist.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProjectdeatilsService } from 'src/app/core/services/projectdeatils.service';
import { MyLayoutUploadAdapter } from 'src/app/components/MyLayoutUploadAdapter';
import { environment } from 'src/environments/environment';
import { SendEmailsthroughFlexdripService } from 'src/app/core/services/sendEmails-through-flexdrip.service';
import { CategoryService } from 'src/app/core/services/category.service';
import { DatePipe } from '@angular/common';
import { ProjecttaskService } from 'src/app/core/services/projecttask.service';
import { globalsearch } from 'src/app/core/services/globalsearchmodel';
import { ClientService } from 'src/app/core/services/client.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { FileUploadService } from 'src/app/core/services/fileupload.service';
import { TaskdetailsService } from 'src/app/core/services/taskdetails.service';
import { AggregatedetailsService } from 'src/app/core/services/aggregatedetails.service';




type Tabs = 'Projects' | 'Dashboard' | 'Users' | 'Clients' | 'Tags' | 'Category' | 'Company Settings' | 'globalSearch' | 'Myprofile';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

/**
 * Sidebar component
 */
export class SidebarComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('componentRef') scrollRef;
  @Input() isCondensed = false;
  menu: any;
  data: any;
  show: any = false;
  filterTerm: string;
  TaskParentTaskId: string;
  dateuploaded: string;
  allUsers: any;
  onwerselectedUsersList: any;
  ParentTaskId: any = '00000000-0000-0000-0000-000000000000';
  aggregateschildren: any = [];
  taskselectedmodel: any;
  taskselectedtags: any;
  projectid: any = '';
  taskId: string;
  modalRef: any;
  showTextField: boolean = false;
  // dynamiclink: any = 'https://d9software.helpdocsonline.com/home';
  privacyusers: any[];
  TasksListForTasksModelList: any;
  userProfileId?: any;
  newcategoryName: any;
  showcategoryTextField: boolean = false;
  UserProfileId: string;


  static handleUploadComplete(arg0: boolean) {
    throw new Error("Method not implemented.");
  }
  menuItems = [];

  @ViewChild('sideMenu') sideMenu: ElementRef;
  @ViewChild('cancelTaskconfirmation', { static: false }) cancelTaskconfirmation: any;
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  public Editor = CustomEditor;
  token_response: any;
  token: any;
  tabSubscription: Subscription;
  activatedtabfile: Tabs = 'Projects';
  username: any;
  UserImageUrl: any;

  // addtask
  projectlists: any;
  destinationproject: any;
  destinationTasklist: any;
  LoadMoreProjects: boolean = false;
  pagenum: number = 1;
  prodauxCategoryId: any = '00000000-0000-0000-0000-000000000000';
  isActive: boolean = true;
  txtSearch: string = "-1";
  filterobject: any = null;
  orderby: any = null;
  tasklisthierarchy: any;
  projecttasklist: any;
  aggregateslist: boolean = false;
  tasklistid: any;
  ProjectId: any;
  newtaskdetails: any = { TaskId: '', TaskName: '', TaskDescription: '', TaskStartDate: null, TaskDueDate: null, TaskPriority: 0, TaskProgress: 0, timehours: 0, TaskEstimateMinutes: 0, auxGroupItemIdBoards: '', tags: '' };
  aggregates: any;
  boardid: any;
  userprofiles: any;
  searchvalue: any = '-1';
  newObject: any = null;
  userselectedlist = [];
  isUploadComplete: boolean = false;
  tasknameEdit: string;
  createdtasktriggered: boolean = false;
  buttononclicksave: boolean = false;
  auxgroupitemid: string;
  auxgroupItemName: string;
  assignedtags: any = [];
  assignedusertype: any = [];
  TaskId: string;
  taskcretaedupdated: string;
  userselectedlistforfilter = [];
  userselectedtags = [];
  selsctedboardid: any;
  assignedusers: any = [];
  taskstatus: any = 'new';
  updatecompletedtask: boolean = false;
  IsSaveOrUpdatetaskClicked: boolean = false;
  usersCount: string = '';
  userTags: string = '';
  userBoards: string = '';
  assignedtoUsers: string = '';
  taskContent: string = '';
  assignedOrEdited: string = '';
  taskcreatedOredited: string;
  savefiltertriggered: boolean = false;
  startdate: any = null;
  enddate: any = null;
  dateIntervel: any = null;
  datecreated: any = '';
  createdstartdate: any = null;
  createdenddate: any = null;
  createddateIntervel: any = null;
  filterpriority: any = 300;
  assignedusersfilter: any = [];
  createdusersfilter: any = [];
  tagsselected: any[];
  boardsselected: any[];
  filterboards: any = [];
  filtertags: any = [];
  Assigneduserselectedlistforfilter: any = [];
  filteractive: boolean = false;
  istaskcollapsed: boolean;
  activechildren: any = 0;
  projecthierarchy: any = { ObjectId: '', ObjectName: '', childrenlist: { coreChildrenOutputModel: [], coreObjectModel: [] } };
  groupchildrensearch: any = '-1';


  // addproject
  IsSaveOrUpdateClicked: boolean = false;
  createprojecttriggered: boolean = false;
  projectDetails: any = { ProjectName: '', TenantCompanyId: '', ProjectDescription: '', ProjectStartDate: '', ProjectEndDate: '', ProjectIsBillable: true, ProjectCompleted: false, ProjectOwnerId: '', companyName: '' };
  categorydetails: any;
  categoryName: any;
  companyId: any;
  tenantCompanieslist: any;
  usersData: any;
  tenantcompanydata: any;
  selectedcompanydata: any;
  projectcereatedorupdate: string;
  selectedUsersList: any = [];
  userSelectedUsersModelsList: any = [];
  companyUserModelsList: any = [];
  owneruserSelectedUsersModelsList: any = [];
  isCompletedTasks: boolean = false;
  tasklistPagenum: number = 1;
  parenttasklist: any = { Objectid: '', ObjectName: '', TaskCounts: '', auxGroupItemIsPrivate: '' };
  isProjectSelected: boolean = false;
  mainProjectId: any = '';
  maintasklistId: any = '';
  isbtndisabled: boolean = false;
  createdclienttriggered: boolean = false;
  isEmailValid: any = true;
  ismobilevalid: any = true;
  TenantCompanyId: string;
  ClientProfiledisplaycontent: string;
  CompanyLogoImageName: any;
  CompressedCompanyLogo: any;
  s3BaseUrl: any;
  addClient: any = {
    UserProfileId: '', TenantCompanyId: '', CompanyName: '', CompanyLogoImage: '', CompanyAddressLine1: '', CompanyAddressLine2: '', CompanyAddressCity: '', CompanyAddressState: '',
    CompanyAddressZip: '', CountryCode: '', CompanyEmail1: '', CompanyWebsite: '', CompanyPhone: '', CompanyFax: '', CompanyProfile: '', CompanyPrivateNotes: '',
  };
  editclient: any;
  editclientcompanyname: string;
  CompanyLogoImage: string = '../../../assets/images/ComapanyImage.jpg';
  gettokenId: any;

  constructor(private eventService: EventService,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient,
    private modalService: NgbModal,

    public toastralert: ToastralertService, private changeDetectorRef: ChangeDetectorRef,
    private UserapplicationService: UserapplicationService,
    private userService: UserProfileService, private sharedService: SharedService,
    public route: ActivatedRoute,
    // addtask
    private projectlistService: ProjectlistService,
    private spinner: NgxSpinnerService,
    private projectdeatilsService: ProjectdeatilsService,
    private sendEmailsthroughFlexdripService: SendEmailsthroughFlexdripService,
    private projecttaskService: ProjecttaskService,
    private clientService: ClientService,
    private imageCompress: NgxImageCompressService,
    private fileuploadservice: FileUploadService,
    private taskdetailsService: TaskdetailsService,
    private aggregatedetailsService: AggregatedetailsService,


    // addproject
    private categoryService: CategoryService,
    public datepipe: DatePipe,
  ) {
    this.token_response = JSON.parse(localStorage.getItem("d9_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  ngOnInit() {
    this.tabSubscription = this.sharedService.getSidebarActiveTab().subscribe((data) => {
      this.activatedtabfile = data;
    });
    this.initialize();
    this.GetMenusByUserProfileIdAndUserRoleId();
    this.username = this.token_response.FullName;
    localStorage.setItem("userimage", this.token_response.UserImageUrl);
    this.UserImageUrl = this.token_response.UserImageUrl;
    const segments = this.router.url.split('/')[2];
    let currentpage = this.router.url.split('/')[2];
    // this.dynamiclink = 'https://d9software.helpdocsonline.com/' + currentpage;
    if (currentpage == "list") {
      this.maintasklistId = atob(this.router.url.split('/')[4]);
    }
    else if (currentpage == "projecttasks") {
      this.mainProjectId = atob(this.router.url.split('/')[3]);
    }
    else if (currentpage == "userTasks") {
      this.userProfileId = atob(this.router.url.split('/')[3]);
    } else {

    }

    this.tabSubscription = this.sharedService.getallProject().subscribe((data) => {
      this.projectlists = data;
    });

    this.tabSubscription = this.sharedService.getclientList().subscribe((data) => {
      this.tenantCompanieslist = data;
    });

    // Now 'segments' is an array that contains each part of the URL separated by slashes
    // addtask
    this.ProjectId = this.sharedService.getGlobalProjectId();
    this.get_projectlist(this.prodauxCategoryId, this.isActive, this.pagenum, this.txtSearch, false);
    this.GetGroups('coreProjects');
    if (this.ProjectId != null && this.ProjectId != '') {
      this.get_hierarchy(1, null);
      this.GetCoreObjectChildrenforTasks(1, null, this.isCompletedTasks, false, null);
    }
    this.sharedService.getIsDisabled().subscribe((data) => {
      this.isUploadComplete = data;
    });


    // addproject
    this.getAllCategoriesOrTagsByTenantProfileId();
    this.GetAllTenantCompanysByTenantProfileId();
  }


  getProjectid() {
    this.projectid = this.sharedService.getGlobalProjectId();
    if (this.projectid != '' && this.projectid != undefined) {
     let projectexsists =  this.projectlists.findIndex(obj => { return obj.TaskId == this.projectid });
     if(projectexsists == -1){
      this.destinationproject = '';
     }
     else{
      this.destinationproject = this.projectid;
      this.ProjectId = this.projectid;
      this.GetGroupsList(this.tasklistid, this.projectid);
      this.get_hierarchy(1, null);
      this.isProjectSelected = true;
     }
    }
  }
  handleUploadComplete(status: boolean) {
    this.isUploadComplete = status;
    this.sharedService.setIsDisabled(false);
  }

  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  ngOnDestroy() {
    // Unsubscribe from the activeTab subject to avoid memory leaks
    this.tabSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.menu = new MetisMenu(this.sideMenu.nativeElement);
  }

  ngOnChanges() {
    if (!this.isCondensed && this.sideMenu || this.isCondensed) {
      setTimeout(() => {
        this.menu = new MetisMenu(this.sideMenu.nativeElement);
      });
    } else if (this.menu) {
      this.menu.dispose();
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    this.menuItems = MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  GetMenusByUserProfileIdAndUserRoleId() {
    this.menuItems = [];
    this.userService.GetMenusByUserProfileIdAndUserRoleId(this.token_response.userId, this.token_response.userRoleId).subscribe((results) => {
      if (results != null && results != 'undefined') {

        const finaldata = results.filter((item: any) => {
          return item.SysMenu !== "Dashboard"; // Filter out the appropriate one
        });
        this.menuItems = finaldata;
      }
    },
      (err) => {
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom("Unable to get menus.Please try angain after some time.");
        }
      }
    );

  }


  redirecttogloabalsearch() {
    this.router.navigate(['/globalSearch'])
  }

  toggleRightNotifications() {
    this.settingsButtonClicked.emit();
  }

  redirectToUserProfile() {
    this.router.navigate(['/user/Myprofile/' + btoa(this.token_response.userId)])
  }

  GetUserDetailsByTenantUserProfileId1(userprofileId: any) {
    this.UserapplicationService.GetUserDetailsByTenantUserProfileId(this.token_response.tenantId, this.token_response.userId, null ,userprofileId).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        //this.UserImageUrl = results.UserImageUrl.includes(this.token_response.tenantId) ? results.UserImageUrl : 'assets/images/avatar.png';
        //this.changeDetectorRef.detectChanges();

        let txtSearch = this.sharedService.getuserprojectlistsearchstring();
        txtSearch == '' ? (txtSearch = '-1') : '';
        let UserIsAdmin = (results.UserIsAdmin == 'Administrator' || this.token_response.UserRoleName == "Owner") ? true : false;
        this.get_globalprojectlist(userprofileId, this.prodauxCategoryId, this.isActive, this.pagenum, txtSearch, true, UserIsAdmin);
      }
    },
      (err) => {
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to edit User details.Please try after some time.');
        }
      }
    );

  }

  logout() {
    localStorage.removeItem('globalObjectId');
    localStorage.removeItem('d9_token_responseuat');
    localStorage.removeItem('IsUser_loggedin');
    localStorage.removeItem('ProjectName');
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("GlobalSearchData");
    localStorage.removeItem("GlobalSearchAllData");
    localStorage.removeItem('projectname');
    this.userService.setTargetScreen('project/projectlist');
    const gobalserachdata: globalsearch = {
      Search: '',
      ProjectId: null,
      Projects: false,
      Tasks: false,
      TaskLists: false,
      Files: false,
      TaskComments: false,
      CompletedItems: false,
      ArchivedProjects: false,
      OrderByName: false,
      OrderByDateLastUpdated: false,
      ProjectsPageNum: 1,
      TasklistPageNum: 1,
      TasksPageNum: 1,
      FilesPageNum: 1,
      CommentsPageNum: 1,
      LoadMore: '',
      LoadAll: false,
      viewoptions: false,
      fewerOptionContent: false
    }
    this.sharedService.setglobalsearchdata(gobalserachdata);
    this.router.navigate(['/login']);
  }


  centerLargeModal(centerLargeDataModal: any) {
    this.modalService.open(centerLargeDataModal, { size: 'lg', centered: true });
  }
  centerlargeModal(centerLargeDataModal: any) {
    this.modalService.open(centerLargeDataModal, { size: 'lg', centered: true, scrollable: true });
  }

  smallModal(smallDataModal: any) {
    this.modalService.open(smallDataModal, { size: 'sm', centered: true });
  }

  onReady(editor: CustomEditor): void {
    Array.from(editor.ui.componentFactory.names());
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      this.handleUploadComplete(true);
      return new MyLayoutUploadAdapter(loader, this.token_response.tenantId, this);
    };
    if (editor.model.schema.isRegistered('image')) {
      editor.model.schema.extend('image', { allowAttributes: 'blockIndent' });
    }
  }

  public ckeditorConfig = {
    toolbar: {
      items: [
        'heading', '|',
        'fontfamily', 'fontsize',
        'alignment',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', '-', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'imageUpload', 'blockQuote',
        'undo', 'redo',
      ],
      shouldNotGroupWhenFull: true,
    },
    image: {
      // Configure the available styles.
      styles: [
        'alignLeft', 'alignCenter', 'alignRight'
      ],

      // Configure the available image resize options.
      resizeOptions: [
        {
          name: 'resizeImage:original',
          label: 'Original',
          value: null
        },
        {
          name: 'resizeImage:50',
          label: '25%',
          value: '25'
        },
        {
          name: 'resizeImage:50',
          label: '50%',
          value: '50'
        },
        {
          name: 'resizeImage:75',
          label: '75%',
          value: '75'
        }
      ],

      // You need to configure the image toolbar, too, so it shows the new style
      // buttons as well as the resize buttons.
      toolbar: [
        'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight',
        '|',
        'ImageResize',
        '|',
        'imageTextAlternative'
      ]
    },
    language: 'en'
  };


  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.UserFirstName.toLocaleLowerCase().indexOf(term) > -1 ||
      item.UserLastName.toLocaleLowerCase().indexOf(term) > -1;
  }

  toggleField() {
    this.showTextField = !this.showTextField;
    this.projectDetails.companyName = '';
    this.companyId = null;
    this.GetProjectUsersListByTenantProfileId(this.companyId);
  }

  togglecategoryField() {
    this.showcategoryTextField = !this.showcategoryTextField;
    this.newcategoryName = '';
    this.categoryName = null;
  }

  get_projectlist(auxCategoryId: any, isActive: any, pagenum: any, search: any, type: any) {
    let UserIsAdmin = (this.token_response.UserRoleName == 'Administrator' || this.token_response.UserRoleName == "Owner") ? true : false;
    this.projectlistService.GetAllProjectsList(this.token_response.userId, this.token_response.tenantId, auxCategoryId, isActive, UserIsAdmin, pagenum, search, this.filterobject, this.orderby, type).subscribe((results) => {
      // this.loading = false;
      if (results != null && results != "" && results != 'undefined') {
        this.projectlists = results;
      }
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get profile details.Please try after some time.');
        }
      }
    );
  }


  get_globalprojectlist(userId: any, auxCategoryId: any, isActive: any, pagenum: any, search: any, type: any, UserIsAdmin: any) {
    this.projectlistService.GetAllProjectsList(userId, this.token_response.tenantId, auxCategoryId, isActive, UserIsAdmin, pagenum, search, this.filterobject, this.orderby, type).subscribe((results) => {
      // this.loading = false;
      if (results != null && results != "" && results != 'undefined') {
        let currentpage = this.router.url.split('/')[2];
        if (currentpage == "projectlist") {
          this.sharedService.setProject(results);
        }
        if (currentpage == "userProjects") {
          this.sharedService.setuserProject(results);
        }
      }
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get profile details.Please try after some time.');
        }
      }
    );
  }

  getprojectsbysearch(term: any) {
    if (term != "") {
      this.txtSearch = term;
    }
    else {
      this.txtSearch = "-1";
    }
    this.pagenum = 1;
    this.get_projectlist(this.prodauxCategoryId, this.isActive, this.pagenum, this.txtSearch, true);
  }

  onproject(projectId: any) {
    this.ProjectId = projectId;
    this.GetGroupsList(this.tasklistid, projectId);
    this.get_hierarchy(1, null);
  }

  GetGroupsList(objectid: any, projectId: any) {
    this.projectdeatilsService.GetGroupsList(objectid, this.token_response.tenantId, projectId, this.token_response.userId).subscribe((results) => {
      this.projecttasklist = results.privacyTaskList;
      let currentpage = this.router.url.split('/')[2];
      let mainPID = this.router.url.split('/')[3];
      if (currentpage == "list") {
        if (atob(mainPID) == projectId) {
          this.sharedService.setProjectTaskslist(results.privacyTaskList);
        }
      }
      if (this.aggregateslist) {
        this.aggregateslist = false;
        this.tasklisthierarchy = results.privacyTaskList;;
      }
    },
      (err) => {
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get task lists.Please try after some time.');
        }
      }
    );
  }

  restricttyping(event: any) {
    return false;
  }

  onProjectSelectionChange() {
    this.isProjectSelected = !!this.destinationproject; // Assuming destinationproject is defined
  }

  GetGroups(objName: any) {
    this.projectdeatilsService.GetGroups(objName, this.token_response.tenantId).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.aggregates = results;
        this.aggregates.forEach((component: any, item: any) => {
          if (component.ObjectName == 'Task Lists') {
            this.tasklistid = component.ObjectId;
            this.GetGroupsList(this.tasklistid, this.destinationproject);
          }
          if (component.ObjectName == 'Boards') {
            this.boardid = component.ObjectId;
            // this.get_boardlist(this.boardid);
          }
        }
        )
      }
    },
      (err) => {
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get task list.Please try after some time.');
        }
      }
    );
  }

  get_hierarchy(pagenum: any, tasklistid: any) {
    let objectDetails = {
      parentObjectName: 'coreProjects',
      childObjectName: 'coreUserProfiles',
      parentObjectItemId: this.ProjectId,
      userProfileId: this.token_response.userId,
      tenantId: this.token_response.tenantId,
      newobject: this.newObject,
      orderBy: this.orderby,
      search: this.searchvalue,
      pagenum: pagenum,
      auxGroupItemId: tasklistid,
      isCompletedTasks: false,
      deletedProjectUsers: 0,
      projectId: this.ProjectId,
      userIsAdmin: (this.token_response.UserRoleName == 'Administrator' || this.token_response.UserRoleName == "Owner") ? true : false
    }
    this.projectdeatilsService.GetCoreObjectChildren(objectDetails).subscribe((results) => {
      this.userprofiles = results.UserModelList;
    },
      (err) => {
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get userprofiles.Please try after some time.');
        }
      }
    );
  }

  cleartaskdetailsmodel() {
    this.newtaskdetails.TaskName = "";
    this.newtaskdetails.TaskDescription = "";
    this.createdtasktriggered = false;
    this.newtaskdetails.TaskStartDate = null;
    this.newtaskdetails.TaskDueDate = null;
    this.newtaskdetails.TaskEstimateMinutes = 0;
    this.newtaskdetails.timehours = 0;
    this.destinationproject = "";
    this.destinationTasklist = "";
    this.newtaskdetails.TaskDescription = "";
    // this.taskIddetails.TaskEstimateMinutes = 0;
    this.newtaskdetails.TaskPriority = 0;
    this.newtaskdetails.TaskProgress = 0;
    this.newtaskdetails.auxGroupItemIdBoards = '';
    this.auxgroupitemid = "";
    this.auxgroupItemName = "";
    this.userselectedlist = [];
    this.privacyusers = [];
    this.userSelectedUsersModelsList = [];
    this.modalService.dismissAll();
    this.assignedtags = [];
    this.TaskId = '';
    this.taskcretaedupdated = '';
    this.userselectedtags = [];
    this.userselectedlist = [];
    this.selsctedboardid = [];
    this.assignedusers = [];
    this.taskstatus = 'new';
    this.updatecompletedtask = false;
    this.isProjectSelected = false;
    this.createdtasktriggered = false;
  }

  onclickcanceltask(newtaskdetails: any) {
    if (newtaskdetails.TaskName != '' && newtaskdetails.TaskId == '') {
      this.smallModal(this.cancelTaskconfirmation);
    }
    else if (newtaskdetails.TaskId != '' && (this.tasknameEdit != newtaskdetails.TaskName)) {
      this.smallModal(this.cancelTaskconfirmation);
    } else {
      this.cleartaskdetailsmodel();
    }
  }

  GetCoreObjectChildrenforTasks(pagenum: any, tasklistid: any, isCompletedTasks: any, isNewOrEdit: any, taskId: any) {
    if (tasklistid == null) {
      this.projecthierarchy = [];
    }
    let objectDetails = {
      parentObjectName: 'coreProjects',
      childObjectName: 'coreTasks',
      parentObjectItemId: this.ProjectId,
      userProfileId: this.token_response.userId,
      tenantId: this.token_response.tenantId,
      newobject: this.newObject,
      orderBy: this.orderby,
      search: this.searchvalue,
      pagenum: pagenum,
      auxGroupItemId: tasklistid,
      isCompletedTasks: isCompletedTasks,
      deletedProjectUsers: 0,
      projectId: this.ProjectId,
      userIsAdmin: (this.token_response.UserRoleName == 'Administrator' || this.token_response.UserRoleName == "Owner") ? true : false
    }
    this.projectdeatilsService.GetCoreObjectChildrenforTasks(objectDetails).subscribe((results) => {
      if (!isNewOrEdit) {
        this.tasklisthierarchy = results.TaksListChildrenModelList;
        if (tasklistid == null) {
          this.projecthierarchy = results.TaksListChildrenModelList;
          let currentpage = this.router.url.split('/')[2];
          let mainPID = this.router.url.split('/')[3];

          if (currentpage == "projecttasks") {
            if (atob(mainPID) == this.ProjectId) {
              this.sharedService.setProjectTasks(results.TaksListChildrenModelList);
              this.sharedService.setProjectTaskslist(results.TaksListChildrenModelList);
            }
          }
          // else if (currentpage == "projecttasks"){
          //   if(atob(this.mainProjectId) == this.ProjectId){
          //     this.sharedService.setProjectTasks(results.TaksListChildrenModelList);
          //   }        
          //   }
          // else{

          // }
        }
        else {
          if (isCompletedTasks) {
            let taskListData = this.projecthierarchy.filter((item: any) => {
              return item.ObjectId == tasklistid;
            });
            let currenttaskListData = results.TaksListChildrenModelList.filter((item: any) => {
              return item.ObjectId == tasklistid;
            });
            taskListData[0].CompletedPageNum = pagenum;
            if (currenttaskListData[0].TaskChildrenCompletedOutputModelList != null && currenttaskListData[0].TaskChildrenCompletedOutputModelList != undefined) {
              taskListData[0].TaskChildrenCompletedOutputModelList.push(...currenttaskListData[0].TaskChildrenCompletedOutputModelList);
              // taskListData[0].TaskChildrenCompletedOutputModelList = [...taskListData[0].TaskChildrenCompletedOutputModelList,...currenttaskListData[0].TaskChildrenCompletedOutputModelList];
            }
            let isThereanyrecords: boolean = taskListData[0].TaskChildrenCompletedOutputModelList != null && (taskListData[0].TaskChildrenCompletedOutputModelList.length / 50 == pagenum);
            taskListData[0].IsCompletedTasksexists = isThereanyrecords ? true : false;
          }
          else {
            let taskListData = this.projecthierarchy.filter((item: any) => {
              return item.ObjectId == tasklistid;
            });
            let currenttaskListData = results.TaksListChildrenModelList.filter((item: any) => {
              return item.ObjectId == tasklistid;
            });
            taskListData[0].PageNum = pagenum;
            if (currenttaskListData[0].coreChildrenOutputList != null && currenttaskListData[0].coreChildrenOutputList != undefined) {
              taskListData[0].coreChildrenOutputList.push(...currenttaskListData[0].coreChildrenOutputList);
            }
            let isThereanyrecords: boolean = taskListData[0].TaskChildrenCompletedOutputModelList != null && (taskListData[0].TaskChildrenCompletedOutputModelList.length / 50 == pagenum);
            taskListData[0].IsTasksexists = isThereanyrecords ? true : false;
          }
          // taskListData[0].coreChildrenOutputList = [...taskListData[0].coreChildrenOutputList,currenttaskListData[0].coreChildrenOutputList]
        }
      }
      else {
        if (taskId == this.ParentTaskId) {
          let tasklistData = this.projecthierarchy.filter((obj: any) => {
            return obj.ObjectId == tasklistid;
          })
          let currenttaskListData = results.TaksListChildrenModelList.filter((item: any) => {
            return item.ObjectId == tasklistid;
          });
          if (currenttaskListData[0] != null && currenttaskListData[0] != undefined && currenttaskListData[0].coreChildrenOutputList != null && currenttaskListData[0].coreChildrenOutputList != undefined) {
            if (tasklistData[0]) {
              let taskdata: any;
              let currenttaskdata: any;
              if (tasklistData[0]) {
                taskdata = tasklistData[0].coreChildrenOutputList.filter((obj: any) => {
                  return obj.TaskId == this.ParentTaskId;
                })
              }
              if (currenttaskListData[0]) {
                currenttaskdata = currenttaskListData[0].coreChildrenOutputList.filter((obj: any) => {
                  return obj.TaskId == this.ParentTaskId;
                })
              }
              if (taskdata[0] && currenttaskdata[0]) {
                currenttaskdata[0].IsShowSubTasksList = taskdata[0].IsShowSubTasksList;
              }
              tasklistData[0].CompletedCount = currenttaskListData[0].CompletedCount;
              tasklistData[0].coreChildrenOutputList = [...currenttaskListData[0].coreChildrenOutputList];
              tasklistData[0].TaskChildrenCompletedOutputModelList = [...currenttaskListData[0].TaskChildrenCompletedOutputModelList];
            }
          }
        }
        else {
          let tasklistData = this.projecthierarchy.filter((obj: any) => {
            return obj.ObjectId == tasklistid;
          })
          let currenttaskListData = results.TaksListChildrenModelList.filter((item: any) => {
            return item.ObjectId == tasklistid;
          });
          let taskdata: any;
          let currenttaskdata: any;
          if (tasklistData[0]) {
            taskdata = tasklistData[0].coreChildrenOutputList.filter((obj: any) => {
              return obj.TaskId == this.ParentTaskId;
            })
          }
          if (currenttaskListData[0]) {
            currenttaskdata = currenttaskListData[0].coreChildrenOutputList.filter((obj: any) => {
              return obj.TaskId == this.ParentTaskId;
            })
          }
          if (currenttaskdata[0] != null && currenttaskdata[0] != undefined && currenttaskdata[0].SubTaskModelList != null && currenttaskdata[0].SubTaskModelList != undefined) {
            if (taskdata[0]) {
              taskdata[0].SubTaskModelList = [...currenttaskdata[0].SubTaskModelList];
            }
          }
        }
      }
      // this.loading = false;
      this.spinner.hide();
      // this.GetCompletedTaskListsProjectId();
      // if (tasklistid == null) {
      // this.projecthierarchy.forEach((value: any, item: any) => {
      //   value.coreChildrenOutputList.forEach((element: any, item1: any) => {
      //     if (element.TaskDescription != null && element.TaskDescription != undefined && element.TaskDescription != '') {
      //       element.TaskDescription = element.TaskDescription.replace(/\n/gi, ' <br>');
      //       //element.TaskDescription = this.urlify(element.TaskDescription);
      //     }
      //     let loggedhours = Math.floor(element.Logged / 60);
      //     let loggedminutes = Math.floor(element.Logged % 60);
      //     if (loggedhours > 0 && loggedminutes > 0) {
      //       element.Logged = loggedhours + 'h ' + loggedminutes + 'm';
      //     }
      //     else if (loggedhours > 0) {
      //       element.Logged = loggedhours + 'h';
      //     }
      //     else if (loggedminutes > 0) {
      //       element.Logged = loggedminutes + 'm';
      //     }
      //     else {
      //       element.Logged = "";
      //     }
      //     element.timehours = Math.floor(element.TaskEstimateMinutes / 60);
      //     element.TaskEstimateMinutes = Math.floor(element.TaskEstimateMinutes % 60);
      //     if (element.TaskStatus == 'completed') {
      //       element.TaskStatus = true
      //     }
      //     else {
      //       element.TaskStatus = false
      //     }
      //   });
      //   value.TaskChildrenCompletedOutputModelList.forEach((element: any, item1: any) => {
      //     if (element.TaskDescription != null && element.TaskDescription != undefined && element.TaskDescription != '') {
      //       element.TaskDescription = element.TaskDescription.replace(/\n/gi, ' <br>');
      //       //element.TaskDescription = this.urlify(element.TaskDescription);
      //     }
      //     let loggedhours = Math.floor(element.Logged / 60);
      //     let loggedminutes = Math.floor(element.Logged % 60);
      //     if (loggedhours > 0 && loggedminutes > 0) {
      //       element.Logged = loggedhours + 'h ' + loggedminutes + 'm';
      //     }
      //     else if (loggedhours > 0) {
      //       element.Logged = loggedhours + 'h';
      //     }
      //     else if (loggedminutes > 0) {
      //       element.Logged = loggedminutes + 'm';
      //     }
      //     else {
      //       element.Logged = "";
      //     }
      //     element.timehours = Math.floor(element.TaskEstimateMinutes / 60);
      //     element.TaskEstimateMinutes = Math.floor(element.TaskEstimateMinutes % 60);
      //     if (element.TaskStatus == 'completed') {
      //       element.TaskStatus = true
      //     }
      //     else {
      //       this.spinner.hide()
      //       element.TaskStatus = false
      //     }
      //   });
      // });
      // }
      // else {
      // if (isCompletedTasks) {
      //   let taskListData = results.TaksListChildrenModelList.filter((item: any) => {
      //     return item.ObjectId == tasklistid;
      //   });
      //   taskListData.forEach((value: any, item: any) => {
      //     value.TaskChildrenCompletedOutputModelList.forEach((element: any, item1: any) => {
      //       if (element.TaskDescription != null && element.TaskDescription != undefined && element.TaskDescription != '') {
      //         element.TaskDescription = element.TaskDescription.replace(/\n/gi, ' <br>');
      //         // element.TaskDescription = this.urlify(element.TaskDescription);
      //       }
      //       let loggedhours = Math.floor(element.Logged / 60);
      //       let loggedminutes = Math.floor(element.Logged % 60);
      //       if (loggedhours > 0 && loggedminutes > 0) {
      //         element.Logged = loggedhours + 'h ' + loggedminutes + 'm';
      //       }
      //       else if (loggedhours > 0) {
      //         element.Logged = loggedhours + 'h';
      //       }
      //       else if (loggedminutes > 0) {
      //         element.Logged = loggedminutes + 'm';
      //       }
      //       else {
      //         element.Logged = "";
      //       }
      //       element.timehours = Math.floor(element.TaskEstimateMinutes / 60);
      //       element.TaskEstimateMinutes = Math.floor(element.TaskEstimateMinutes % 60);
      //       if (element.TaskStatus == 'completed') {
      //         element.TaskStatus = true
      //       }
      //       else {
      //         this.spinner.hide()
      //         element.TaskStatus = false
      //       }
      //     });
      //   });
      // }
      // else {
      //   let taskListData = results.TaksListChildrenModelList.filter((item: any) => {
      //     return item.ObjectId == tasklistid;
      //   });
      //   taskListData.forEach((value: any, item: any) => {
      //     value.coreChildrenOutputList.forEach((element: any, item1: any) => {
      //       if (element.TaskDescription != null && element.TaskDescription != undefined && element.TaskDescription != '') {
      //         element.TaskDescription = element.TaskDescription.replace(/\n/gi, ' <br>');
      //         //element.TaskDescription = this.urlify(element.TaskDescription);
      //       }
      //       let loggedhours = Math.floor(element.Logged / 60);
      //       let loggedminutes = Math.floor(element.Logged % 60);
      //       if (loggedhours > 0 && loggedminutes > 0) {
      //         element.Logged = loggedhours + 'h ' + loggedminutes + 'm';
      //       }
      //       else if (loggedhours > 0) {
      //         element.Logged = loggedhours + 'h';
      //       }
      //       else if (loggedminutes > 0) {
      //         element.Logged = loggedminutes + 'm';
      //       }
      //       else {
      //         element.Logged = "";
      //       }
      //       element.timehours = Math.floor(element.TaskEstimateMinutes / 60);
      //       element.TaskEstimateMinutes = Math.floor(element.TaskEstimateMinutes % 60);
      //       if (element.TaskStatus == 'completed') {
      //         element.TaskStatus = true
      //       }
      //       else {
      //         element.TaskStatus = false
      //       }
      //     });
      //   });
      // }
      // }
    },
      (err) => {
        this.spinner.hide();
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get task details.Please try after some time.');
        }
      }
    );
  }

  GetTasksByTasksFilter(pagenum: any, isExportToExcel: boolean, tasklist: any) {
    //this.spinner.show();
    this.filteractive = true;
    this.createdusersfilter = [];
    this.assignedusersfilter = [];
    this.tagsselected = [];
    this.boardsselected = [];
    this.savefiltertriggered = true;
    if (this.filtertags.length != 0) {
      this.filtertags.forEach((value: any, item: any) => {
        let model = {
          "auxCategoryId": value,
        }
        this.tagsselected.push(model);
      });
    }
    if (this.filterboards.length != 0) {
      this.filterboards.forEach((value: any, item: any) => {
        let model = {
          "ChildObjectItemId": value,
        }
        this.boardsselected.push(model);
      });
    }
    if (this.userselectedlistforfilter.length != 0) {
      this.userselectedlistforfilter.forEach((value: any, item: any) => {
        let model = {
          "TaskCreatedByUserId": value,
        }
        this.createdusersfilter.push(model);
      });
    }
    if (this.Assigneduserselectedlistforfilter.length != 0) {
      this.Assigneduserselectedlistforfilter.forEach((value: any, item: any) => {
        let model = {
          "UserProfileId": value,
        }
        this.assignedusersfilter.push(model);
      });
    }
    if (this.dateuploaded == "Custom date range" && (typeof this.startdate == 'undefined' || this.startdate == "" || this.startdate == null)) {
      this.spinner.hide();
      return false;
    }
    else if (this.dateuploaded == "Custom date range" && (typeof this.enddate == 'undefined' || this.enddate == "" || this.enddate == null)) {
      this.spinner.hide();
      return false;
    }
    else if (this.enddate < this.startdate) {
      this.spinner.hide();
      return false;
    }
    else if (this.datecreated == "Custom date range" && (typeof this.createdstartdate == 'undefined' || this.createdstartdate == "" || this.createdstartdate == null)) {
      this.spinner.hide();
      return false;
    }
    else if (this.datecreated == "Custom date range" && (typeof this.createdenddate == 'undefined' || this.createdenddate == "" || this.createdenddate == null)) {
      this.spinner.hide();
      return false;
    }
    else if (this.createdenddate < this.createdstartdate) {
      this.spinner.hide();
      return false;
    }
    else {
      if (pagenum == 1) {
        this.aggregateschildren = [];
      }
      let taskFiltermodel = {
        UserProfileId: this.token_response.userId,
        TenantProfileId: this.token_response.tenantId,
        ProjectId: this.ProjectId,
        Date: this.dateuploaded,
        DateInterval: this.dateIntervel,
        DateCreated: this.datecreated,
        StartDate: this.startdate,
        EndDate: this.enddate,
        pagenum: pagenum,
        CreatedStartDate: this.createdstartdate,
        CreatedEndDate: this.createdenddate,
        TaskPriority: this.filterpriority,
        TasksCreatedByUserTypeModelList: this.createdusersfilter,
        TasksFilterAssignedToUserTypedelList: this.assignedusersfilter,
        CreatedDateInterval: this.createddateIntervel,
        TasksFilterBoardsTypeModelList: this.boardsselected,
        TaskTagsModelList: this.tagsselected,
        auxGroupItemId: tasklist,
        search: this.groupchildrensearch,
        IsExportToExcel: isExportToExcel
      };
      this.projecttaskService.GetTasksByTasksFilter(taskFiltermodel).subscribe((results) => {
        if (results != null && results != "" && results != 'undefined') {
          this.spinner.hide();
          // this.loading = false;
          this.savefiltertriggered = false;
          if (pagenum == 1) {
            this.tasklistPagenum = pagenum;
            // this.aggregateschildren = results.TaksListChildrenModelList.filter((obj:any) => {
            //   return obj.ObjectId = this.auxtaskid;
            // })[1].coreChildrenOutputList;
            this.aggregateschildren = results.TaskChildrenOutputModelList;
            let currentpage = this.router.url.split('/')[2];
            if (currentpage == "list" || currentpage == "taskdetails") {
              this.sharedService.setprojectlists(results.TaskChildrenOutputModelList);
            }
          }
          else {
            this.tasklistPagenum = pagenum;
            this.aggregateschildren.push(...results.TaskChildrenOutputModelList)
          }
          this.taskselectedmodel = results.CoreBoardsModelList;
          this.taskselectedtags = results.CoreTagsModelList;

          if (pagenum == 1) {
            if (this.aggregateschildren && this.aggregateschildren.length != 0) {
              this.aggregateschildren.forEach((value: any, item: any) => {
                if (value.TaskDescription != null && value.TaskDescription != undefined && value.TaskDescription != '') {
                  value.TaskDescription = value.TaskDescription.replace(/\n/gi, ' <br>');
                  // value.TaskDescription = this.urlify(value.TaskDescription);
                }
                value.boardexisit = this.taskselectedmodel.findIndex(obj => { return obj.TaskId == value.TaskId });
                value.timehours = Math.floor(value.TaskEstimateMinutes / 60);
                value.TaskEstimateMinutes = Math.floor(value.TaskEstimateMinutes % 60);
                let loggedhours = Math.floor(value.Logged / 60);
                let loggedminutes = Math.floor(value.Logged % 60);
                if (loggedhours > 0 && loggedminutes > 0) {
                  value.Logged = loggedhours + 'h ' + loggedminutes + 'm';
                }
                else if (loggedhours > 0) {
                  value.Logged = loggedhours + 'h';
                }
                else if (loggedminutes > 0) {
                  value.Logged = loggedminutes + 'm';
                }
                else {
                  value.Logged = "";
                }
                if (value.TaskStatus == 'completed') {
                  value.TaskStatus = true
                }
                else {
                  value.TaskStatus = false
                }
              });
            }
          }
          else {
            results.TaskChildrenOutputModelList.forEach((value: any, item: any) => {
              if (value.TaskDescription != null && value.TaskDescription != undefined && value.TaskDescription != '') {
                value.TaskDescription = value.TaskDescription.replace(/\n/gi, ' <br>');
                //value.TaskDescription = this.urlify(value.TaskDescription);
              }
              value.boardexisit = this.taskselectedmodel.findIndex(obj => { return obj.TaskId == value.TaskId });
              value.timehours = Math.floor(value.TaskEstimateMinutes / 60);
              value.TaskEstimateMinutes = Math.floor(value.TaskEstimateMinutes % 60);
              let loggedhours = Math.floor(value.Logged / 60);
              let loggedminutes = Math.floor(value.Logged % 60);
              if (loggedhours > 0 && loggedminutes > 0) {
                value.Logged = loggedhours + 'h ' + loggedminutes + 'm';
              }
              else if (loggedhours > 0) {
                value.Logged = loggedhours + 'h';
              }
              else if (loggedminutes > 0) {
                value.Logged = loggedminutes + 'm';
              }
              else {
                value.Logged = "";
              }
              if (value.TaskStatus == 'completed') {
                value.TaskStatus = true
              }
              else {
                value.TaskStatus = false
              }
            });
          }
          this.parenttasklist.Objectid = this.destinationTasklist;
          // this.GetCompletedTasksByProjectId();
        } else {
          this.spinner.hide();
        }

      },
        (err) => {
          this.spinner.hide();
          if (err.status == 401) {
            const timer = JSON.parse(localStorage.getItem('timer'));
            if (timer && (Date.now() < timer)) {
              this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
            }
          }
          else {
            this.toastralert.toastrerrorbottom("Unable to get tasks.Please try after some time");
          }
          this.savefiltertriggered = false;
        }
      );
    }
  }

  SaveOrUpdatecoreTasks(newtaskdetails: any, showtoast: any, subtask: any) {
    this.createdtasktriggered = true;
    let tasklistdata = this.projecttasklist.filter((obj) => {
      return obj.ObjectId == this.destinationTasklist;
    })
    let projectdata = this.projectlists.filter((obj) => {
      return obj.ProjectId == this.ProjectId;
    })
    let projectname = projectdata[0].ProjectName;
    this.buttononclicksave = true;
    this.IsSaveOrUpdatetaskClicked = true;
    this.assignedtoUsers = '';
    this.usersCount = '';
    this.userTags = '';
    this.userBoards = '';
    this.taskContent = '';
    this.assignedOrEdited = 'has assigned a task to';
    this.assignedusers = [];
    let priority: string = '';
    this.assignedtags = [];
    this.privacyusers = [];
    this.taskcreatedOredited = 'created';
    if (this.userselectedlist.length != 0) {
      this.userselectedlist.forEach((value: any, item: any) => {
        if (value != null) {
          let model = {
            "UserProfileId": value,
          }
          this.assignedusers.push(model);
        }
      });
    }

    if (this.userSelectedUsersModelsList.length != 0) {
      this.userSelectedUsersModelsList.forEach((value: any, item: any) => {
        if (value != null) {
          let model = {
            "UserProfileId": value,
          }
          this.privacyusers.push(model);
        }
      });
    }

    if (this.userselectedtags.length != 0) {
      this.userselectedtags.forEach((value: any, item: any) => {
        let modeltags = {
          "auxCategoryId": value,
        }
        this.assignedtags.push(modeltags);
      });
    }
    this.createdtasktriggered = true;
    if ((typeof newtaskdetails.TaskName == 'undefined' || newtaskdetails.TaskName == "" || newtaskdetails.TaskName == null)) {
      this.buttononclicksave = false;
      return false;
    }
    else if (((typeof newtaskdetails.TaskDueDate != undefined && newtaskdetails.TaskDueDate != '' && newtaskdetails.TaskDueDate != null) && (typeof newtaskdetails.TaskStartDate != undefined && newtaskdetails.TaskStartDate != '' && newtaskdetails.TaskStartDate != null)) && (newtaskdetails.TaskDueDate < newtaskdetails.TaskStartDate)) {
      this.buttononclicksave = false;
      return false;
    } else if ((typeof this.ProjectId == 'undefined' || this.ProjectId == '' || this.ProjectId == null)) {
      this.buttononclicksave = false;
      return false;
    }
    else if ((typeof this.destinationTasklist == 'undefined' || this.destinationTasklist == '' || this.destinationTasklist == null)) {
      this.buttononclicksave = false;
      return false;
    }
    else if (newtaskdetails.TaskEstimateMinutes > 60) {
      this.buttononclicksave = false;
      return false;
    }
    else {
      if (newtaskdetails.TaskId == "") {
        this.searchvalue = '-1';
        this.filterTerm = '';
        this.clearfilter('newtask');
        this.TaskId = '00000000-0000-0000-0000-000000000000';
        this.taskstatus = 'new';
        this.TaskParentTaskId = null;
        this.taskcretaedupdated = "Task created successfully";
      }

      let taskdetails = {
        UserProfileId: this.token_response.userId,
        TenantProfileId: this.token_response.tenantId,
        ProjectId: this.ProjectId,
        TaskId: '00000000-0000-0000-0000-000000000000',
        auxGroupItemIdTaskList: this.destinationTasklist,
        auxGroupItemIdBoards: null,
        TaskStartDate: newtaskdetails.TaskStartDate,
        TaskDueDate: newtaskdetails.TaskDueDate,
        TaskEstimateMinutes: 0,
        TaskPriority: 0,
        TaskProgress: 0,
        TaskName: newtaskdetails.TaskName,
        TaskDescription: newtaskdetails.TaskDescription,
        auxGroupItemName: tasklistdata[0].ObjectName,
        auxGroupItemDescription: '',
        CoreTaskUsersModelList: this.assignedusers,
        TaskTagsModelList: this.assignedtags,
        TaskStatus: 'new',
        TaskParentTaskId: this.TaskParentTaskId,
        TaskIsPrivate: (this.privacyusers.length > 0) ? 1 : 0,
        PrivacyUsersTypeModelList: this.privacyusers
      };
      this.projectdeatilsService.SaveOrUpdatecoreTasks(taskdetails).subscribe((results) => {

        if (results != null && results != "" && results != undefined) {
          this.buttononclicksave = false;
          if (results == '99999999-9999-9999-9999-999999999999') {
            this.toastralert.toastrerrorbottom("This Task is already exits, please check once.");
          }
          else {
            if (this.TaskId != "" && this.TaskId != null && this.TaskId != undefined && this.TaskId != '00000000-0000-0000-0000-000000000000') {
              this.assignedOrEdited = "has edited a task to";
              this.taskcreatedOredited = 'edited';
              this.IsSaveOrUpdatetaskClicked = true;
            }
            // if (this.taskstatus == 'completed' && this.updatecompletedtask) {
            //   this.taskcretaedupdated = "Task completed successfully";
            //   this.assignedOrEdited = "has completed a task assigned to";
            //   this.taskcreatedOredited = 'completed';
            //   // this.GetCompletedTaskListsProjectId();
            //   // this.get_Aggregateslist(this.tasklistid);
            //   this.IsSaveOrUpdatetaskClicked = true;
            // }
            // else if (this.taskstatus == 'reopened' && this.updatecompletedtask) {
            //   this.taskcretaedupdated = "Task reopened successfully";
            //   this.assignedOrEdited = "has reopened a task assigned to";
            //   this.taskcreatedOredited = 'reopened';
            //   // this.GetCompletedTaskListsProjectId();
            //   // this.get_Aggregateslist(this.tasklistid);
            //   this.IsSaveOrUpdatetaskClicked = true;
            // }
            else {
              // this.get_Aggregateslist(this.tasklistid);
              // this.GetCompletedTaskListsProjectId();
            }
            // this.loading = false;
            this.spinner.hide();
            if (showtoast) {
              // window.location.reload();
              this.toastralert.toastersuccesstop(this.taskcretaedupdated);
            }
            if (this.TaskId != "" && this.TaskId != null && this.TaskId != undefined && this.TaskId != '00000000-0000-0000-0000-000000000000') {
              this.updatecompletedtask = false;
              // this.getCoreObjectDetails(this.TaskId);
              this.IsSaveOrUpdatetaskClicked = false;
              // this.GetCoreObjectChildrenforTasks(this.selectedPageNum, this.parenttasklist.Objectid, false, true, this.TaskId);

            }
            else {
              this.IsSaveOrUpdatetaskClicked = false;
              let currentpage = this.router.url.split('/')[2];
              if (currentpage == "list") {
                this.maintasklistId = atob(this.router.url.split('/')[4]);
                if (this.maintasklistId == this.destinationTasklist) {
                  this.GetTasksByTasksFilter(1, false, this.destinationTasklist);
                  this.GetGroupListByauxGroupItemId(this.destinationTasklist);

                  //this.get_Aggregateschildren(this.parenttasklist.Objectid, this.parenttasklist.ObjectName);
                  //this.GetGroupsList(this.tasklistid, this.ProjectId);
                }
                this.GetGroupsList(this.tasklistid, this.ProjectId);
              }
              else if (currentpage == "taskdetails") {
                this.GetTasksByTasksFilter(1, false, this.destinationTasklist);
              }
              else if (currentpage == "projecttasks") {
                this.GetCoreObjectChildrenforTasks(1, null, false, false, null);
              }
              else if (currentpage == "userTasks") {
                this.userProfileId = this.router.url.split('/')[3];
                this.UserProfileId = atob(this.userProfileId);
                this.GetTasksByUserProfileId(1 ,this.UserProfileId);
              }
              else if (currentpage == "projectlist") {
                let txtSearch = this.sharedService.getprojectlistsearchstring();
                txtSearch == '' ? (txtSearch = '-1') : '';
                let UserIsAdmin = (this.token_response.UserRoleName == 'Administrator' || this.token_response.UserRoleName == "Owner") ? true : false;
                this.get_globalprojectlist(this.token_response.userId, this.prodauxCategoryId, this.isActive, this.pagenum, txtSearch, true, UserIsAdmin);
              }

              else {

              }
            }
            // this.get_Aggregateschildren(this.parenttasklist.Objectid, this.parenttasklist.ObjectName, this.parenttasklist.TaskCounts, this.parenttasklist.auxGroupItemIsPrivate);
            if (newtaskdetails.TaskPriority && newtaskdetails.TaskPriority != 0) {
              priority = newtaskdetails.TaskPriority == '200' ? 'Low' : newtaskdetails.TaskPriority == '100' ? 'Medium' : newtaskdetails.TaskPriority == '1' ? 'High' : '';
            }
            else {
              priority = 'Not Set'
            }
            if (this.assignedusers && this.assignedusers.length > 0) {
              this.usersCount = this.assignedusers.length == 1 ? 'you.' : 'you and ' + (this.assignedusers.length - 1) + ' other people.'
              this.assignedusers.forEach((value: any) => {
                let userEmail = this.userprofiles.filter((item: any) => {
                  return item.UserProfileId == value.UserProfileId;
                });
                if (this.assignedtoUsers == '') {
                  this.assignedtoUsers = userEmail[0].UserFirstName + ' ' + userEmail[0].UserLastName;
                }
                else {
                  this.assignedtoUsers += ', ' + userEmail[0].UserFirstName + ' ' + userEmail[0].UserLastName;
                }
              })
            }
            else {
              this.assignedtoUsers = 'Anyone';
            }

            this.userTags = 'Not Set';

            this.userBoards = 'Not Set'
            let emailTaskId = btoa(results);
            let emailTaskListId = btoa(this.destinationTasklist);
            let emailProjectId = btoa(this.ProjectId);
            let taskUrl = environment.baseUrl + 'project/taskdetails/' + emailTaskId + '/' + emailProjectId;
            let projecturl = environment.baseUrl + 'project/projecttasks/' + emailProjectId;
            let taskListurl = environment.baseUrl + 'project/list/' + emailProjectId + '/' + emailTaskListId;
            let emailTaskListName = tasklistdata[0].ObjectName;
            let emailProjectName = projectname;
            let companyLogo = '<a><img src="' + this.token_response.CompanyLogoImage + '" height="60" width="200" style="display: block;" alt=""></a>';
            if (this.taskstatus == 'completed') {
              this.taskContent = '<a href="' + taskUrl + '"><del><strong>' + newtaskdetails.TaskName + '</strong></del></a>';
            }
            else {
              this.taskContent = '<strong>' + newtaskdetails.TaskName + '</strong> — <a href="' + taskUrl + '"><u>View</u></a>';
            }
            this.assignedusers.forEach((value: any, index: any) => {
              let userEmail = this.userprofiles.filter((item: any) => {
                return item.UserProfileId == value.UserProfileId;
              });
              if (userEmail[0].UserProfileId != this.token_response.userId) {
                let replaceElementsList: any[] = [];
                let newList: any[] = [
                  { Name: 'Company_Logo', Value: companyLogo },
                  { Name: 'AssignedBy', Value: this.token_response.FullName },
                  { Name: 'UsersCount', Value: this.usersCount },
                  { Name: 'AssignedTo', Value: this.assignedtoUsers },
                  { Name: 'TaskName', Value: newtaskdetails.TaskName },
                  { Name: 'TaskUrl', Value: taskUrl },
                  { Name: 'TaskListName', Value: emailTaskListName },
                  { Name: 'TaskListUrl', Value: taskListurl },
                  { Name: 'ProjectName', Value: emailProjectName },
                  { Name: 'ProjectUrl', Value: projecturl },
                  { Name: 'CompanyName', Value: this.token_response.CompanyName },
                  { Name: 'TaskId', Value: results },
                  { Name: 'AssignedTags', Value: this.userTags },
                  { Name: 'AssignedBoard', Value: this.userBoards },
                  { Name: 'AssignedOrEdited', Value: this.assignedOrEdited },
                  { Name: 'TaskContent', Value: this.taskContent },
                  { Name: 'TaskPriority', Value: priority }

                ];
                replaceElementsList = newList;
                let taskdetails = {
                  ToAddress: userEmail[0].UserEmail,
                  SubjectLine: '(' + emailProjectName + ') - Task ' + this.taskcreatedOredited + ': ' + newtaskdetails.TaskName + ' (' + emailTaskListName + ')',
                  TemplateType: 'Email',
                  TemplateName: 'D9 Task Email Template',
                  SourceCustomerId: environment.SourceCustomerId,
                  SourceTenantId: environment.sourcetenantid,
                  TempReplaceElements: replaceElementsList,
                  ReferenceId: results,
                  ReferenceType: "TaskCreatedOrUpdated"
                };
                this.sendEmailsthroughFlexdripService.SaveSendTaskCreatedEmailsToUsers(taskdetails).subscribe((results) => {
                  if (results != null && results != "" && results != undefined && results.responseText == 'Email sent') {

                  }
                  else {
                    // this.toastralert.toastrerror('Error occurred while sending emails');
                  }
                },
                  (err) => {
                    if (err.status == 401) {
                      const timer = JSON.parse(localStorage.getItem('timer'));
                      if (timer && (Date.now() < timer)) {
                        this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
                      }
                    }
                    else {
                      this.toastralert.toastrerrorbottom("Unable to send emails.Please try after some time.");
                    }
                  }
                );
              }
            })
            this.cleartaskdetailsmodel();
          }

        }
        else {
          // this.loading = false;
          this.spinner.hide();
          this.buttononclicksave = false;
          this.IsSaveOrUpdatetaskClicked = false;
          this.toastralert.toastrerror('Error occurred while creating new Task');
        }
      },
        (err) => {
          // this.loading = false;
          this.spinner.hide();
          this.buttononclicksave = false;
          this.IsSaveOrUpdatetaskClicked = false;
          if (err.status == 401) {
            const timer = JSON.parse(localStorage.getItem('timer'));
            if (timer && (Date.now() < timer)) {
              this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
            }
          }
          else {
            this.toastralert.toastrerrorbottom("Unable to save details.Please try after some time.");
          }
        }
      );
    }
  }

  clearfilter(textforcalling: any) {
    this.dateuploaded = '';
    this.savefiltertriggered = false;
    this.startdate = null;
    this.enddate = null;
    this.createdstartdate = null;
    this.createdenddate = null;
    this.createddateIntervel = null;
    this.dateIntervel = null;
    this.datecreated = '';
    this.filterpriority = 300;
    this.createdusersfilter = [];
    this.assignedusersfilter = [];
    this.boardsselected = [];
    this.tagsselected = [];
    this.filtertags = [];
    this.filterboards = [];
    this.userselectedlistforfilter = [];
    this.Assigneduserselectedlistforfilter = [];
    this.filteractive = false;
    this.istaskcollapsed = true;
    this.filterTerm = '';
    this.activechildren = 0;
    if (textforcalling == 'clearbutton') {
      // this.GetCoreObjectChildrenforTasks(1, null, this.isCompletedTasks, false, null);
    }
  }


  // addproject

  get_categoryName(event: any) {
    this.categoryName = event;
  }

  get_companyId(event: Event) {
    this.companyId = event;
    this.GetProjectUsersListByTenantProfileId(this.companyId);
  }

  GetAllTenantCompanysByTenantProfileId() {
    this.projectlistService.GetAllTenantCompanysByTenantProfileId(this.token_response.tenantId, this.token_response.userId, this.txtSearch).subscribe((results) => {
      if (results != null && results != 'undefined') {
        this.tenantCompanieslist = results;
      }
    },
      (err) => {
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom("Unable to get Companies.Please try angain after some time.");
        }
      }
    );
  }

  getAllCategoriesOrTagsByTenantProfileId() {
    this.categoryService.GetAllCategoriesOrTagsByTenantProfileId(this.token_response.userId, this.token_response.tenantId, 'Technology', '-1').subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.categorydetails = results;
      }
    },
      (err) => {
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get Categories.Please try after some time.');
        }
      }
    );
  }

  GetProjectUsersListByTenantProfileId(companyId: any) {
    this.projectlistService.GetProjectUsersListByTenantProfileId(this.token_response.userId, this.token_response.tenantId).subscribe((results) => {
      if (results != null && results != 'undefined') {
        if (this.projectDetails.ProjectId == 'undefined' || this.projectDetails.ProjectId == "" || this.projectDetails.ProjectId == null) {
          const finaldata = results.filter((item: any) => {
            return item.TenantCompanyId === companyId || item.TenantCompanyId == this.token_response.TenantCompanyId; // Filter out the appropriate one
          });
          this.usersData = finaldata;
        }
        else {
          const tenantcompanydata = results.filter((item: any) => {
            return item.TenantCompanyId == this.token_response.TenantCompanyId; // Filter out the appropriate one
          });
          if (companyId != this.token_response.TenantCompanyId) {
            const selectedcompanydata = results.filter((item: any) => {
              return item.TenantCompanyId === companyId; // Filter out the appropriate one
            });
            this.selectedcompanydata = selectedcompanydata;
          }
          this.tenantcompanydata = tenantcompanydata;
        }
      }
    },
      (err) => {
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom("Unable to get Companies.Please try angain after some time.");
        }
      }
    );

  }

  cancelModel() {
    this.companyId = "";
    this.categoryName = null;
    this.newcategoryName = null;
    this.createprojecttriggered = false;
    this.projectDetails.ProjectName = "";
    this.projectDetails.ProjectDescription = "";
    this.projectDetails.ProjectStartDate = "";
    this.projectDetails.ProjectEndDate = "";
    this.projectDetails.ProjectOwnerId = "";
    this.usersData = [];
    this.ProjectId = "";
    this.modalService.dismissAll();
    this.projectcereatedorupdate = '';
    this.projectDetails.ProjectId = '';
    this.selectedcompanydata = [];
    this.showTextField = false;
    this.projectDetails.companyName = '';

  }
  GetCoreProjectByProjectId(projetId: any) {
    this.userSelectedUsersModelsList = [];
    this.owneruserSelectedUsersModelsList = [];
    this.companyUserModelsList = [];
    this.projectlistService.GetCoreProjectByProjectId(this.token_response.tenantId, projetId, this.token_response.userId).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        if (this.IsSaveOrUpdateClicked) {
          let indexofProject = this.projectlists.findIndex((obj) => {
            return obj.ProjectId == projetId;
          });
          this.projectlists[indexofProject] = results;
        }
        else {
          this.projectDetails = results;
          this.companyId = this.projectDetails.TenantCompanyId;
          this.categoryName = this.projectDetails.ProjectCategory;
          if (this.projectDetails.ProjectOwnerId == '00000000-0000-0000-0000-000000000000') {
            this.projectDetails.ProjectOwnerId = null;
          }
          this.projectDetails.ProjectUsersModelList.forEach((value: any, item: any) => {
            if (this.token_response.TenantCompanyId == value.TenantCompanyId) {
              this.userSelectedUsersModelsList.push(value.UserProfileId);
            }
            else {
              this.companyUserModelsList.push(value.UserProfileId);
            }
          });
          this.projectDetails.ProjectStartDate = this.datepipe.transform(this.projectDetails.ProjectStartDate, 'yyyy-MM-dd');
          this.projectDetails.ProjectEndDate = this.datepipe.transform(this.projectDetails.ProjectEndDate, 'yyyy-MM-dd');
        }
        setTimeout(() => {
          this.IsSaveOrUpdateClicked = false;
        }, 500)
      }
    },
      (err) => {
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get project details.Please try after some time.');
        }
      }
    );
  }
  SaveOrUpdatecoreProjects(isprojectowner: any, selectstartdate: any) {
    this.IsSaveOrUpdateClicked = true;
    this.createprojecttriggered = true;
    this.buttononclicksave = true;
    this.selectedUsersList = [];
    this.onwerselectedUsersList = [];
    if (this.usersData != undefined) {
      this.allUsers = this.usersData.filter((item: any) => {
        return item.TenantCompanyId === this.companyId || item.TenantCompanyId == this.token_response.TenantCompanyId;
      });
      this.allUsers.forEach((user: any, item: any) => {
        let selectedUserList = user.SelectedUsersModelsList;
        if (selectedUserList.length > 0) {
          selectedUserList.forEach((selecteduser: any, item: any) => {
            let model = {
              UserProfileId: selecteduser.UserProfileId
            }
            this.onwerselectedUsersList.push(selecteduser);
            this.selectedUsersList.push(model);
          })
        }

      });
    }
    if (this.selectedUsersList.length == 0 && (this.companyId != undefined && this.companyId != "" && this.companyId != null)) {
      this.toastralert.toastrerrorbottom("Please atleast add one people");
    }
    if ((typeof this.projectDetails.ProjectName == 'undefined' || this.projectDetails.ProjectName == "" || this.projectDetails.ProjectName == null)) {
      this.buttononclicksave = false;
      return false;
    }
    else if ((this.companyId == undefined || this.companyId == "" || this.companyId == null) && (this.projectDetails.companyName == undefined || this.projectDetails.companyName == "" || this.projectDetails.companyName == null)) {
      this.buttononclicksave = false;
      return false;
    }
    else if (((typeof this.projectDetails.ProjectEndDate != undefined && this.projectDetails.ProjectEndDate != '' && this.projectDetails.ProjectEndDate != null) && (typeof this.projectDetails.ProjectStartDate != undefined && this.projectDetails.ProjectStartDate != '' && this.projectDetails.ProjectStartDate != null)) && ((this.projectDetails.ProjectEndDate) < (this.projectDetails.ProjectStartDate))) {
      this.buttononclicksave = false;
      return false;
    }
    else {
      if (this.projectDetails.ProjectId == 'undefined' || this.projectDetails.ProjectId == "" || this.projectDetails.ProjectId == null) {
        this.ProjectId = null;
        this.projectcereatedorupdate = "Project created successfully";
        if (this.selectedUsersList.length == 0) {
          this.buttononclicksave = false;
          return false;
        }
      }

      if (this.companyId == undefined || this.companyId == "" || this.companyId == null) {
        this.companyId = null;
      }
      if (this.projectDetails.companyName == undefined || this.projectDetails.companyName == "" || this.projectDetails.companyName == null) {
        this.projectDetails.companyName = null;
      }
      if (this.newcategoryName == undefined || this.newcategoryName == "" || this.newcategoryName == null) {
        this.newcategoryName = null;
      }
      if (this.categoryName == undefined || this.categoryName == "" || this.categoryName == null) {
        this.categoryName = null;
      }
      let ProjectDetails = {
        UserProfileId: this.token_response.userId,
        TenantProfileId: this.token_response.tenantId,
        ProjectId: null,
        Projectname: this.projectDetails.ProjectName,
        ProjectDescription: '',
        TenantCompanyId: this.companyId,
        IsActive: 1,
        auxCategoryName: "Technology",
        ProjectOwnerId: null,
        auxSubCategoryName: this.categoryName,
        CoreProjectUsersModelList: this.selectedUsersList,
        ProjectStartDate: this.projectDetails.ProjectStartDate,
        ProjectEndDate: this.projectDetails.ProjectEndDate,
        ProjectIsBillable: 0,
        ProjectCompleted: 0,
        ProjectOverviewStartPage: 'Default',
        ContactInfo: null,
        CompanyName: this.projectDetails.companyName,
        NewauxSubCategoryName: this.newcategoryName
      };
      this.projectlistService.SaveOrUpdatecoreProjects(ProjectDetails).subscribe((results) => {
        if (results != null && results != "" && results != 'undefined') {
          this.IsSaveOrUpdateClicked = false;
          if (results == '99999999-9999-9999-9999-999999999999') {
            this.IsSaveOrUpdateClicked = false;
            this.toastralert.toastrerrorbottom("This category already exits, please check once.");
          }
          else if (results == '11111111-1111-1111-1111-111111111111') {
            this.IsSaveOrUpdateClicked = false;
            this.toastralert.toastrerrorbottom("This client already exits, please check once.");
          }
          else if (results == '22222222-2222-2222-2222-222222222222') {
            this.IsSaveOrUpdateClicked = false;
            this.toastralert.toastrerrorbottom(" client & category already exits, please check once.");
          }
          else {
            if (!selectstartdate && !isprojectowner) {
              this.toastralert.toastersuccesstop(this.projectcereatedorupdate);
            }
            this.pagenum = 1;
            if (ProjectDetails.ProjectId != "" && ProjectDetails.ProjectId != null && ProjectDetails.ProjectId != undefined && ProjectDetails.ProjectId != '00000000-0000-0000-0000-000000000000') {
              this.GetCoreProjectByProjectId(ProjectDetails.ProjectId);
            }
            else {
              this.IsSaveOrUpdateClicked = false;
              this.GetAllTenantCompanysByTenantProfileId();
              let currentpage = this.router.url.split('/')[2];
              if (currentpage == "projectlist") {
                let txtSearch = this.sharedService.getprojectlistsearchstring();
                txtSearch == '' ? (txtSearch = '-1') : '';
                let UserIsAdmin = (this.token_response.UserRoleName == 'Administrator' || this.token_response.UserRoleName == "Owner") ? true : false;
                this.get_globalprojectlist(this.token_response.userId, this.prodauxCategoryId, this.isActive, this.pagenum, txtSearch, true, UserIsAdmin);
              }
              else if (currentpage == "userProjects") {
                let userId = this.router.url.split('/')[3];
                this.GetUserDetailsByTenantUserProfileId1(atob(userId));
              }
              else if (currentpage == "clientProject") {
                let companyId = this.router.url.split('/')[3];
                this.GetProjectsByCompanyId(atob(companyId), 1);
              }
              else {

              }
            }
            // this.projectlists=[];
            this.cancelModel();
          }
          this.get_projectlist(this.prodauxCategoryId, this.isActive, this.pagenum, this.txtSearch, false);
        }
        else {
          this.buttononclicksave = false;
          this.toastralert.toastrerror('Error occurred while saving component');
          this.IsSaveOrUpdateClicked = false;
        }
      },
        (err) => {
          this.buttononclicksave = false;
          if (err.status == 401) {
            const timer = JSON.parse(localStorage.getItem('timer'));
            if (timer && (Date.now() < timer)) {
              this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
            }
          }
          else {
            this.toastralert.toastrerrorbottom("Unable to save project.Please try after some time");
          }
          this.IsSaveOrUpdateClicked = false;
        },
      );
    }
  }

  GetProjectsByCompanyId(tenantCompanyId: any, pagenum: any) {
    this.clientService.GetProjectsByCompanyId(this.token_response.tenantId, this.token_response.userId, tenantCompanyId, pagenum).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        this.spinner.hide();
        this.sharedService.setcompanyProject(results);
      }
    },
      (err) => {
        console.log(err);
        this.spinner.hide();
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to edit client details.Please try after some time.');
        }
      })
  }

  validateEmail(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isEmailValid = re.test(email);
    return this.isEmailValid;
  }
  validatemobile(mobile: string) {
    var re = /^\d{10}$/;
    if (mobile != "" && mobile != null) {
      this.ismobilevalid = re.test(mobile);
      return this.ismobilevalid;
    }
    else {
      return true
    }
  }

  trim(str: string) {
    return str.replace(/ /g, '');
  }

  SaveOrUpdateTenantCompany(addClient: any) {
    this.isbtndisabled = true;
    this.createdclienttriggered = true;
    addClient.CompanyEmail1 = this.trim(addClient.CompanyEmail1);
    if ((typeof addClient.CompanyName == 'undefined' || addClient.CompanyName == "" || addClient.CompanyName == null)) {
      this.isbtndisabled = false;
      return false;
    }
    else if ((!this.validateEmail(this.trim(addClient.CompanyEmail1)))) {
      this.isbtndisabled = false;
      return false;
    }
    else if (!this.validatemobile((addClient.CompanyPhone))) {
      this.isbtndisabled = false;
      // this.createdclienttriggered = false;
      return false;
    }
    else if ((typeof addClient.CompanyEmail1 == 'undefined' || addClient.CompanyEmail1 == "" || addClient.CompanyEmail1 == null)) {
      this.isbtndisabled = false;
      return false;
    }

    else {
      if (addClient.TenantCompanyId == "") {
        this.TenantCompanyId = '00000000-0000-0000-0000-000000000000';
        this.ClientProfiledisplaycontent = 'Client created successfully';
      }
      else {
        this.TenantCompanyId = addClient.TenantCompanyId;
        this.ClientProfiledisplaycontent = 'Client updated successfully';
      }
      let saveclientdetails = {
        UserProfileId: this.token_response.userId,
        TenantProfileId: this.token_response.tenantId,
        TenantCompanyId: this.TenantCompanyId,
        CompanyName: addClient.CompanyName,
        CompanyLogoImage: this.CompanyLogoImageName,
        CompressedCompanyLogoImage: this.CompressedCompanyLogo != null && this.CompressedCompanyLogo != '' ? this.CompressedCompanyLogo : this.CompanyLogoImageName,
        CompanyLogoUploadedToServer: this.s3BaseUrl,
        CompanyAddressLine1: addClient.CompanyAddressLine1,
        CompanyAddressLine2: addClient.CompanyAddressLine2,
        CompanyAddressCity: addClient.CompanyAddressCity,
        CompanyAddressState: addClient.CompanyAddressState,
        CompanyAddressZip: addClient.CompanyAddressZip,
        CountryCode: addClient.CountryCode,
        CompanyEmail1: addClient.CompanyEmail1,
        CompanyWebsite: addClient.CompanyWebsite,
        CompanyPhone: addClient.CompanyPhone,
        CompanyFax: addClient.CompanyFax,
        CompanyProfile: null,
        CompanyPrivateNotes: null,
      }
      this.clientService.SaveOrUpdateTenantCompany(saveclientdetails).subscribe((results) => {
        if (results != null && results != "" && results != 'undefined') {
          this.toastralert.toastersuccesstop(this.ClientProfiledisplaycontent);
          this.isbtndisabled = false;
          this.GetAllTenantCompanysByTenantProfileId();
          this.clearclientmodel();
        } else {
          this.toastralert.toastrerror('Error occurred while saving component');
          this.isbtndisabled = false;
        }
      },
        (err) => {
          this.isbtndisabled = false;
          if (err.status == 401) {
            const timer = JSON.parse(localStorage.getItem('timer'));
            if (timer && (Date.now() < timer)) {
              this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
            }
          }
          else {
            this.toastralert.toastrerrorbottom("Unable to save component.Please try after some time");
          }
        }
      )
    }
  }

  clearclientmodel() {
    this.CompanyLogoImage = '../../../assets/images/ComapanyImage.jpg';
    this.isbtndisabled = false;
    this.CompanyLogoImageName = '';
    this.CompressedCompanyLogo = '';
    this.s3BaseUrl = '';
    this.addClient.CompanyName = '';
    this.addClient.CompanyWebsite = '';
    this.addClient.CompanyEmail1 = '';
    this.addClient.CompanyPhone = '';
    this.addClient.CompanyFax = '';
    this.addClient.CompanyAddressLine1 = '';
    this.addClient.CompanyAddressLine2 = '';
    this.addClient.CompanyAddressCity = '';
    this.addClient.CompanyAddressState = '';
    this.addClient.CompanyAddressZip = '';
    this.addClient.CountryCode = '';
    this.addClient.TenantCompanyId = '';
    this.ClientProfiledisplaycontent = '';
    this.TenantCompanyId = '';
    this.createdclienttriggered = false;
    this.editclientcompanyname = '';
    this.modalRef.close();
  }

  openModalclient(centerLargeDataModal: any): void {
    this.modalRef = this.modalService.open(centerLargeDataModal, { size: 'lg', centered: true });
  }

  RemoveUserImage() {
    this.CompanyLogoImage = '../../../assets/images/ComapanyImage.jpg';
    this.CompanyLogoImageName = '';
  }

  UploadFile(files: FileList) {
    if (!files || files.length === 0) {
      // Handle case when no files are selected
      this.toastralert.toastrerrorbottom('Upload atleast one file');
      return;
    }
    for (let i = 0; i < files.length; i++) {
      const file: File = files[i];
      if (!this.isValidFile(file)) {
        return;
      }
      const validExtensions = environment.validImageExtensions;

      if (!this.isValidFileExtension(file.name, validExtensions)) {
        this.toastralert.toastrerrorbottom('File extension is invalid.');
        return false;
      }

      const fileExtension = file.name.split('.').pop().toLowerCase();
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const dataUrl = reader.result as string;
        this.imageCompress.compressFile(dataUrl, -1, 75, 50).then(result => {
          const compressedImage = result;

          this.fileuploadservice.UploadFileInPublicBucket(file, this.token_response.tenantId, 'companyLogo', fileExtension).subscribe((results) => {
            if (results != null && results != "" && results != 'undefined') {
              this.CompanyLogoImage = results.responseText;
              this.CompanyLogoImageName = results.filename;
              this.fileuploadservice.UploadFileInPublicBucket(this.dataURLtoBlob(compressedImage), this.token_response.tenantId, 'companyLogo', fileExtension).subscribe((results) => {
                if (results != null && results != "" && results != 'undefined') {
                  this.CompressedCompanyLogo = results.filename;
                  this.s3BaseUrl = results.baseUrl;
                }
              },
                (err) => {
                  if (err.status == 401) {
                    const timer = JSON.parse(localStorage.getItem('timer'));
                    if (timer && (Date.now() < timer)) {
                      console.log(err);
                      this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
                    }
                  }
                  else {
                    console.log(err);
                    this.toastralert.toastrerrorbottom('Unable to upload user profile Please try after some time.');
                  }
                }
              );
            }
          },
            (err) => {
              if (err.status == 401) {
                const timer = JSON.parse(localStorage.getItem('timer'));
                if (timer && (Date.now() < timer)) {
                  console.log(err);
                  this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
                }
              }
              else {
                console.log(err);
                this.toastralert.toastrerrorbottom('Unable to upload user profile Please try after some time.');
              }
            }
          );

        });
      };
    }
  }

  isValidFile(file: File): boolean {
    // Implement any custom file validations here
    const fileSize = file.size / 1024; // in KB
    const maxSize = 5120; // 5MB

    if (fileSize > maxSize) {
      this.toastralert.toastrerrorbottom('File size accept upto 5 Mb.');
      return false;
    }
    return true;
  }

  isValidFileExtension(fileName: string, validExtensions: string[]): boolean {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return validExtensions.some(ext => fileExtension.endsWith(ext));
  }

  dataURLtoBlob(dataURL: string): Blob {
    const arr = dataURL.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    const mime = mimeMatch && mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  GetGroupListByauxGroupItemId(auxgroupid: any) {
    this.userSelectedUsersModelsList = [];
    this.projectdeatilsService.GetGroupListByauxGroupItemId(this.tasklistid, this.token_response.tenantId, this.destinationproject, auxgroupid).subscribe((results) => {
      if (results != null && results != "" && results != 'undefined') {
        //this.auxtasklistdetails = results;
        if (results.privacyUsersList.length != 0 && results.privacyUsersList != null) {
          results.privacyUsersList.forEach((value: any, item: any) => {
            if (value.UserProfileId != '00000000-0000-0000-0000-000000000000') {
              this.userSelectedUsersModelsList.push(value.UserProfileId);
            }
           

          });
        }
        let currentpage = this.router.url.split('/')[2];
        if (currentpage == "list" || currentpage == "taskdetails") {
          this.sharedService.settaskscounts(results);
        }
      }
    },
      (err) => {
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get tasklist details.Please try after some time.');
        }
      }
    );
  }

  GetTasksByUserProfileId(pagenum: any, userprofileid:any) {
    this.taskdetailsService.GetTasksByUserProfileId(this.token_response.tenantId, this.token_response.userId, pagenum, userprofileid, null, -1).subscribe((results) => {
      if (pagenum == 1) {
        this.projecthierarchy = results.ProjectCoreObjectModelList;
        this.TasksListForTasksModelList = results.TasksListForTasksModelList;
      }
      else {
        this.projecthierarchy.push(...results.ProjectCoreObjectModelList);
        this.TasksListForTasksModelList = results.TasksListForTasksModelList;
      }
      if (pagenum == 1) {
        if (this.projecthierarchy && this.projecthierarchy.length != 0) {
          this.projecthierarchy.forEach((element: any, item: any) => {
            if (element.coreOutputModelList && element.coreOutputModelList.length != 0) {
              element.coreOutputModelList.forEach((value: any, item: any) => {
                if (value.TaskDescription != null && value.TaskDescription != undefined && value.TaskDescription != '') {
                  value.TaskDescription = value.TaskDescription.replace(/\n/gi, ' <br>');
                  // value.TaskDescription = this.urlify(value.TaskDescription);
                }
                value.timehours = Math.floor(value.TaskEstimateMinutes / 60);
                value.TaskEstimateMinutes = Math.floor(value.TaskEstimateMinutes % 60);
                let loggedhours = Math.floor(value.Logged / 60);
                let loggedminutes = Math.floor(value.Logged % 60);
                if (loggedhours > 0 && loggedminutes > 0) {
                  value.Logged = loggedhours + 'h ' + loggedminutes + 'm';
                }
                else if (loggedhours > 0) {
                  value.Logged = loggedhours + 'h';
                }
                else if (loggedminutes > 0) {
                  value.Logged = loggedminutes + 'm';
                }
                else {
                  value.Logged = "";
                }
                if (value.TaskStatus == 'completed') {
                  value.TaskStatus = true
                }
                else {
                  value.TaskStatus = false
                }
              });
            }
          });
        }

      } else {
        if (results.ProjectCoreObjectModelList && results.ProjectCoreObjectModelList.length != 0) {
          results.ProjectCoreObjectModelList.forEach((element: any, item: any) => {
            if (element.coreOutputModelList && element.coreOutputModelList.length != 0) {
              element.coreOutputModelList.forEach((value: any, item: any) => {
                if (value.TaskDescription != null && value.TaskDescription != undefined && value.TaskDescription != '') {
                  value.TaskDescription = value.TaskDescription.replace(/\n/gi, ' <br>');
                  // value.TaskDescription = this.urlify(value.TaskDescription);
                }
                value.timehours = Math.floor(value.TaskEstimateMinutes / 60);
                value.TaskEstimateMinutes = Math.floor(value.TaskEstimateMinutes % 60);
                let loggedhours = Math.floor(value.Logged / 60);
                let loggedminutes = Math.floor(value.Logged % 60);
                if (loggedhours > 0 && loggedminutes > 0) {
                  value.Logged = loggedhours + 'h ' + loggedminutes + 'm';
                }
                else if (loggedhours > 0) {
                  value.Logged = loggedhours + 'h';
                }
                else if (loggedminutes > 0) {
                  value.Logged = loggedminutes + 'm';
                }
                else {
                  value.Logged = "";
                }
                if (value.TaskStatus == 'completed') {
                  value.TaskStatus = true
                }
                else {
                  value.TaskStatus = false
                }
              });
            }
          });
        }
      }
      let currentpage = this.router.url.split('/')[2];
      if (currentpage == "userTasks") {
        this.sharedService.setusertasks(results.ProjectCoreObjectModelList);
      }
      this.spinner.hide();
    },
      (err) => {
        this.spinner.hide();
        console.log(err);
        if (err.status == 401) {
          const timer = JSON.parse(localStorage.getItem('timer'));
          if (timer && (Date.now() < timer)) {
            this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
          }
        }
        else {
          this.toastralert.toastrerrorbottom('Unable to get task details.Please try after some time.');
        }
      }
    );
  }
}

