import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UseroptedpropertiesService {

  constructor(private http: HttpClient) {
  }

  getCoreObjectProperties(UserProfileId: any, tenantId: any) {
    return this.http.get(environment.GetCoreObjectProperties + '/' + UserProfileId + '/' + tenantId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetappTimeZones(UserProfileId: any, tenantId: any) {
    return this.http.get(environment.GetappTimeZones + '/' + tenantId + '/' + UserProfileId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }


}
