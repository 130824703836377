import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeNextLine'
})
export class RemoveNextLinePipe implements PipeTransform {

  transform(description: any): string {
 
    let CovertedDescription:any = '';
    if(description != null && description != undefined && description != ''){
        CovertedDescription = description.replace(/\n/gi, ' <br>');
    }
    return CovertedDescription;
  }

}
