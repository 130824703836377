import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[setUserProfiles]'
})
export class SetUserProfilesDirective implements OnChanges {
    @Input('setUserProfiles') users: string;
    @Input() imageUrls: string;

    constructor(private elementRef: ElementRef) { }

    ngOnChanges(changes: SimpleChanges) {
        let html = '<div class="avatar-group">';
        if (this.users) {
            let nameList = this.users != null && this.users != ''? this.users.split('\\n') : [];
            let urlsList: any = [];
            if (this.imageUrls) {
                urlsList = this.imageUrls.split('\\n');
            }
            let usernames = '';
            if (urlsList != undefined && urlsList != null && urlsList.length > 0) {
                for (let i = 0; i < urlsList.length; i++) {
                    if (i == 0) {
                        html += `<div class="avatar-group-item" data-toggle="tooltip" title="${nameList[i]}"><a href="javascript: void(0);" class="d-inline-block"><img src="${urlsList[i]}" class="rounded-circle avatar-xs" alt=""></a></div>`
                    }
                    else if (i == 1) {
                        html += `<div class="avatar-group-item" data-toggle="tooltip" title="${nameList[i]}"><a href="javascript: void(0);" class="d-inline-block"><img src="${urlsList[i]}" class="rounded-circle avatar-xs" alt=""></a></div>`
                    }
                    else {
                        usernames += nameList[i] + '\n';
                    }
                }
            }
            else {
                html += `<div class="avatar-group-item"><a href="javascript: void(0);" class="d-inline-block"><img src="../../../assets/images/avatar.png" class="rounded-circle avatar-xs" alt /></a></div>`
            }
            if (urlsList != undefined && urlsList != null && urlsList.length > 2) {
                html += `<div class="avatar-group-item" data-toggle="tooltip" title="${usernames}">
                <a href="javascript:void(0);" class="d-inline-block">
                  <div class="rounded-circle avatar-xs" style="background-color: #000000; display: flex; align-items: center; justify-content: center; color: #fff; font-weight: bold; font-size: 12px; width: 33px; height: 33px;">+${urlsList.length - 2}</div>
                </a>
              </div>`;
            }

            html += `</div>`;

            if (html) {
                this.elementRef.nativeElement.innerHTML = html;
            }
        }
        else{
            html += `<div class="avatar-group-item"><a href="javascript: void(0);" class="d-inline-block"><img src="../../../assets/images/avatar.png" class="rounded-circle avatar-xs" alt /></a></div></div>`
            this.elementRef.nativeElement.innerHTML = html;
        }
    }
}
