import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page500',
  templateUrl: './page500.component.html',
  styleUrls: ['./page500.component.scss']
})

/**
 * Pages-500 component
 */
export class Page500Component implements OnInit {

  constructor(private router: Router,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.modalService.dismissAll();
  }

  ondashboardLinkClick(event: MouseEvent): void {
    event.preventDefault(); // Prevent the default anchor link behavior
    if (event.ctrlKey || event.metaKey) {
      window.open(environment.baseUrl + 'project/projectlist')
    } else {
      this.router.navigate(['/project/projectlist']);
    }
  }

  redirectodashboard() {
    return environment.baseUrl + 'project/projectlist';
    // this.router.navigate(['/projectlist'])
  }

}
