import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Page404Component } from './page404/page404.component';
import { Page500Component } from './page500/page500.component';
import { LoginComponent } from '../components/login/login.component';

const routes: Routes = [

    {
        path: '404',
        component: Page404Component
    },
    {
        path: 'error',
        component: Page500Component
    },
    {
        path: 'login',
        component: LoginComponent
    }
];


@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class ExtrapagesRoutingModule { }
