<footer class="footer position-fixed  align-items-center">
    <!-- <div class="container-fluid"> -->
    <!-- <div class="row">
            <div class="col-sm-6">
                {{year}}© | D9 | v {{version}}
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">                   
                </div>
            </div>
        </div> -->
    <div class="row">
       <div class="col-lg-4"> {{year}}© | D9 | v {{version}} </div>
         <!-- <h4 class="col-lg-4 text-primary mb-1 text-center">{{projectdetails.Projectname}}</h4> -->
        <div class="col-lg-4"></div>
        </div>
    <!-- </div> -->
</footer>