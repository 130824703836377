import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { UserProfileService } from '../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(private router: Router, private spinner: NgxSpinnerService, private userService: UserProfileService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token_response = JSON.parse(localStorage.getItem("d9_token_responseuat") || '{}');
    const token = token_response.access_token;
    const modifiedReq = request.clone({
      setHeaders: { Authorization: `Bearer ${token}`, 'Access-Control-Allow-Origin': '*' }
    });
    const timer = JSON.parse(localStorage.getItem('timer'));
    if (timer && (Date.now() > timer)) {
      if(this.router.url.split('/')[1] != 'login')
      Swal.fire({
        title: "Your session has Expired",
        text: "Login again!",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#006ca2",
        confirmButtonText: "Log Out"
      }).then((result) => {
        if (result.isConfirmed) {
          this.logout();
        }
      });
    }
    return next.handle(modifiedReq);
  }

  logout() {
    localStorage.removeItem('globalObjectId');
    localStorage.removeItem('d9_token_responseuat');
    localStorage.removeItem('IsUser_loggedin');
    localStorage.removeItem("isAuthenticated");
    this.userService.setTargetScreen('project/projectlist');
    this.spinner.hide();
    this.router.navigate(['/login']);
  }
}
