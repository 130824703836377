import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthguardService } from 'src/app/core/services/authgurd.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserapplicationService } from 'src/app/core/services/userapplication.service';
import { environment } from '../../../environments/environment';
import { UserProfileService } from 'src/app/core/services/user.service';
import { ToastralertService } from 'src/app/core/services/toasteralert.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login2',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
/**
 * Login-2 component
 */
export class LoginComponent implements OnInit {
  loginCredentials: any = { username: '', password: '' };
  loginbtn = 'Log In';
  token_response: any;
  token: any;
  submited: any = false;
  isEmailValid: any = false;
  visible: boolean = false;
  changetype: boolean = true;
  isShowtenantdropdown: boolean = false;
  tenantcompanies: any;
  companyname: any;
  diablelogin: boolean = true;

  constructor(private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private UserapplicationService: UserapplicationService,
    private userService: UserProfileService,
    private authGuardservice: AuthguardService,
    public toastralert: ToastralertService,
    private modalService: NgbModal

  ) { }

  ngOnInit(): void {
    this.modalService.dismissAll();
    this.validateEmail('', this.loginCredentials.username);
  }

  async doLogin(loginCredentials: { username: any; password: any; tenantProfileId: any }) {
    this.submited = true;
    this.loginbtn = 'Processing...';
    loginCredentials.username = this.trim(loginCredentials.username);
    if ((typeof loginCredentials.username == 'undefined' || this.trim(loginCredentials.username) == "" || loginCredentials.username == null)) {
      this.loginbtn = 'Log In';
      return false;
    }
    else if ((!this.validateEmail("", this.trim(loginCredentials.username)))) {
      this.loginbtn = 'Log In';
      return false;
    }
    else if ((typeof loginCredentials.password == 'undefined' || loginCredentials.password == "" || loginCredentials.password == null)) {
      this.loginbtn = 'Log In';
      return false;
    }
    else if ((typeof loginCredentials.tenantProfileId == 'undefined' || loginCredentials.tenantProfileId == "")) {
      this.loginbtn = 'Log In';
      return false;
    }
    else {
      const formData = new FormData();
      formData.append('username', loginCredentials.username);
      formData.append('password', loginCredentials.password);
      formData.append('tenantProfileId', loginCredentials.tenantProfileId);
      this.userService.userAuthentication(formData).subscribe((results) => {

        if (results != null && results != "" && results != 'undefined') {
          this.submited = false;
          this.token_response = results;
          if (this.token_response.userRoleId != null) {
            this.token = this.token_response.access_token;
            localStorage.setItem("d9_token_responseuat", JSON.stringify(this.token_response));
            localStorage.setItem("timer", JSON.stringify(Date.now() + (this.token_response.expires_in * 1000)));
            if (results.IsMultiFactorAuth) {
              if (results.IsEmailAuthentication) {
                this.ResentEmailOTPtoUser();
              }
              localStorage.setItem("isAuthenticated", "false");
              setTimeout(() => {
                this.router.navigate(['/MultiFactorAuthentication']);
              }, 500);
            }
            else {
              localStorage.setItem("isAuthenticated", "true");
              this.authGuardservice.authguard();
              // const targetScreen = this.userService.getTargetScreen();
              this.loginbtn = 'Authenticating...';
              // if (targetScreen) {
              //   this.router.navigateByUrl(targetScreen);
              // } else {
              this.router.navigate(['project/projectlist']);
              //}
            }
          }
          else {
            this.toastralert.toastrerror('Access not allowed for this user');
            this.loginbtn = 'Log In';
          }
        }
        else {
          this.toastralert.toastrerror('Access not allowed for this user');
          this.loginbtn = 'Log In';
          this.submited = false;
        }
      },
        (err) => {
          this.toastralert.toastrerror('The Email or Password is incorrect');
          this.loginbtn = 'Log In';
          this.submited = false;
        }
      );
    }

  }

  ResentEmailOTPtoUser() {
    if (this.token_response.IsEmailAuthentication && this.token_response.IsMultiFactorAuth) {
      let replaceElementsList: any[] = [];
      let authDetails = {
        UserProfileId: this.token_response.userId,
        TenantProfileId: this.token_response.tenantId,
        UserId: this.token_response.userId,
        UserLoginEmailOTP: null,
        UserTwoFactorAuthCode: null,
        IsMultiFactorAuth: this.token_response.IsMultiFactorAuth,
        IsAuthenticatorApp: false,
        IsEmailAuthentication: this.token_response.IsEmailAuthentication,
      };
      let taskdetails = {
        ToAddress: this.token_response.UserEmail,
        SubjectLine: 'One Time Password',
        TemplateType: 'Email',
        TemplateName: 'D9 invite link',
        SourceCustomerId: environment.SourceCustomerId,
        SourceTenantId: environment.sourcetenantid,
        CompanyImage: this.token_response.CompanyLogoImage,
        EmailSetupModel: authDetails,
        TempReplaceElements: replaceElementsList,
        ReferenceId: this.token_response.userId,
        ReferenceType: "ResentOTP"

      };
      this.UserapplicationService.SendEmailOTPtoUser(taskdetails).subscribe((results) => {
        if (results != null && results != "" && results != undefined && results == 'Email sent') {
          this.toastralert.toastersuccesstop('OTP has been sent successfully');
        }
        else {

        }
      },
        (err) => {
          if (err.status == 401) {
            const timer = JSON.parse(localStorage.getItem('timer'));
            if (timer && (Date.now() < timer)) {
              this.toastralert.toastrerrorbottom('Your are unauthorized user to get access this data.');
            }
          }
          else {
            this.toastralert.toastrerrorbottom("Unable to send OTP to your email.Please try after some time.");
          }
        }
      );
    }
  }

  changetenant() {
    this.loginCredentials.tenantProfileId = ''
  }

  validateEmail(event: any, email: string) {
    this.diablelogin = true;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isEmailValid = re.test(email);
    if (this.isEmailValid) {
      if ((this.loginCredentials.tenantProfileId == null || this.loginCredentials.tenantProfileId == "" || this.loginCredentials.tenantProfileId == 'undefined')) {
        this.tenantcompanies = [];
        this.userService.GetTenantsByUser(email).subscribe((results) => {
          if ((results != null && results != "" && results != 'undefined')) {
            this.tenantcompanies = results.tenantByUserModel;
            if (this.tenantcompanies.length === 1) {
              this.loginCredentials.tenantProfileId = this.tenantcompanies[0].TenantProfileId;
              this.isShowtenantdropdown = false;
              this.diablelogin = false;
            }
            else {
              this.loginCredentials.tenantProfileId = '';
              this.isShowtenantdropdown = true;
              this.diablelogin = false;
            }
            if (this.tenantcompanies.length === 0 || this.tenantcompanies == null) {
              this.isShowtenantdropdown = false;
              this.loginCredentials.tenantProfileId = null;
              this.diablelogin = false;
            }
          }
        })
      }
      else {
        if (this.tenantcompanies.length === 1) {
          this.isShowtenantdropdown = false;
          this.diablelogin = false;
        }
        else {
          this.isShowtenantdropdown = true;
          this.diablelogin = false;
        }
      }
    } else {
      this.isShowtenantdropdown = false;
      this.diablelogin = false;
    }
    return this.isEmailValid;
  }
  validationfortenantdropdown(email: string) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.isEmailValid = re.test(email);
    if (this.isEmailValid) {
      this.isShowtenantdropdown = true;
    } else {
      this.isShowtenantdropdown = false;
    }
  }

  trim(str: string) {
    return str.replace(/ /g, '');
  }

  onforgotpswdLinkClick(event: MouseEvent): void {
    event.preventDefault(); // Prevent the default anchor link behavior
    if (event.ctrlKey || event.metaKey) {
      window.open(environment.baseUrl + 'recoverpwd')
    } else {
      this.router.navigate(['/recoverpwd']);
    }
  }
  redirecttoforgotpsd() {
    return environment.baseUrl + 'recoverpwd';
  }



  // redirecttoforgotpsd() {
  //   this.router.navigate(["/recoverpwd"])

  // }
  viewpass() {
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }

  onsignupLinkClick(event: MouseEvent): void {
    event.preventDefault(); // Prevent the default anchor link behavior
    if (event.ctrlKey || event.metaKey) {
      window.open(environment.baseUrl + 'signup')
    } else {
      this.router.navigate(['/signup']);
    }
  }
  redirecttosignup() {
    return environment.baseUrl + 'signup';
  }

}

