<div class="p-0">
    <div class="row g-0">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div
                class="auth-full-page-content d-flex justify-content-center align-items-center p-4 p-lg-5 p-md-3 p-xl-5">
                <div class="w-100 p-xl-3">
                    <div class="d-flex flex-column h-100">
                        <div class="text-center"><img src="../assets/images/D9.Software.Logo.png" alt="" height="60"
                                class="auth-logo-dark text-center"></div>
                        <div class="p-2 mt-4 text-center w-100">
                            <h4>Two Factor Authentication</h4>
                            <p class="mb-3" *ngIf="token_response.IsAuthenticatorApp">Please enter the 6 digit code from
                                your authenticator app. </p>
                            <p class="mb-3" *ngIf="token_response.IsEmailAuthentication">Please enter the 6 digit code
                                sent to <span class="fw-semibold">{{token_response.UserEmail}}</span></p>
                            <!-- <form> -->
                            <ng-otp-input [config]="config" #ngOtpInput
                                (onInputChange)="onOtpChange($event)"></ng-otp-input>
                            <!-- </form> -->
                            <div class="mt-2" *ngIf="!isShowResendOTPbtn"><b>{{transform(counter)}}</b></div>
                            <!-- <button type="button" class="btn btn-primary waves-effect px-2 py-1 font-size-14">Resend
                                OTP</button> -->
                            <div class="mt-3">
                                <span *ngIf="token_response.IsEmailAuthentication">
                                    <button type="button"
                                        class="btn btn-primary waves-effect px-2 py-1 font-size-14 me-2"
                                        *ngIf="isShowResendOTPbtn" (click)="ResendOTPtoUser()">Resend
                                        OTP</button>
                                </span>
                                <button class="btn btn-primary waves-effect px-2 py-1 font-size-14"
                                    (click)="VerifyTwofactorAuthentication()">Confirm </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="auth-full-bg pt-lg-5 p-4">
                <div class="w-100">
                    <div class="bg-overlay"></div>
                    <div class="d-flex h-100 flex-column">
                        <div class="p-4 mt-auto">
                            <div class="row justify-content-center">
                                <div class="col-lg-7">
                                    <div class="text-center">
                                        <div dir="ltr">
                                            <!-- <owl-carousel-o class="owl-theme auth-review-carousel"
                                                  >
                                              </owl-carousel-o> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>