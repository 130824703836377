import { Directive, ElementRef, Input, ViewChild } from '@angular/core';

@Directive({
  selector: '[appProjectpermissionsforaddedcompoents]'
})
export class ProjectpermissionsforaddedcompoentsDirective {
  @Input('appProjectpermissionsforaddedcompoents') users : any | undefined;

  UserIds?: any[];
  token_response: any;

  constructor(private elementRef: ElementRef) {
    this.token_response = JSON.parse(
      localStorage.getItem("d9_token_responseuat") || "{}"
    );
  }
  ngOnChanges() {
    this.UserIds = this.users?.split(",");

    if (
      this.UserIds?.includes(this.token_response.userId.toUpperCase()) ||
      this.token_response.UserRoleName == "Administrator" ||
      this.token_response.UserRoleName == "Owner" || this.UserIds == null
    ) {
      
    } else {
      this.elementRef.nativeElement.classList.add('disabled');
    }
  }
}
