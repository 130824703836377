// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const url = "https://apis.d9.software";

export const environment = {
  baseUrl: 'https://app.d9.software/#/',
  defaultPassword: 'Apoorva$2023',
  timezone: '98f346bd-b1f9-4ffd-8d8c-9830f5c1b56a', // prod
  SourceCustomerId: "4578c0dd-d02f-4c79-9b2d-8f448b68e99e", //meastroprod
  sourcetenantid: 'cd7f0e42-f7ce-4559-9480-4be54d658305',//meastroprod
  Version: '1.0.0.6',
  production: true,
  validImageExtensions: ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'svg'],
  validFileExtensions: ["", 'docx', 'doc', 'pdf', 'csv', 'txt', 'text', 'xlsx', 'pptx', 'xml', 'ppt', 'psd', 'xls', 'jpeg', 'jpg', 'png', 'bmp', 'gif', 'svg',
    'mp4', 'avi', 'flv', 'm4a', 'mov', 'mpg', 'mwv', 'm4v', '3gp', 'mkv', 'wmv', 'webm', 'mp3', 'wav', 'aac', 'flac', 'm4a', 'ogg', 'aiff', 'au',
    'cshtml', 'html', 'css', 'js', 'json', 'cs', 'config', 'ts', 'csproj', 'php', 'sql'],
  tokenUrl: url + '/api/token',
  GetAllProjectsList: url + '/api/Hierarchy/GetAllProjectsList',
  GetCoreObjectHierarchy: url + '/api/Hierarchy/GetCoreObjectHierarchy',
  GetCoreObjectChildren: url + '/api/Hierarchy/GetCoreObjectChildren',
  GetCoreObjectChildrenforTasks: url + '/api/Hierarchy/GetCoreObjectChildrenforTasks',
  GetCoreObjectChildrenforCommentFiles: url + '/api/Hierarchy/GetCoreObjectChildrenforCommentFiles',
  GetCoreObjectDetails: url + '/api/Hierarchy/GetCoreObjectDetails',
  GetGroups: url + '/api/Hierarchy/GetGroups',
  GetGroupChildren: url + '/api/Hierarchy/GetGroupChildren',
  GetGroupsList: url + '/api/Hierarchy/GetGroupsList',
  GetCoreObjectProperties: url + '/api/Hierarchy/GetCoreObjectProperties',
  GetCoreTaskByTaskId: url + '/api/Hierarchy/GetCoreTaskByTaskId',
  GetCoreProjectByProjectId: url + '/api/Hierarchy/GetCoreProjectByProjectId',
  GetCoreUserProfileByUserProfileId: url + '/api/Hierarchy/GetCoreUserProfileByUserProfileId',
  GetCoreFileByFileId: url + '/api/Hierarchy/GetCoreFileByFileId',
  GetCoreObjectPrimaryKey: url + '/api/Hierarchy/GetCoreObjectPrimaryKey',
  PostForgotPassword: url + '/api/Account/PostForgotPassword',
  ResetPassword: url + '/api/Account/ResetPassword',
  GetAllCategoriesByProjectId: url + '/api/Hierarchy/GetAllCategoriesByProjectId',
  GetCategoriesById: url + '/api/Hierarchy/GetCategoriesById',
  GetAllTenantCompanysByTenantProfileId: url + '/api/Hierarchy/GetTenantCompaniesByTenantId',
  GetAllUsersListByTenantProfileId: url + '/api/Hierarchy/GetAllUsersListByTenantProfileId',
  SaveOrUpdatecoreTasks: url + '/api/Task/SaveOrUpdatecoreTasks',
  SaveOrUpdatecoreProjects: url + '/api/Project/SaveOrUpdatecoreProjects',
  SaveOrUpdatecoreTimeLog: url + '/api/TimeLog/SaveOrUpdatecoreTimeLog',
  SaveOrUpdatecoreComment: url + '/api/Comments/SaveOrUpdatecoreComment',
  GetAllCategoriesOrTagsByTenantProfileId: url + '/api/Category/GetAllCategoriesOrTagsByTenantProfileId',
  SaveOrUpdateauxCategoryOrTag: url + '/api/Category/SaveOrUpdateauxCategoryOrTag',
  GetAllCategoriesOrTagsByauxCategoryId: url + '/api/Category/GetAllCategoriesOrTagsByauxCategoryId',
  GetUserProfileDetailsByUserProfileId: url + '/api/Users/GetUserProfileDetailsByUserProfileId',
  SaveOrUpdatecoreUserProfile: url + '/api/UserProfile/SaveOrUpdatecoreUserProfile',
  SaveDeleteauxCategorieOrTag: url + '/api/Category/SaveDeleteauxCategorieOrTag',
  SaveOrUpdateauxGroupItem: url + '/api/Task/SaveOrUpdateauxGroupItem',
  SaveDeleteauxGroupItem: url + '/api/Task/SaveDeleteauxGroupItem',
  GetGroupListByauxGroupItemId: url + '/api/Task/GetGroupListByauxGroupItemId',
  GetUserDetailsByTenantUserProfileId: url + '/api/UserProfile/GetUserDetailsByTenantUserProfileId',
  SaveOrUpdateTenantCompany: url + '/api/Hierarchy/SaveOrUpdateTenantCompany',
  GetTenantCompaniesByCompanyId: url + '/api/Hierarchy/GetTenantCompaniesByCompanyId',
  GetCoreLookUpByCategory: url + '/api/Category/GetCoreLookUpByCategory',
  SaveOrUpdateCoreLookUp: url + '/api/Category/SaveOrUpdateCoreLookUp',
  SaveDeleteCoreLookUpCategory: url + '/api/Category/SaveDeleteCoreLookUpCategory',
  SaveDeleteTenantCompanies: url + '/api/Hierarchy/SaveDeleteTenantCompanies',
  GetCoreObjectDetailsforProjects: url + '/api/Hierarchy/GetCoreObjectDetailsforProjects',
  GetCoreTimeLogsByTimeLogId: url + '/api/Hierarchy/GetCoreTimeLogsByTimeLogId',
  SaveDeleteUserProfile: url + '/api/UserProfile/SaveDeleteUserProfile',
  SaveDeletecoreTimeLogByTimeLogId: url + '/api/TimeLog/SaveDeletecoreTimeLogByTimeLogId',
  SaveFilesToS3Bucket: url + '/api/FileUpload/UploadFileToS3Bucket',
  SaveEditorFilesToS3Bucket: url + '/api/FileUpload/UploadFile',
  SaveOrUpdatecoreFiles: url + '/api/Files/SaveOrUpdatecoreFiles',
  DownloadFilesFromS3Bucket: url + '/api/FileUpload/DownloadFile',
  GetcoreFilesByTaskId: url + '/api/Files/GetcoreFilesByTaskId',
  GetFileURL: url + '/api/FileUpload/GetFileURL',
  GetFilesByFilter: url + '/api/Files/GetFilesByFilter',
  SaveGetAdvancedSearch: url + '/api/Hierarchy/GetAdvancedSearch',
  SaveDeleteProjectUser: url + '/api/Project/SaveDeleteProjectUser',
  GetCompletedTaskListsProjectId: url + '/api/Task/GetCompletedTaskListsProjectId',
  GetCompletedTasksByAuxGroupItemId: url + '/api/Task/GetCompletedTasksByAuxGroupItemId',
  SaveDeletecoreFileByFileId: url + '/api/Files/SaveDeletecoreFileByFileId',
  SaveDeletecoreCommentByCommentId: url + '/api/Comments/SaveDeletecoreCommentByCommentId',
  SaveDeleteFileInS3Bucket: url + '/api/FileUpload/SaveDeleteFileInS3Bucket',
  GetCoreCommentByCommentId: url + '/api/Hierarchy/GetCoreCommentByCommentId',
  UpdatecoreFileVersion: url + '/api/Files/UpdatecoreFileVersion',
  SaveDeleteProjectByProjectId: url + '/api/Project/SaveDeleteProjectByProjectId',
  GetTimeLogsByTimelogsFilter: url + '/api/TimeLog/GetTimeLogsByTimelogsFilter',
  SaveDeletecoreTaskByTaskId: url + '/api/Task/SaveDeletecoreTaskByTaskId',
  GetFileURLFromS3Bucket: url + '/api/FileUpload/SaveGetFileURLFromS3Bucket',
  UploadUserFilesToS3Bucket: url + '/api/FileUpload/UploadUserFilesToS3Bucket',
  UploadFileInPublicBucket: url + '/api/FileUpload/UploadFileInPublicBucket',
  SaveGetFileURLFromS3: url + '/api/FileUpload/SaveGetFileURLFromS3',
  SaveSendTaskCreatedEmailsToUsers: url + '/api/Emails/SaveSendTaskCreatedEmailsToUsers',
  SaveDeleteauxCategoryTagByTaskId: url + '/api/Project/SaveDeleteauxCategoryTagByTaskId',
  SaveDeleteauxGroupItemBoardByTaskId: url + '/api/Project/SaveDeleteauxGroupItemBoardByTaskId',
  GetFileURLFromAmazonS3: url + '/api/FileUpload/GetFileURLFromAmazonS3',
  GetUserCode: url + '/api/Emails/GetUserCode',
  GetSetEmailSentforForgotPassword: url + '/api/Emails/GetSetEmailSentforForgotPassword',
  SaveSendInvitationMailsToUsers: url + '/api/Emails/SaveSendInvitationMailsToUsers',
  GetMenusByUserProfileIdAndUserRoleId: url + '/api/Account/GetMenusByUserProfileIdAndUserRoleId',
  GetProjectUsersListByTenantProfileId: url + '/api/Project/GetProjectUsersListByTenantProfileId',
  GetCoreObjectChildrenTimelogPaging: url + '/api/Hierarchy/GetCoreObjectChildrenTimelogPaging',
  GetappUserRolesByTenantProfileId: url + '/api/UserProfile/GetappUserRolesByTenantProfileId',
  GenerateNewSecretKey: url + '/api/Account/GenerateNewSecretKey',
  SaveVerifyGoogleAuthCode: url + '/api/Account/SaveVerifyGoogleAuthCode',
  UpdateMultiFactorAuthenticationByUserId: url + '/api/UserProfile/UpdateMultiFactorAuthenticationByUserId',
  LoginWithAuthentication: url + '/api/Account/LoginWithAuthentication',
  SendEmailOTPtoUser: url + '/api/Account/SendEmailOTPtoUser',
  GetTasksByTasksFilter: url + '/api/Task/GetTasksByTasksFilter',
  UpdateappTenantProfiles: url + '/api/Tenant/UpdateappTenantProfiles',
  GetappTenantProfiles: url + '/api/Tenant/GetappTenantProfiles',
  GetappTimeZones: url + '/api/UserProfile/GetappTimeZones',
  SaveCopyorMovecoreTasks: url + '/api/Task/SaveCopyorMovecoreTasks',
  SaveCopyorMovecoreTaskList: url + '/api/Task/SaveCopyorMovecoreTaskList',
  GetProjectsByCompanyId: url + '/api/Project/GetProjectsByCompanyId',
  GetUsersByCompanyId: url + '/api/Client/GetUsersByCompanyId',
  GetDefaultTenantPermissions: url + '/api/Account/GetDefaultTenantPermissions',
  SaveOrUpdateDefaultTenantPermissions: url + '/api/Account/SaveOrUpdateDefaultTenantPermissions',
  GetTasksByUserProfileId: url + '/api/Task/GetTasksByUserProfileId',
  GetUserProjectPermissions: url + '/api/Project/GetUserProjectPermissions',
  SaveOrUpdateUserProjectPermissions: url + '/api/Project/SaveOrUpdateUserProjectPermissions',
  GetTasksCompletedByUser: url + '/api/Task/GetTasksCompletedByUser',
  SaveCopyProject: url + '/api/Project/SaveCopyProject',
  GetPrivacyUsersByTaskId: url + '/api/Task/GetPrivacyUsersByTaskId',
  SaveOrUpdateTenantProfile: url + '/api/Hierarchy/SaveOrUpdateTenantProfile',
  GetTenantsByUser: url + '/api/Hierarchy/GetTenantsByUser',
  DSBTenantsData: url + '/api/Hierarchy/DSBTenantsData',
  DSBTenantDataByTenantProfileId: url + '/api/Hierarchy/DSBTenantDataByTenantProfileId',
  TenantsFilter: url + '/api/Hierarchy/TenantsFilter',
  GetTasksByBoards: url + "/api/Project/GetTasksByBoards",
  UpdateProjectBoardByTaskId: url + "/api/Project/UpdateProjectBoardByTaskId",
  GetBoardTaskDropdowns: url + "/api/Project/GetBoardTaskDropdowns",
  GetAllCategories: url + "/api/Files/GetAllCategories",
  SaveClient: url + '/api/Hierarchy/SaveClient',
  SaveauxCategoryTask: url + '/api/Project/SaveauxCategoryTask',
  GetauxCategorybyId: url + '/api/Category/GetauxCategorybyId',
  GetauxCategoryTask: url + '/api/Category/GetauxCategoryTask',
  GetBoardDetailsByBoardId: url + '/api/Hierarchy/GetBoardDetailsByBoardId',
  VerifySignUpOTP: url + '/api/Account/VerifySignUpOTP',
  DeleteProjectBoardById: url + '/api/Project/DeleteProjectBoardById',
  UpdateTasksDisplayOrder: url + '/api/Hierarchy/UpdateTasksDisplayOrder',
  UpdateTaskListDisplayOrder: url + '/api/Hierarchy/UpdateTaskListDisplayOrder',
};