<div class="p-0">
    <div class="row g-0">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div
                class="auth-full-page-content d-flex justify-content-center align-items-center p-4 p-lg-5 p-md-3 p-xl-5">
                <div class="w-75 p-xl-3">
                    <div class="d-flex flex-column h-100">
                        <div class="text-center"><img src="../assets/images/D9.Software.Logo.png" alt="" height="60"
                                class="auth-logo-dark text-center"></div>
                        <div class="mt-4">
                            <div class="alert alert-success text-center mb-4" role="alert">
                                Enter your Email to generate URL for restting your password.
                            </div>
                            <div class="mb-3">
                                <label for="useremail" class="fw-bold">Email</label>
                                <input [(ngModel)]='email' placeholder="Enter your Email" class="form-control"
                                    (input)="changetenant();validateEmail($event,email)">
                                <div *ngIf="submited && (email == undefined || email == '' || email == null)">
                                    <span class="text-danger">Please enter your Email</span>
                                </div>
                                <div
                                    *ngIf="submited && !isEmailValid && (email != undefined && email != '' && email != null)">
                                    <span class="text-danger">Please enter a valid Email</span>
                                </div>
                            </div>

                            <div class="mb-3" *ngIf="isShowtenantdropdown">
                                <label for="tenant" class="fw-bold font-size-13">Company</label>
                                <ng-select [clearable]="false" class="cursor-pointer" placeholder="Select company"
                                    [(ngModel)]="tenantProfileId">
                                    <ng-option [value]="''" disabled>Select Company
                                    </ng-option>
                                    <ng-option *ngFor="let tenant of tenantcompanies" [value]="tenant.TenantProfileId">
                                        {{tenant.TenantName}}</ng-option>
                                </ng-select>
                                <div
                                    *ngIf="submited && (tenantProfileId == undefined || tenantProfileId == '' || tenantProfileId == null)">
                                    <span class="text-danger">Please select Company</span>
                                </div>
                            </div>
                            <div class="text-end">
                                <button class="btn btn-primary w-md"
                                    (click)="PostForgotPassword(email,tenantProfileId)">Reset</button>
                            </div>
                            <div class="mt-5 text-center cursor-pointer">
                                <p> <a [href]="redirecttologin()" class="fw-medium text-primary"
                                        (click)="onloginLinkClick($event)">
                                        Sign In here</a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12    ">
            <div class="auth-full-bg pt-lg-5 p-4">
                <div class="w-100">
                    <div class="bg-overlay"></div>
                    <div class="d-flex h-100 flex-column">
                        <div class="p-4 mt-auto">
                            <div class="row justify-content-center">
                                <div class="col-lg-7">
                                    <div class="text-center">
                                        <div dir="ltr">
                                            <!-- <owl-carousel-o class="owl-theme auth-review-carousel"
                                                    [options]="carouselOption">
                                                </owl-carousel-o> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>