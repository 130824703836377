import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './extrapages/page404/page404.component';
import { PasswordresetComponent } from './components/passwordreset/passwordreset.component';
import { Recoverpwd2Component } from './components/recoverpwd2/recoverpwd2.component';
import { LoginComponent } from './components/login/login.component';
import { MultiFactorAuthenticationComponent } from './components/multi-factor-authentication/multi-factor-authentication.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { Page500Component } from './extrapages/page500/page500.component';
const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'reset-password',
    component: PasswordresetComponent
  },
  {
    path: 'recoverpwd',
    component: Recoverpwd2Component
  },
  {
    path: 'MultiFactorAuthentication',
    component: MultiFactorAuthenticationComponent
  },
  {
    path: 'signup',
    component: SignUpComponent
  },
  { path: 'error', component: Page500Component },
  { path: '', component: LayoutComponent, loadChildren: () => import('./components/pages.module').then(m => m.PagesModule) },
  { path: 'client', component: LayoutComponent, loadChildren: () => import('./components/clientroute/clientroute.module').then(m => m.ClientrouteModule) },
  { path: 'company', component: LayoutComponent, loadChildren: () => import('./components/companyroute/companyroute.module').then(m => m.CompanyrouteModule) },
  { path: 'project', component: LayoutComponent, loadChildren: () => import('./components/projectsroute/projectsroute.module').then(m => m.ProjectsrouteModule) },
  { path: 'user', component: LayoutComponent, loadChildren: () => import('./components/userroute/userroute.module').then(m => m.UserrouteModule) },
  { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule) },
  { path: '**', component: Page404Component },
  
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
