import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, throwError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Injectable({ providedIn: 'root' })
export class UserProfileService {
  token_response: any;
  token: any;
  private targetScreen: string;
  modalService: any;

  constructor(private http: HttpClient,
    private modalSerivce: NgbModal) { }

  ngOnInit(): void {
    this.modalService.dismissAll();
    this.token_response = JSON.parse(localStorage.getItem("d9_token_responseuat") || '{}');
    this.token = this.token_response.access_token;
  }

  setTargetScreen(url: string): void {
    this.targetScreen = url;
  }

  getTargetScreen(): string {
    return this.targetScreen;
  }

  userAuthentication(formData: any) {
    return this.http.post(environment.tokenUrl, formData).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  LoginWithAuthentication(formData: any) {
    return this.http.post(environment.LoginWithAuthentication, formData).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  PostForgotPassword(email: any, tenantProfileId: any) {
    const httpOptions = {
      responseType: 'text',
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }
    return this.http.post(environment.PostForgotPassword + '/' + email + '/' + tenantProfileId, httpOptions).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetUserCode(email: any, tenantId: any) {
    return this.http.get(environment.GetUserCode + '/' + email + '/' + tenantId, {
      responseType: 'text',
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetSetEmailSentforForgotPassword(userId: any, tenantId: any) {
    return this.http.get(environment.GetSetEmailSentforForgotPassword + '/' + userId + '/' + tenantId, {
      responseType: 'text',
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  resetpassword(passworddetails: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      })
    }
    return this.http.post(environment.ResetPassword, passworddetails, httpOptions).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      return error;
    }));
  }

  GetMenusByUserProfileIdAndUserRoleId(UserProfileId: any, timeLogId: any) {
    return this.http.get(environment.GetMenusByUserProfileIdAndUserRoleId + '/' + UserProfileId + '/' + timeLogId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  GetDefaultTenantPermissions(tenantProfileId: any, UserProfileId: any, projectId: any) {
    return this.http.get(environment.GetDefaultTenantPermissions + '/' + tenantProfileId + '/' + UserProfileId + '/' + projectId)
      .pipe(map((res: any) => {
        return res;
      }), catchError(error => {
        console.log(error);
        return throwError(error);
      }));
  }

  SaveOrUpdateDefaultTenantPermissions(accesspermissions: any) {
    return this.http.post(environment.SaveOrUpdateDefaultTenantPermissions, accesspermissions, {
      responseType: 'text'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  SaveOrUpdateTenantProfile(tenantProfile: any) {
    return this.http.post(environment.SaveOrUpdateTenantProfile, tenantProfile, {
      responseType: 'json'
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  GetTenantsByUser(company: any) {
    return this.http.get(environment.GetTenantsByUser + '/' + company, {
      responseType: 'json',
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

  VerifySignUpOTP(email: any, tenantProfileId: any) {    
    return this.http.get(environment.VerifySignUpOTP + '/' + email + '/' + tenantProfileId,{
      responseType: 'json',
    }).pipe(map((res: any) => {
      return res;
    }), catchError(error => {
      console.log(error);
      return throwError(error);
    }));
  }

}
